import React, { useState } from "react";
import { useEffect } from "react";
import { filter } from "lodash";

const ApproveDeclineConfirmation = ({ onConfirm, approval }) => {
  const [dontShow, setDontShow] = useState(false);

  useEffect(() => {
    setDontShow(false);
  }, [approval]);

  const pText = approval
    ? "When declining a channel, the channel artwork will be hidden on your devices and it will no longer be available to be set/played within your locations."
    : "When approving a channel, the channel artwork will be shown on your devices and it will be available to be set/played within your locations.";

  const headerText = approval
    ? "Confirm decline channel(s)"
    : "Confirm approve channel(s)";

  return (
    <div className="confirm_container">
      <h4>{headerText}</h4>
      <p>{pText}</p>
      <div className="label-input">
        <label
          htmlFor="decline_confirmation"
          className="control control-checkbox"
          style={{
            position: "relative",
          }}
        >
          <input
            type="checkbox"
            id="decline_confirmation"
            checked={dontShow}
            onChange={(e) => {
              setDontShow(e.target.checked);
            }}
          />
          <div
            className="control_indicator"
            style={{ left: 0, right: "unset", top: "2px" }}
          ></div>
          Don’t show this message again.
        </label>
      </div>
      <button className="red-btn" onClick={() => onConfirm(dontShow)}>
        Confirm
      </button>
    </div>
  );
};

export default ApproveDeclineConfirmation;
