import React from 'react';

const BillingsTable = (props) => {
    const rowData = props.data;
    const rows = Object.keys(rowData).sort().map((rowDataKey, didx) => {
      if (props.studioType == 'creator') {
        return (
          <tr key={`${rowDataKey}-${didx}`} className='billing-row'>
            <td>{rowData[rowDataKey].subscription}</td>
            <td>{rowData[rowDataKey].amount}</td>
            <td>{rowData[rowDataKey].start_date}</td>
            <td>{rowData[rowDataKey].end_date}</td>
            <td>{rowData[rowDataKey].renewal_date}</td>
            <td>{rowData[rowDataKey].period}</td>
          </tr>
        )
      } else {
        return (
          <tr key={`${rowDataKey}-${didx}`} className='billing-row'>
            <td>{rowData[rowDataKey].code}</td>
            <td>{rowData[rowDataKey].zone_code}</td>
            <td>{rowData[rowDataKey].subscription}</td>
            <td>{rowData[rowDataKey].amount}</td>
            <td>{rowData[rowDataKey].start_date}</td>
            <td>{rowData[rowDataKey].end_date}</td>
            <td>{rowData[rowDataKey].renewal_date}</td>
            <td>{rowData[rowDataKey].period}</td>
            <td>{rowData[rowDataKey].s_name}</td>
            <td>{rowData[rowDataKey].company_address}</td>
          </tr>
        )
      }
    });
    var headers = props.studioType == 'creator' ? (
      <tr>
          <th>Subscription</th>
          <th>Billing Amount</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Renewal Date</th>
          <th>Period</th>
      </tr>
    ) : (
      <tr>
          <th>Location</th>
          <th>Zone</th>
          <th>Subscription</th>
          <th>Billing Amount</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Renewal Date</th>
          <th>Period</th>
          <th title='company address'>Location Name</th>
          <th title='company address'>Company Address</th>
      </tr>
    )
    return (
        <table className="billings-table">
            <thead>
              {headers}
            </thead>
            <tbody>
              {rows}
            </tbody>
        </table>
    )
};

export default BillingsTable