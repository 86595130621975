import React from 'react';

class BasicSwitch extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = { isChecked: this.props.isChecked()}
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (event) {
        this.props.onSwitchChange(event);
        this.setState({isChecked: !this.state.isChecked});
    }

    componentWillReceiveProps({isChecked}) {
      this.setState({
        isChecked: isChecked(),
      })
    }

    render () {
        return(
          <label className="switch">
            <input name={this.props.name} type="checkbox" checked={this.props.isChecked()} onChange={this.handleChange} />
            <span className="slider round"></span>
          </label>
        );
    }
}

export default BasicSwitch