import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, ClickableText, Flex, Grid, Input, Text } from "../elements";
import ZonesInfo from "./ZonesInfo";
import ZonesMusic from "./ZonesMusic";
import ZonesMessaging from "./ZonesMessaging";

const LocationModal = ({
  location,
  displayed,
  handleClose,
  setZones,
  zones,
  refreshData,
}) => {
  const [modalOpen, setModalOpen] = useState(displayed);
  const [name, setName] = useState(location.name);
  const [address, setAddress] = useState(location.address);
  const [timezone, setTimezone] = useState(location.timezone);
  const [edit, setEdit] = useState(false);
  const [currentTab, setCurrentTab] = useState("Zones Info");
  const editButton = edit ? solid("times") : solid("pencil");
  const [zoneData, setZoneData] = useState({});

  useEffect(() => {
    setModalOpen(displayed);
  }, [displayed]);

  useEffect(() => {
    refreshData();
  }, []);

  const updateLocation = (zoneData) => {
    let data = {
      location: {
        name: name,
        address: address,
        timezone: timezone,
        locations_attributes: zoneData,
      },
    };
    $.ajax({
      url: `/api/v2/sites/${location.id}`,
      type: "PATCH",
      dataType: "JSON",
      data: data,
      success: function (data) {
        window.kk.notifier.notify("Location updated.", "success");
        setZones(data.zones);
      },
      error: function (error) {
        window.kk.notifier.notify(
          "Error updating location, please contact support.",
          "error"
        );
      },
    });
    setEdit(false);
  };

  const closeModal = (event) => {
    if (event.target.classList.contains("location-modal")) {
      setModalOpen(!modalOpen);
      handleClose(event);
    }
  };
  const fetchZones = () => {
    fetch(`/api/v2/locations/${location.id}/zones`)
      .then((response) => response.json())
      .then((data) => {
        setZones(data);
      });
  };

  useEffect(() => {
    fetchZones();
  }, []);

  const tabs = {
    "Zones Info": (
      <ZonesInfo
        zones={zones}
        edit={edit}
        setZoneData={setZoneData}
        zoneData={zoneData}
        updateLocation={updateLocation}
      />
    ),
    "Zones Music": <ZonesMusic fetchZones={fetchZones} zones={zones} />,
    "Zones Messaging": (
      <ZonesMessaging
        locationId={location.id}
        zones={zones}
        setZones={setZones}
      />
    ),
    // 'Zones Settings': <div></div>
  };

  if (zones.length === 0) {
    return <></>;
  }

  const navLinks = Object.keys(tabs).map((tabName, i) => {
    const borderLeft = i > 0 ? "1px #EAF0F1 solid" : "";
    const borderBottom = tabName === currentTab ? "3px #f44336 solid" : "";
    const fontWeight = tabName === currentTab ? "bold" : "";

    return (
      <Box
        key={i}
        borderLeft={borderLeft}
        onClick={() => setCurrentTab(tabName)}
      >
        <ClickableText
          textAlign="center"
          fontFamily="Montserrat"
          pb="5px"
          width="fit-content"
          margin="auto"
          fontWeight={fontWeight}
          borderBottom={borderBottom}
        >
          {tabName}
        </ClickableText>
      </Box>
    );
  });

  return (
    <Flex
      bg="rgba(0, 0, 0, 0.5)"
      className={modalOpen ? "modal location-modal" : "hidden"}
      onClick={closeModal}
    >
      <div className="modal-dialog modal-lg">
        <Box className="modal-content" width="125%" borderRadius="6px">
          <Grid px={15}>
            <Grid pt={20} pb={10} gridTemplateColumns="14fr 5fr 5fr 6fr 1fr">
              <Flex flexDirection="row">
                <Text
                  pr={10}
                  fontFamily="Montserrat"
                  fontSize="1.75em"
                  fontWeight="500"
                >
                  Location {location.code}
                </Text>
                {currentTab === "Zones Info" && (
                  <ClickableText
                    pl={10}
                    fontSize="1.15em"
                    onClick={() => setEdit(!edit)}
                  >
                    <FontAwesomeIcon icon={editButton} />
                  </ClickableText>
                )}
              </Flex>
              {navLinks}
              <ClickableText fontSize="1.35em" onClick={handleClose}>
                <FontAwesomeIcon color="black" icon={solid("times")} />
              </ClickableText>
            </Grid>
            <Grid height={"50px"} gridTemplateColumns="4fr 5fr 2fr 12fr">
              <Flex flexDirection="row" px={10} pr={25}>
                <Text fontSize="0.85em" mt="5px">
                  <FontAwesomeIcon icon={solid("building")} />
                </Text>
                {edit ? (
                  <Input
                    fontFamily="Montserrat"
                    height="25px"
                    maxWidth={150}
                    ml="7px"
                    border="1px solid"
                    borderBottom="1px solid"
                    onChange={(event) => setName(event.target.value)}
                    value={name || ""}
                  />
                ) : (
                  <Text fontFamily="Montserrat" pl="7px" mt="5px">
                    {name}
                  </Text>
                )}
              </Flex>
              <Flex flexDirection="row" px={10} pr={25}>
                <Text fontSize="0.85em" mt="5px">
                  <FontAwesomeIcon icon={solid("location-dot")} />
                </Text>
                {edit ? (
                  <Input
                    fontFamily="Montserrat"
                    height="25px"
                    width={190}
                    ml="7px"
                    border="1px solid"
                    borderBottom="1px solid"
                    onChange={(event) => setAddress(event.target.value)}
                    value={address || ""}
                  />
                ) : (
                  <Text fontFamily="Montserrat" pl="7px" mt="5px">
                    {address}
                  </Text>
                )}
              </Flex>
              <Flex flexDirection="row" px={10} pr={25}>
                <Text fontSize="0.85em" mt="5px">
                  <FontAwesomeIcon icon={solid("clock")} />
                </Text>
                {edit ? (
                  <Input
                    fontFamily="Montserrat"
                    height="25px"
                    maxWidth={50}
                    ml="7px"
                    border="1px solid"
                    borderBottom="1px solid"
                    onChange={(event) => setTimezone(event.target.value)}
                    value={timezone || ""}
                  />
                ) : (
                  <Text fontFamily="Montserrat" pl="7px" mt="5px">
                    {timezone}
                  </Text>
                )}
              </Flex>
            </Grid>
          </Grid>
          <Box bg="#EAF0F1" p={10}>
            {tabs[currentTab]}
          </Box>
        </Box>
      </div>
    </Flex>
  );
};

export default LocationModal;
