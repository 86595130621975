import React, {useState} from 'react'
import { BasicButton, Flex } from '../elements'

const TalentButtons = ({talent, stopAudio, toggleModal, displayed}) => {
  const [playButtonText, setPlayButtonText] = useState('Play')

  const playSoundByte = (event) => {
    event.preventDefault()
    if (window.kk.player.overlayAudio) {
      stopAudio()
      setPlayButtonText('Play')
      return
    }

    setPlayButtonText('Loading')

    window.kk.player.loadOverlayAudio(
      talent.soundbyte.url,
      function () {
        setPlayButtonText('Stop')
      },
      function () {
        setPlayButtonText('Play')
      },
      function () {
        setPlayButtonText('Error')
      }
    );
  }

  return (
    <Flex
      display={displayed ? 'flex' : 'none'}
      flexDirection='column'
      bg='rgba(0,0,0,0.25)'
      position='absolute'
      top='0'
      right='0'
      bottom='0'
      left='0'
    >
      <BasicButton
        bg='white'
        width='150px'
        mx='auto'
        mt='50%'
        mb='5px'
        onClick={playSoundByte}
      >{playButtonText}</BasicButton>
      <BasicButton
        bg='white'
        width='150px'
        mx='auto'
        mb='10px'
        onClick={toggleModal}
      >Request</BasicButton>
    </Flex>
  )
}

export default TalentButtons;