import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { CreditsContext, UserContext } from '../contexts'
import CreditMenu from './CreditMenu'
import BillingModal from '../Accounts/BillingModal'
import { Box, Button, Flex, Grid, GridItem, Text } from '../elements'

const CreditPackModal = (props) => {
  const [displayed, setDisplayed] = useState(props.displayed)
  const [selectedPack, setSelectedPack] = useState({})
  const [couponCode, setCouponCode] = useState('')
  const [couponApplied, setCouponApplied] = useState(false)
  const [discount, setDiscount] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState({})
  const [modalDisplayed, setModalDisplayed] = useState(false)
  const { setAvailableCredits } = useContext(CreditsContext)
  const { tenantName } = useContext(UserContext)

  useEffect(() => {
    setSelectedPack(props.selected || { credits: 3, price: 30000, savePercent: "0%" });
  }, [props.selected])

  useEffect(() => {
    setDisplayed(props.displayed);
  }, [props.displayed])

  useEffect(() => {
    fetch('/api/v2/billing/default')
      .then(response => response.json())
      .then((data) => {
        setPaymentMethod(data.card)
      })
  }, [])

  const closeModal = (event) => {
    if (event.target.classList.contains("credits-modal")) {
      setDisplayed(!displayed)
      props.handleClose(event)
    }
  }

  const updateSelectedPack = (pack) => {
    setSelectedPack(pack)
  }

  const updateCoupon = (event) => {
    setCouponCode(event.target.value)
  }

  const calculateDiscount = (coupon) => {
    if (coupon.amount_off) {
      setDiscount(coupon.amount_off)
    } else {
      const discountAmount = selectedPack.costInCents * (coupon.percent_off / 100)
      setDiscount(discountAmount)
    }
  }

  const applyCoupon = () => {
    if (couponApplied) {
      setDiscount(0)
      setCouponCode('')
      return setCouponApplied(!couponApplied)
    }

    $.ajax({
      url: '/api/v2/coupons',
      type: 'POST',
      dataType: 'JSON',
      data: {coupon: couponCode},
      success: (result) => {
        const coupon = result.coupon
        if (coupon && (coupon.amount_off || coupon.percent_off)) {
          calculateDiscount(coupon)
          setCouponApplied(!couponApplied)
          window.kk.notifier.notify(`Successfully applied coupon: ${couponCode}`, "success");
        } else {
          setDiscount(0)
          setCouponCode('')
          window.kk.notifier.notify("Looks like that coupon was not valid.", "error");
        }
      },
      error: (error) => {
        setDiscount(0)
        setCouponCode('')
        window.kk.notifier.notify("Looks like that coupon was not valid.", "error");
      }
    })
  }

  const togglePaymentsModal = () => {
    setModalDisplayed(!modalDisplayed)
  }

  const updatePaymentMethod = (card) => {
    setPaymentMethod(card)
  }

  const centsToDollars = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'USD'
    }).format(value / 100);

  const purchaseCredits = () => {
    if (paymentMethod.expired) {
      return window.kk.notifier.notify("Your payment method has expired. Please update your card.", "error");
    }
    $.ajax({
      url: '/api/v2/credits',
      type: 'POST',
      dataType: 'JSON',
      data: {
        cart: {
          credits: selectedPack.credits,
          amount: selectedPack.costInCents,
          price: selectedPack.price,
          coupon: couponCode,
          source: paymentMethod.id
        }
      },
      success: (result) => {
        setAvailableCredits(result.credits)
        window.kk.notifier.notify(`Successfully purchased ${selectedPack.credits} credits`, "success");
      },
      error: (error) => {
        window.kk.notifier.notify("Error purchasing credits, please contact support.", "error");
      }
    })

    setDisplayed(!displayed)
    props.handleClose()
  }

  return (
    <Box display='flex' bg='rgba(0, 0, 0, 0.5)' className={displayed ? "modal credits-modal" : "hidden"} onClick={closeModal} >
      {paymentMethod ?
        <div className="modal-dialog modal-lg" >
          <div className="modal-content" >
            <Flex justifyContent='flex-end' pr='8px' pt='8px'>
              <FontAwesomeIcon color='grey' icon={solid('times')} onClick={props.handleClose} />
            </Flex>
            <Box px='25px' pt='25px' borderBottom='1px solid #eeeeee'>
              <CreditMenu selectPack={updateSelectedPack} selectedPack={selectedPack}/>
            </Box>
            <Flex flexDirection='column'>
              <Grid gridTemplateColumns='4fr 5fr' gridTemplateRows='auto' py='20px' px='40px'>
                <GridItem column={1}>
                  <Grid gridTemplateColumns='2fr 3fr'>
                    <Text fontWeight='600'>Licensed to:</Text>
                    <Text>{tenantName}</Text>
                    <Text fontWeight='600'>Pay with:</Text>
                    <Flex flexDirection='row'>
                      <Text
                        color={paymentMethod.expired ? 'red' : 'black'}
                        pr='10px'
                      >
                        {paymentMethod.brand} {paymentMethod.last_four} {paymentMethod.expired ? 'exp' : ''}
                      </Text>
                      <Link to="#" style={{textDecoration: 'underline'}} onClick={togglePaymentsModal}>Update payment</Link>
                    </Flex>
                    <Text fontWeight='600'>Subtotal:</Text>
                    <Text>{centsToDollars(selectedPack.costInCents)}</Text>
                    <Text fontWeight='600'>Coupon Code:</Text>
                    <Flex flexDirection='row' mb={15}>
                      <input
                        name='coupon'
                        type='text_field'
                        className='form-control'
                        placeholder='Coupon Code'
                        disabled={couponApplied}
                        value={couponCode}
                        onChange={updateCoupon}
                      />
                      <Button ml='10px' onClick={applyCoupon}>{couponApplied ? 'Clear' : 'Apply'}</Button>
                    </Flex>
                    <Text fontWeight='600' pb='10px' borderBottom='1px solid #eeeeee'>Discount:</Text>
                    <Text color='#f44336' borderBottom='1px solid #eeeeee'>({centsToDollars(discount)})</Text>
                    <Text fontWeight='600'>Total:</Text>
                    <Text>{centsToDollars(selectedPack.costInCents - discount)}</Text>
                  </Grid>
                </GridItem>
              </Grid>
              <Box px='30px' borderBottom='1px solid #eeeeee'>
                <Text fontSize='11px' fontWeight='500'>
                  By placing your order, you agree to Krosskast's <Link style={{ color: 'red' }} to='/privacy-policy'>Privacy Policy</Link> and <Link style={{ color: 'red' }} to='/terms-and-conditions'>Terms Of Use</Link>.
                  All messaging & branding credit packs are non-refundable and must be used within a one-year period
                  from the date of purchase. All purchased credits expire immidiately if your Krosskast subscription is terminated for any reason.
                  In the event that one of our team members is unable to make this performance,
                  we will provide you with another team member that is equally qualified.
                </Text>
              </Box>
              <Box pl='75%' py='20px'>
                <Button onClick={purchaseCredits}>Place Your Order</Button>
              </Box>
            </Flex>
            <BillingModal pt='25px' modalDisplayed={modalDisplayed} handleClose={togglePaymentsModal} handleSubmit={updatePaymentMethod} />
          </div >
        </div >
        : <></>
      }
    </Box>
  )
}

export default CreditPackModal
