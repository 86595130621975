import React, { Children, useState } from "react";
import { Portal } from "react-portal";

const ConfirmModal = ({ content, onClose }) => {
  return (
    <Portal node={document && document.getElementById("portal_area")}>
      <div className="modal_root" onClick={onClose}>
        <div
          className="content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="close-btn" onClick={onClose}>
            <i className="fas fa-times search-clear"></i>
          </div>
          <div>{content}</div>
        </div>
      </div>
    </Portal>
  );
};

export default ConfirmModal;
