import React from 'react';
import { UserContext } from "./contexts";

class Support extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      message: "",
    };

    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  handleMessageChange(event) {
    var value = event.target.value;

    this.setState({
      message: value,
    });
  }

  componentDidMount() {
    setClassName("main", "support");
  }

  componentWillUnmount() {
    setClassName("main", "");
  }

  sendMessage(e) {
    e.preventDefault();
    var formData = { message: this.state.message };

    if (this.state.message.length <= 1) {
      window.kk.notifier.notify("Please enter a message to send", "error");
      return false;
    }

    // Disable textarea and button
    $("#message-field").attr("disabled", "disabled");
    $("#message-submit-button").attr("disabled", "disabled");
    $("#message-submit-button").text("Sending..");

    $.ajax({
      url: "/support/send_message",
      type: "POST",
      dataType: "JSON",
      data: formData,
      success: function (data) {
        window.kk.notifier.notify(data.message, "success");
        $("#message-submit-button").text("Thank you!");
      },
      error: function (error) {
        window.kk.notifier.notify(
          "Error sending message, please email us at admin@krosskast.com",
          "error"
        );
        //
      },
    });
  }

  notice() {
    setClassName("main", "dashboard notice");
    return (
      <div className="channels notice">
        <div className="channels notice banner">
          <span>Master Account</span>
        </div>
        <p>
          <h4>You are currently logged into your master account.</h4>
          This master account is designed for you to view and manage all of your
          locations, talent, talent requests, and messaging schedules. It cannot
          be used as one of your locations to listen to music. Therefore the
          “Channels” page is not active within your master account.
        </p>
        <p>
          <h4>How do I listen to music?</h4>
          Free KrossKast App: Mobile, Tablet, Chrome Devices
          <ol>
            <li>
              Navigate to the Locations page located on the side bar to access
              your login credentials. Use your PIN to access passwords.
            </li>
            <li>
              With the device you will be streaming on, search for “KrossKast”
              in the Google Play Store or Apple App Store.
            </li>
            <li>
              Download and open our app. Enter your location’s username and
              password found on the Locations page.
            </li>
            <li>
              Click Sign In and select a music channel to start streaming
              unlimited music.
            </li>
          </ol>
          <a
            href="https://www.youtube.com/watch?v=D3KT4K03BD8&feature=youtu.be"
            target="_blank"
          >
            Video Tutorial
          </a>
        </p>
        <p>
          Free Web App: Desktop, Laptop
          <ol>
            <li>
              Navigate to the Locations page located on the side bar to access
              your login credentials. Use your PIN to access passwords.
            </li>
            <li>
              On the desktop or laptop that you’ll be streaming from, open the
              Chrome Web Browser.
            </li>
            <li>
              Go to KrossKast.com and sign in. Enter your location’s username
              and password found on the Locations page.
            </li>
            <li>
              Click Sign In and navigate to the Channels page to start streaming
              unlimited music.
            </li>
          </ol>
          <a
            href="https://www.youtube.com/watch?v=c2fxo3euhtA&feature=youtu.be"
            target="_blank"
          >
            Video Tutorial
          </a>
        </p>
        <p>
          For best practices for device and computer streaming, please read our{" "}
          <a href="https://krosskast.com/faq" target="_blank">
            FAQ
          </a>
        </p>
        <p>
          <h4>Lost your PIN?</h4>
          If you lost your PIN please go to the “Support” page and contact us to
          reset your PIN, (additional verification will be required).
        </p>
      </div>
    );
  }

  render() {
    var csrfToken = $("meta[name=csrf-token]").attr("content");
    const { role } = this.context;
    return (
      <div>
        <h1>Support</h1>
        <br />
        <form
          onSubmit={this.sendMessage}
          role="form"
          acceptCharset="UTF-8"
          className="row"
        >
          <div className="col-sm-12">
            <label htmlFor="message-field">
              Send us a message and we will get back to you.
            </label>
          </div>
          <div className="col-sm-12">
            <textarea
              type="text"
              name="message"
              width="100%"
              rows="8"
              cols="60"
              id="message-field"
              value={this.state.message || ""}
              onChange={this.handleMessageChange}
            ></textarea>
          </div>
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <div className="col-sm-12">
            <button
              type="submit"
              value="Submit"
              id="message-submit-button"
              className="button btn"
            >
              Send
            </button>
          </div>
        </form>
        {role !== "member" && this.notice()}
      </div>
    );
  }
}

export default Support;
