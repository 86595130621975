import React, { useState } from "react";

const ReplacementConfirmation = ({ onConfirm, replacementChannels }) => {
  const [replacement, setReplacement] = useState(replacementChannels[0].id);
  return (
    <div className="confirm_container">
      <h4>Before you decline ...</h4>
      <p>
        The channel(s) is currently set in one or more zones. <br />
        <br />
        Please select a replacement channel.
      </p>
      <select
        value={replacement}
        onChange={(e) => setReplacement(e.target.value)}
      >
        {replacementChannels.map((r) => (
          <option key={r.id} value={r.id}>
            {r.name}
          </option>
        ))}
      </select>
      <button className="red-btn" onClick={() => onConfirm(replacement)}>
        Confirm
      </button>
    </div>
  );
};

export default ReplacementConfirmation;
