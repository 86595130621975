import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import {Box, Switch, Text} from '../elements'
import TalentModal from './TalentModal'
import DisableMessageModal from "./DisableMessageModal";

const RequestItem = ({ talentRequest, requestType, refreshRequests }) => {
  const [playButtonText, setPlayButtonText] = useState("Play");
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const [showDisabledMessageModal, setShowDisabledMessageModal] =
    useState(false);
  const [declined, setDeclined] = useState(false);
  const history = useHistory();

  const talent = talentRequest ? talentRequest.talent : null;
  const image = talent ? talent.image : null;
  const thumb = image ? image.thumb : { url: "" };

  useEffect(() => {
    $("requestInfo").popover({
      trigger: "hover",
      placement: "auto top",
    });
  }, []);

  const hasButton = () => {
    return (
      ["pending_approval", "approved", "live", "disabled"].indexOf(
        requestType
      ) > -1
    );
  };
  const updateMessageConfirmation = (disable_messaging_confirmation) => {
    $.ajax({
      url: `/api/v2/schedule_messaging_confirmation/`,
      dataType: "json",
      type: "PUT",
      data: { disable_messaging_confirmation: disable_messaging_confirmation },
    });
  };

  const handleDisableClick = (e, requestAction) => {
    $.get("/api/v2/schedule_messaging_confirmation/", (result) => {
      setShowDisabledMessageModal(result.disable_messaging_confirmation);
      if (result.disable_messaging_confirmation == false)
        updateStatus(e, requestAction);
    });
  };

  const getButtonName = () => {
    // Decline is a special case, directly handled in the html
    switch (requestType) {
      case "pending_approval":
        return "Approve";
        break;
      case "disabled":
      case "approved":
        return "Go Live";
        break;
      case "live":
        return "Disable";
        break;
    }
  };

  const displayModal = (declined) => {
    if (declined) {
      setDeclined(declined);
    }
    setModalDisplayed(!modalDisplayed);
  };

  const acceptRequest = (e) => {
    updateStatus(e, "approve");
    setModalDisplayed(!modalDisplayed);
    history.push("/messaging_schedule");
  };

  const toggleModal = () => {
    if (modalDisplayed) {
      stopAudio();
    }
    setModalDisplayed(!modalDisplayed);
    refreshRequests();
  };

  const updateStatus = (e, requestAction) => {
    let request_action = "";

    if (requestAction) {
      request_action = requestAction;
    } else {
      switch (e.target.innerText.toLowerCase()) {
        case "toggle":
          request_action = "toggle";
        case "approve":
          request_action = "approve";
          break;
        case "go live":
          request_action = "make_live";
          break;
        case "disable":
          request_action = "disable";
          break;
        case "decline":
          request_action = "decline";
          break;
      }
    }

    const url =
      "/api/update_message_status/" + talentRequest.id + "/" + request_action;
    $.ajax({
      url: url,
      type: "PUT",
      dataType: "JSON",
      success: (data) => {
        window.kk.notifier.notify(
          "Talent request updated successfully.",
          "success"
        );
        refreshRequests();
      },
      error: (error) => {
        window.kk.notifier.notify(
          "Error updating talent request, please contact support.",
          "error"
        );
      },
    });
  };

  const playSoundByte = () => {
    const audio = window.kk.player.overlayAudio;
    stopAudio();
    if (audio && audio._src == talentRequest.sound.url) {
      return setPlayButtonText("Play");
    }
    setPlayButtonText("Loading");

    window.kk.player.loadOverlayAudio(
      talentRequest.sound.url,
      () => {
        setPlayButtonText("Stop");
      },
      () => {
        setPlayButtonText("Play");
      },
      () => {
        setPlayButtonText("Error");
      }
    );
  };

  const stopAudio = () => {
    const audio = window.kk.player.overlayAudio;
    if (audio) {
      audio.unload();
    }
    window.kk.player.overlayAudio = undefined;
  };

  const toggleGlobalMessaging = () => {
    const url = `/api/toggle_global_message/${talentRequest.id}`;
    $.ajax({
      url: url,
      type: "PUT",
      dataType: "JSON",
      success: function (data) {
        window.kk.notifier.notify(
          "Global messaging updated successfully.",
          "success"
        );
      },
      error: function (error) {
        window.kk.notifier.notify(
          "Error updating talent request, please contact support.",
          "error"
        );
      },
    });
  };

  return (
    <Box pb="10px" className="talent item-container col-sm-3 no-hover">
      <div className="talent-content box-item">
        <Box
          minHeight="173px"
          minWidth="173px"
          className="talent-image-holder request-item-img"
        >
          <img src={thumb.url} />
          <div className="preview-overlay">
            <div className="btn-group-vertical request-item-button-group">
              {hasButton() ? (
                <button
                  className={`btn btn-sm primary-btn request-item-button ${
                    requestType === "disabled"
                      ? `${requestType}-item`
                      : requestType
                  }`}
                  onClick={playSoundByte}
                >
                  {playButtonText}
                </button>
              ) : (
                ""
              )}
              {hasButton() && requestType === "pending_approval" ? (
                <button
                  className={`btn btn-primary btn-sm secondary-btn request-item-button ${requestType}`}
                  onClick={(e) => displayModal(false)}
                >
                  {getButtonName()}
                </button>
              ) : (
                ""
              )}
              {hasButton() && requestType === "pending_approval" ? (
                <button
                  className={`btn btn-primary btn-sm secondary-btn request-item-button decline ${requestType}`}
                  onClick={(e) => displayModal(true)}
                >
                  Decline
                </button>
              ) : (
                ""
              )}
              {hasButton() &&
              (requestType === "live" || requestType === "approved") ? (
                <button
                  className={`btn btn-primary btn-sm secondary-btn request-item-button`}
                  onClick={
                    requestType == "live" ? handleDisableClick : updateStatus
                  }
                >
                  {getButtonName()}
                </button>
              ) : (
                ""
              )}
              {hasButton() && requestType === "disabled" ? (
                <button
                  className={`btn btn-primary btn-sm secondary-btn request-item-button`}
                  onClick={updateStatus}
                >
                  {getButtonName()}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </Box>
        <div
          className="talent-info-box message-request-item"
          data-toggle="popover"
          data-content={talentRequest.message}
        >
          <Box className="talent-info" overflow="scroll" height="30px">
            <Text className="title">{talentRequest.name}</Text>
          </Box>
        </div>
        {requestType === "live" ? (
          <div className="global-switch">
            <div className="switch-label">Global</div>
            <div className="global-message-switch">
              {requestType === "live" ? (
                <Switch
                  id={talentRequest.id}
                  name={talentRequest.name}
                  isChecked={talentRequest.global}
                  onChange={toggleGlobalMessaging}
                />
              ) : (
                <span></span>
              )}
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </div>
      <TalentModal
        talent={talent}
        talentRequest={talentRequest}
        stopAudio={stopAudio}
        displayed={modalDisplayed}
        toggleModal={toggleModal}
        acceptTalentRequest={acceptRequest}
        declined={declined}
      />
      {showDisabledMessageModal && (
        <DisableMessageModal
          onClose={() => setShowDisabledMessageModal(false)}
          showDisabledMessageModal={showDisabledMessageModal}
          updateStatus={updateStatus}
          updateMessageConfirmation={updateMessageConfirmation}
        />
      )}
    </Box>
  );
};

export default RequestItem;
