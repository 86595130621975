import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { CreditCalculator } from '../Credits'
import { CreditsContext } from '../contexts'
import { Box, Button, Flex, Grid, Text, TextArea } from '../elements'

const TalentModal = (props) => {
  const [displayed, setDisplayed] = useState(props.displayed)
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [feedback, setFeedback] = useState('')
  const [playButtonText, setPlayButtonText] = React.useState('Preview');
  const { availableCredits, setAvailableCredits } = useContext(CreditsContext)

  useEffect(() => {
    setDisplayed(props.displayed);
  }, [props.displayed])

  useEffect(() => {
    if (props.talentRequest) {
      setMessage(props.talentRequest.message);
    }
  }, [props.talentRequest])

  useEffect(() => {
    if (props.talentRequest) {
      setName(props.talentRequest.name);
    }
  }, [props.talentRequest])

  const closeModal = (event) => {
    if (event.target.classList.contains("talent-modal")) {
      setDisplayed(!displayed)
      props.toggleModal()
    }
  }

  const toggleCreditsModal = (event) => {
    event.preventDefault()
    props.toggleCreditsModal()
  }

  const createTalentRequest = (event) => {
    event.preventDefault()
    if (!name || !message) {
      window.kk.notifier.notify("Name and message are required!", "error");
      return
    }
    $.ajax({
      url: '/api/v2/talent_requests',
      type: 'POST',
      dataType: 'JSON',
      data: {
        talent_request: {
          talent_id: props.talent.id,
          name: name,
          message: message
        }
      },
      success: (data) => {
        window.kk.notifier.notify("Talent request created successfully.", "success");
        setAvailableCredits(availableCredits - 1)
        props.toggleModal()
      },
      error: (error) => {
        window.kk.notifier.notify("Error creating talent request, please contact support.", "error");
      }
    });
  }

  const declineTalentRequest = (event) => {
    event.preventDefault()
    const requestId = props.talentRequest ? props.talentRequest.id : ''
    $.ajax({
      url: `/api/v2/talent_requests/${requestId}`,
      type: 'PATCH',
      dataType: 'JSON',
      data: {
        talent_request: {
          feedback: feedback,
          status: 'decline'
        }
      },
      success: (data) => {
        window.kk.notifier.notify("Talent request declined.", "success");
        props.toggleModal()
      },
      error: (error) => {
        window.kk.notifier.notify("Error updateing talent request, please contact support.", "error");
      }
    });
  }

  const acceptTalentRequest = () => {
    props.acceptTalentRequest()
  }

  const playSoundByte = (event) => {
    event.preventDefault()
    if (window.kk.player.overlayAudio) {
      props.stopAudio()
      setPlayButtonText('Preview')
      return
    }

    window.kk.player.loadOverlayAudio(
      props.talent.soundbyte.url,
      function () {
        setPlayButtonText('Playing')
      },
      function () {
        setPlayButtonText('Play')
      },
      function () {
        setPlayButtonText('Error')
      }
    );
  }

  const modalContent = () => {
    if (!props.talentRequest) {
      return <Box p='30px'>
        <legend>My Credit Calculator</legend>
        <CreditCalculator pl='6px' />
        <Flex justifyContent='center' pt='30px'>
          {
            availableCredits == 0 ? <Button onClick={toggleCreditsModal}>Purchase More Credits</Button> : <Button onClick={createTalentRequest}>Create Talent Request</Button>
          }
        </Flex>
      </Box >
    }

    if (props.talentRequest && props.declined) {
      return <Box p='30px'>
        <legend>Change Request</legend>
        <Flex flexDirection='column' pl='6px'>
          <label htmlFor="message" className="control-label" > Script/Message:</label >
          <TextArea
            name="message"
            rows="5"
            maxLength="1000"
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
            placeholder={"Please let us know what you would like us to change."}
          ></TextArea>
        </Flex>
        <Flex justifyContent='center' pt='30px'>
          <Button onClick={declineTalentRequest}>Submit</Button>
        </Flex>
      </Box >
    } else {
        return <Box p='30px'>
          <legend>Request Approved</legend>
          <Flex flexDirection='column' pl='6px'>
            <p>You're all set! Please go to the "Messaging Schedule" page to view your messages to make your messaging or branding "Go Live"!</p>
          </Flex>
          <Flex justifyContent='center' pt='30px'>
            <Button onClick={acceptTalentRequest}>Go To Messaging Schedule</Button>
          </Flex>
        </Box >
    }
  }

  return (
    <Box display='flex' className={displayed ? "modal talent-modal" : "hidden"}  onClick={closeModal}>
      <Box className="modal-dialog modal-lg"  maxHeight='550px'>
        <div className="modal-content" >
          <Flex justifyContent='flex-end' pr='8px' pt='8px'>
            <FontAwesomeIcon color='grey' icon={solid('times')} onClick={props.toggleModal}/>
          </Flex>
          <form>
            <Box className="modal-body" >
              <div className="row" >
                <div className="col-sm-4" >
                  <h3><img src={ props.talent.image.thumb.url } /></h3 >
                </div >
                <div className="col-sm-8" >
                  <h3 className="name" >{ props.talent.name }</h3 >
                  {props.talentRequest ? <></> :
                    <button
                      className="preview_soundbyte btn btn-primary"
                      onClick={playSoundByte}
                      disabled={(props.talent.soundbyte && props.talent.soundbyte.url) ? false : 'disabled'}
                    >{playButtonText}</button >
                  }
                  <div className="bio" >{
                    (props.talentRequest && props.declined) ? <p>Please explain what needs to be fixed in the audio element. When finished click "submit" and it will automatically be sent back to Step 1, so we can revise it and send it back to "Awaiting Approval" Step 2.</p> : props.talent.bio
                  }</div >
                </div >
              </div >
              <hr />
              <Grid gridTemplateColumns="3fr 2fr">
                <Flex flexDirection='column' py='30px' px='50px' borderRight='1px solid #eeeeee'>
                  <legend>{props.talentRequest ? 'Current Request' : 'Create a new request'}</legend>
                  <Flex flexDirection="row" pb='20px' pl='6px'>
                    <Text fontWeight='bold' m='auto' mr='15px'>Title:</Text>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      value={ name }
                      disabled={!!props.talentRequest}
                      onChange={ e => setName(e.target.value) }
                      placeholder="Name your message ( for identifying later )"
                    />
                  </Flex>
                  <Flex flexDirection='column' pl='6px'>
                    <label htmlFor="message" className = "control-label" > Script / Message:</label >
                    <TextArea
                      name="message"
                      rows="5"
                      maxLength="1000"
                      value={ message }
                      disabled={!!props.talentRequest}
                      onChange={ e => setMessage(e.target.value) }
                      placeholder={ "Script / Message you would like " + props.talent.name + " to read. Limit 150 words." }
                    ></TextArea>
                  </Flex >
                </Flex >
                { modalContent() }
              </Grid >
            </Box >
          </form >
        </div >
      </Box >
    </Box >
  )
}

export default TalentModal
