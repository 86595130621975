import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Box, Flex, UnstyledButton } from '../elements'
import { Channel } from '../Channels'

const ChannelWrapper = ({channel, selectChannel, player}) => {
  const [showButtons, setShowButtons] = useState(false)
  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    const currentChannel = player.currentChannelId == channel.id
    const playing = currentChannel && player.playing
    setPlaying(playing)
  }, [player])

  const icon = playing ? solid('stop') : solid('play')

  const playChannel = (event) => {
      event.preventDefault()
      if (player.playing || player.loading) {
        player.pause()
        setPlaying(false)
      } else {
        player.loadChannel(channel.id)
        player.playNextSong()
        setPlaying(true)
      }
  }

  return (
    <Box
      height='210px'
      width='180px'
      p={10}
      ml='7px'
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <Channel
        channel={channel}
        hideDescription={true}
        onChannelClick={() => null}
      >
      { showButtons &&
        <Flex
          position='absolute'
          zIndex={2}
          top={0}
          height='90%'
          width='100%'
          pt='73%'
          bg='rgba(0, 0, 0, 0.5)'
        >
          <UnstyledButton
            bg='#E21E26'
            borderRadius='50%'
            height='35px'
            width='35px'
            mx='10px'
            p='10px'
            pl='13px'
            pt='9px'
            onClick={event => playChannel(event)}
          >
            <FontAwesomeIcon icon={icon} color='black' />
          </UnstyledButton>
          <UnstyledButton
            color='white'
            bg='#E21E26'
            borderRadius='25px'
            height='35px'
            fontFamily='Montserrat'
            onClick={selectChannel}
          >Select</UnstyledButton>
        </Flex>
      }
      </Channel>
    </Box>
  )
}

export default ChannelWrapper
