import React from 'react';

class License extends React.Component {
  render() {
    var license = this.props.license
    return (
      <div className="license-content">
        <span>{license.project_title}</span>
        <span>{license.project_description}</span>
        <span>{license.sublicense ? license.sublicense.name : "None"}</span>
        <span>{license.project_category}</span>
        <span>{license.created_at}</span>
        <span>{license.license_number}</span>
      </div>
    )
  }
}

export default License