import React, { useEffect, useState, useContext } from "react";

import { Box, Flex, Slider } from "./elements";
import { MediaContext } from "./contexts";

const AudioPlayer = () => {
  const [volume, setVolume] = useState(0);
  const [volumeBarDisplayed, setVolumeBarDisplayed] = useState(false);
  const { media, setMedia } = useContext(MediaContext);

  useEffect(() => {
    window.kk = window.kk || {};
    if (!kk.player) {
      kk.player = new kk.AudioPlayer();
    }
  }, []);

  const onMediaChange = () => {
    setMedia({ ...media, playing: !media.playing });
  };

  const toggleVolumeBar = () => {
    var display = !volumeBarDisplayed;
    setVolumeBarDisplayed(display);
    if (display) {
      setCurrentVolume();
    }
  };

  const setCurrentVolume = () => {
    if (kk.player) {
      setVolume(kk.player.volume);
    }
  };

  const changeVolume = (value) => {
    setVolume(value);
    if (kk.player) {
      kk.player.setVolume(value);
    }
  };

  return (
    <Flex className="audio-player" flexDirection="row">
      <div className="controlsOuter">
        <div className="controlsInner">
          <div id="loading"></div>
          <div onClick={onMediaChange} className="audio-btn" id="playBtn"></div>
          <div
            onClick={onMediaChange}
            className="audio-btn"
            id="pauseBtn"
          ></div>
          <div className="audio-btn" id="nextBtn"></div>
        </div>
      </div>

      <div id="timer">0:00</div>

      <div id="progressContainer">
        <div id="waveform"></div>
        <div id="bar"></div>
        <div id="progress"></div>
        <div id="player-song-info">
          <span id="track"></span>
          <span id="album-artist"></span>
        </div>
      </div>

      <div id="duration">0:00</div>

      <div id="volumeControls">
        <Box
          ml="13px"
          className="audio-btn"
          id="volumeBtn"
          onClick={toggleVolumeBar}
        ></Box>
        <Slider
          value={volume}
          setValue={changeVolume}
          displayed={volumeBarDisplayed}
          toggleDisplayed={toggleVolumeBar}
        />
      </div>
    </Flex>
  );
};

export default AudioPlayer;
