import React, { useState } from "react";
import declinedFaq from "../../images/declined-faq.png";

const EmptyDeclined = ({ setApproved }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <div className="empty-declined-root">
      <div>
        <img width="250" src={declinedFaq}></img>
      </div>
      <div className="message">
        <p>
          You currently do not have any channels marked as declined. To set a
          channel as declined, visit the &nbsp;
          <span className="approved-link" onClick={() => setApproved(true)}>
            Approved Channels
          </span>{" "}
          view and decline the channel you choose.
        </p>
        <div>
          <h4>Questions?</h4>
          <span className="question" onClick={() => setShowAnswer(!showAnswer)}>
            <p className="red-dash">{showAnswer ? "-" : "+"}</p>
            <p className="plus">What does it mean to decline a channel?</p>
          </span>
          {showAnswer && (
            <p className="answer">
              When decline a channel, the channel artwork will be hidden from
              your devices and it will no longer be available to be set/played
              within your locations
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptyDeclined;
