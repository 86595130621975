import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, ClickableText, Flex, Grid, Text } from "../elements";
import ZoneRow from "./ZoneRow";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import refreshIcon from "../../images/Icon-material-refresh.png";

const Zones = ({ zones, refreshData, isLoading }) => {
  const history = useHistory();

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Box className="zones-details">
      <Grid p={10} gridTemplateColumns="1fr 4fr 3fr 4fr 4fr 2fr" bg="#F3F6FA">
        <OverlayTrigger
          overlay={<Tooltip className="customTooltip">Refresh Zones</Tooltip>}
        >
          <button
            className={
              isLoading ? "refresh-button disabled-button" : "refresh-button"
            }
            disabled={isLoading}
            onClick={refreshData}
          >
            <img className="zone-refresh-icon" src={refreshIcon} />
          </button>
        </OverlayTrigger>
        <strong
          style={{
            fontFamily: "Montserrat",
          }}
        >
          Zones
        </strong>
        <strong
          style={{
            fontFamily: "Montserrat",
          }}
        >
          Channel
        </strong>
        <strong
          style={{
            fontFamily: "Montserrat",
          }}
        >
          Song
        </strong>
        <strong
          style={{
            fontFamily: "Montserrat",
          }}
        >
          Message
        </strong>
        <strong
          style={{
            fontFamily: "Montserrat",
          }}
        >
          Last Played
        </strong>
      </Grid>
      <Box>
        {zones.map((zone) => (
          <ZoneRow key={zone.code} zone={zone} />
        ))}
      </Box>
      <Grid p={10} gridTemplateColumns="1fr 3fr 4fr 3fr 3fr">
        <span></span>
        <Flex>
          <Text fontFamily="Montserrat" fontWeight="600">
            Add Zone
          </Text>
          <ClickableText
            pl="5px"
            fontSize="0.85em"
            onClick={() => history.push("/support")}
          >
            <FontAwesomeIcon icon={solid("circle-plus")} />
          </ClickableText>
        </Flex>
      </Grid>
    </Box>
  );
};

export default Zones;
