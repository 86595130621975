import React from 'react';

const UserContext = React.createContext({
  admin: false,
  role: '',
  userRole: '',
  tenantName: '',
  plans: {}
})

export default UserContext