import React, { useState, useEffect } from "react";
import { Box, Flex, Grid, Text, UnstyledButton } from "../elements";
import { SearchBar } from "../FilterNav";
import ZoneOptionRow from "./ZoneOptionRow";
import ZoneSelectionModal from "./ZoneSelectionModal";
import ChannelPlayWrapper from "../Channels/ChannelPlayWrapper";

const ZonesMusic = ({ zones, fetchZones }) => {
  const [zoneOptions, setZoneOptions] = useState([]);
  const [selectedZone, setSelectedZone] = useState({});
  const [selectedChannel, setSelectedChannel] = useState({});
  const [channels, setChannels] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetch("/api/approved_channels.json")
      .then((response) => response.json())
      .then((data) => setChannels(data));
  }, []);

  useEffect(() => {
    setZoneOptions(zones);
    zones && setSelectedZone(zones[0]);
  }, [zones]);


  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const submitSearch = (search) => {
    fetch(`/api/approved_channels.json?q=${search}`)
      .then((response) => response.json())
      .then((data) => setChannels(data));
  };

  const saveZoneMusic = (option) => {
    const data = {
      channel: selectedChannel.id,
      option: option,
    };
    $.ajax({
      url: `/api/v2/zones/${selectedZone.id}/current_channel`,
      type: "POST",
      dataType: "JSON",
      data: data,
      success: function (data) {
        window.kk.notifier.notify("Channel updated.", "success");
        fetchZones();
      },
      error: function (error) {
        window.kk.notifier.notify(
          "Error updating channel, please contact support.",
          "error"
        );
      },
    });
  };

  const selectZone = (zone) => {
    setSelectedZone(zone);
  };

  const selectChannel = (channel) => {
    setModalOpen(true);
    setSelectedChannel(channel);
  };

  const zoneRows = zoneOptions.flatMap((zone) => {
    return zone.master ? (
      []
    ) : (
      <ZoneOptionRow
        key={zone.code}
        zone={zone}
        rowType={"zonesMusic"}
        checked={selectedZone.code === zone.code}
        selectZone={() => selectZone(zone)}
      />
    );
  });

  const player = window.kk && window.kk.player ? window.kk.player : {};

  const hiddenStuff = (channel) => {
    return (
      <UnstyledButton
        color="white"
        bg="#E21E26"
        borderRadius="25px"
        height="35px"
        fontFamily="Montserrat"
        onClick={() => selectChannel(channel)}
      >
        Select
      </UnstyledButton>
    );
  };

  const channelIcons = channels.map((channel) => {
    return (
      <ChannelPlayWrapper
        key={channel.id}
        channel={channel}
        player={player}
        hiddenStuff={hiddenStuff}
      />
    );
  });

  return (
    <Box bg="white" height={500} overflowY="scroll" borderRadius="6px">
      <Text
        p={10}
        pl={15}
        pt={20}
        fontFamily="Montserrat"
        fontSize="1.5em"
        fontWeight="bold"
      >
        Zones Music
      </Text>
      <Grid py={10} pl={20} gridTemplateColumns="10fr 8fr 21fr" bg="#F3F6FA">
        <Text m={0} fontFamily="Montserrat" fontWeight="600">
          Zone Name
        </Text>
        <Text m={0} fontFamily="Montserrat" fontWeight="600">
          Channel
        </Text>
        <Text m={0} fontFamily="Montserrat" fontWeight="600">
          Select Channel
        </Text>
      </Grid>
      <Box pt={10}>
        <Grid gridTemplateColumns="8fr 9fr">
          <Box>{zoneRows}</Box>
          <Box>
            <SearchBar
              border="1px solid #bdb4c0"
              borderRadius="3px"
              mr="13px"
              mb="10px"
              submitSearch={submitSearch}
            />
            <Flex
              flexWrap="wrap"
              flexDirection="row"
              overflowY="scroll"
              maxHeight="350px"
              boxShadow="0px 1px 5px #bdb4c0"
              mr="15px"
              justifyContent="space-between"
            >
              {channelIcons}
            </Flex>
          </Box>
        </Grid>
      </Box>
      <ZoneSelectionModal
        zone={selectedZone}
        displayed={modalOpen}
        handleClose={toggleModal}
        saveInfo={saveZoneMusic}
      />
    </Box>
  );
};

export default ZonesMusic;
