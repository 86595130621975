import React from 'react';

class SublicenseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      company: {},
      profile: {},
      displayed: false,
      isLoading: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSublicenseSubmit = this.handleSublicenseSubmit.bind(this)
  }

  componentWillReceiveProps({displayed, sublicense}) {
    if (sublicense && !this.state.id) {
      var company = {name: sublicense.name, address: sublicense.address, number: sublicense.number}
      var profile = sublicense.profile
      this.setState({
        id: sublicense.id,
        displayed: displayed,
        company: company,
        profile: profile
      })
    } else {
      this.setState({
        id: null,
        displayed: displayed,
        company: {},
        profile: {}
      })
    }
  }

  handleChange(event) {
    event.preventDefault()
    var target = event.target;
    var [fieldset, field] = target.name.split("__");
    var value = target.value
    var currentFieldset = this.state[fieldset]

    this.setState({
      [fieldset]: {...currentFieldset, [field]: value}
    });
  }

  handleClose(event) {
    if (event.target.classList.contains("sublicense-modal")) {
      this.setState({
        company: {},
        profile: {}
      });
      this.props.handleClose(event)
    }
  }

  handleSublicenseSubmit(event) {
    event.preventDefault()
    if (!this.state.company.name) {
      return window.kk.notifier.notify("Company Name is required.", "error");
    }
    if (!this.state.company.number) {
      return window.kk.notifier.notify("Company Number is required.", "error");
    }
    if (!this.state.company.address) {
      return window.kk.notifier.notify("Company Address is required.", "error");
    }
    if (Object.keys(this.state.profile).length === 0) {
      return window.kk.notifier.notify("At least one social profile is required.", "error");
    }
    this.setState({
      isLoading: true
    })
    url = this.state.id ? `/api/v2/sublicenses/${this.state.id}` : '/api/v2/sublicenses'
    verb = this.state.id ? 'PATCH' : 'POST'
    message = this.state.id ? "Updated sublicense." : "Added new sublicense."
    this.serverRequest =
    $.ajax({
        url: url,
        type: verb,
        data: {
          sublicense: this.state.company,
          profile: this.state.profile
        },
        success: (result) => {
          window.kk.notifier.notify(message, "success");
          this.setState({
            isLoading: false,
            id: null,
            company: {},
            profile: {}
          });
          this.props.handleClose(event, null, true)
        },
        error: (error) => {
          this.setState({
            isLoading: false
          });
          window.kk.notifier.notify("Error adding sublicense, please contact support.", "error");
        }
    })
  }

  render() {
    return (
      <div className={this.state.displayed ? "modal sublicense-modal" : "hidden"} onClick={this.handleClose}>
        <div className="authform">
          <form onSubmit={this.handleSublicenseSubmit}>
            <div className="modal-top">
              <div>
                <strong>Sublicense Details</strong>
              </div>
            </div>
            <div className="form-group">
              <input
                name='company__name'
                type='text_field'
                className='form-control'
                placeholder='Company Name'
                value={this.state.company.name || ''}
                onChange={this.handleChange}
                autoFocus />
              <input
                name='company__number'
                type='text_field'
                className='form-control'
                placeholder='Company Number'
                value={this.state.company.number || ''}
                onChange={this.handleChange} />
              <input
                name='company__address'
                type='text_field'
                className='form-control'
                placeholder='Company Address'
                value={this.state.company.address || ''}
                onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <div className="form-field-container">
                <input
                  name='profile__facebook'
                  type='text_field'
                  className='form-control social'
                  placeholder='Facebook'
                  value={this.state.profile.facebook || ''}
                  onChange={this.handleChange} />
                <i className="fab fa-facebook-f profile-icon"></i>
              </div>
              <div className="form-field-container">
                <input
                  name='profile__instagram'
                  type='text_field'
                  className='form-control social'
                  placeholder='Instagram'
                  value={this.state.profile.instagram || ''}
                  onChange={this.handleChange} />
                <i className="fab fa-instagram-square profile-icon"></i>
              </div>
              <div className="form-field-container">
                <input
                  name='profile__podcast'
                  type='text_field'
                  className='form-control social'
                  placeholder='Podcast'
                  value={this.state.profile.podcast || ''}
                  onChange={this.handleChange} />
                <i className="fas fa-rss profile-icon"></i>
              </div>
              <div className="form-field-container">
                <input
                  name='profile__twitch'
                  type='text_field'
                  className='form-control social'
                  placeholder='Twitch'
                  value={this.state.profile.twitch || ''}
                  onChange={this.handleChange} />
                <i className="fab fa-twitch profile-icon"></i>
              </div>
              <div className="form-field-container">
                <input
                  name='profile__twitter'
                  type='text_field'
                  className='form-control social'
                  placeholder='Twitter'
                  value={this.state.profile.twitter || ''}
                  onChange={this.handleChange} />
                <i className="fab fa-twitter profile-icon"></i>
              </div>
              <div className="form-field-container">
                <input
                  name='profile__youtube'
                  type='text_field'
                  className='form-control social'
                  placeholder='Youtube'
                  value={this.state.profile.youtube || ''}
                  onChange={this.handleChange} />
                <i className="fab fa-youtube profile-icon"></i>
              </div>
              <div className="form-field-container">
                <input
                  name='profile__website'
                  type='text_field'
                  className='form-control social'
                  placeholder='Website'
                  value={this.state.profile.website || ''}
                  onChange={this.handleChange} />
                <i className="fas fa-user profile-icon"></i>
              </div>
            </div>
            <div className="form-group">
              {this.state.isLoading ? <div className="loading"></div> : <input type="submit" value="Update" className='button btn btn-primary red save-button' />}
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default SublicenseModal