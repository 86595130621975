import React, { useState, useEffect } from "react";
import {
  CheckBox,
  Box,
  Flex,
  Grid,
  Input,
  Text,
  UnstyledButton,
} from "../elements";
import ZoneOptionRow from "./ZoneOptionRow";
import ZoneSelectionModal from "./ZoneSelectionModal";

const ZonesMessaging = ({ locationId, zones, setZones }) => {
  const [zoneOptions, setZoneOptions] = useState([]);
  const [talentRequests, setTalentRequests] = useState([]);
  const [songCount, setSongCount] = useState();
  const [global, setGlobal] = useState(false);
  const [local, setLocal] = useState(false);
  const [selectedZone, setSelectedZone] = useState({});
  const [selectedMessages, setSelectedMessages] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setZoneOptions(zones);
    if (zones) {
      const zone = zones[0];
      zone && selectZone(zone);
    }
  }, [zones]);

  useEffect(() => {
    fetch("/api/v2/talent_requests")
      .then((response) => response.json())
      .then((data) => setTalentRequests(data));
  }, []);

  const fetchZones = () => {
    fetch(`/api/v2/locations/${locationId}/zones`)
      .then((response) => response.json())
      .then((data) => {
        setZones(data);
      });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const selectZone = (zone) => {
    const { messaging, messageSchedule, messages } = zone;
    setSelectedZone(zone);
    setSongCount((messageSchedule && messageSchedule.interval) || 0);
    setGlobal((messaging && messaging.global) || false);
    setLocal((messaging && messaging.local) || false);
    const currentMessages = messages.reduce((acc, message) => {
      acc[message] = message;
      return acc;
    }, {});
    setSelectedMessages(currentMessages);
  };

  const changeSongCount = (event) => {
    const count = parseInt(event.target.value);
    setSongCount(count || 0);
  };

  const changeSelectedMessages = (event) => {
    const message = event.target.id;
    if (selectedMessages[message]) {
      delete selectedMessages[message];
    } else {
      selectedMessages[message] = event.target.id;
    }
    setSelectedMessages(selectedMessages);
  };

  const saveZoneMessaging = (option) => {
    const data = {
      interval: songCount,
      global: global,
      local: local,
      option: option,
      messages: Object.keys(selectedMessages),
    };
    $.ajax({
      url: `/api/v2/zones/${selectedZone.id}/messaging_schedule`,
      type: "POST",
      dataType: "JSON",
      data: data,
      success: function (data) {
        window.kk.notifier.notify("Messaging updated.", "success");
        fetchZones();
      },
      error: function (error) {
        window.kk.notifier.notify(
          "Error updating channel, please contact support.",
          "error"
        );
      },
    });
  };

  const zoneRows = zoneOptions.flatMap((zone) => {
    return zone.master ? (
      []
    ) : (
      <ZoneOptionRow
        key={zone.code}
        zone={zone}
        rowType={"zonesMessaging"}
        checked={selectedZone.code === zone.code}
        selectZone={() => selectZone(zone)}
      />
    );
  });

  const messages = talentRequests.map((talentRequest) => {
    const checked = local && !!selectedMessages[talentRequest.id];
    return (
      <Flex flexDirection="row" key={talentRequest.id}>
        <CheckBox
          mt="3px !important"
          mr="5px !important"
          id={talentRequest.id}
          defaultChecked={checked}
          onChange={changeSelectedMessages}
        />
        <Text fontFamily="Montserrat">{talentRequest.name}</Text>
      </Flex>
    );
  });

  return (
    <Box bg="white" height={500} overflowY="scroll" borderRadius="6px">
      <Text
        p={10}
        pl={15}
        pt={20}
        fontFamily="Montserrat"
        fontSize="1.5em"
        fontWeight="bold"
      >
        Zones Messaging
      </Text>
      <Grid py={10} pl={20} gridTemplateColumns="5fr 4fr 4fr 10fr" bg="#F3F6FA">
        <Text m={0} fontFamily="Montserrat" fontWeight="600">
          Zone Name
        </Text>
        <Text m={0} fontFamily="Montserrat" fontWeight="600">
          Messaging
        </Text>
        <Text m={0} fontFamily="Montserrat" fontWeight="600">
          Schedule
        </Text>
        <Text m={0} fontFamily="Montserrat" fontWeight="600">
          Messaging Settings
        </Text>
      </Grid>
      <Box pt={10}>
        <Grid gridTemplateColumns="15fr 11fr">
          <Box>{zoneRows}</Box>
          <Box>
            <Flex
              position="relative"
              flexWrap="wrap"
              flexDirection="row"
              height="350px"
              boxShadow="0px 1px 5px #bdb4c0"
              mr="35px"
            >
              <Grid overflowY="scroll" maxHeight="350px" pl="25px" py="20px">
                <Flex flexDirection="column">
                  <Flex flexDirection="row">
                    <Text fontFamily="Montserrat" fontWeight="600">
                      Play message(s) every{" "}
                    </Text>
                    <Input
                      type="text"
                      inputmode="numeric"
                      fontFamily="Montserrat"
                      maxWidth="35px"
                      height="21px"
                      mx="10px"
                      border="1px solid #eeeeee"
                      textAlign="center"
                      onChange={changeSongCount}
                      value={songCount || 0}
                    />
                    <Text fontFamily="Montserrat" fontWeight="600">
                      songs
                    </Text>
                  </Flex>
                  <Text fontSize="0.8em">
                    Please allow up to 8 songs to play before any changes take
                    effect
                  </Text>
                </Flex>
                <Grid pt="10px">
                  <Flex flexDirection="row">
                    <CheckBox
                      mt="3px !important"
                      mr="5px !important"
                      checked={global}
                      onChange={() => setGlobal(!global)}
                    />
                    <Text fontFamily="Montserrat" fontWeight="600">
                      Global Messaging
                    </Text>
                  </Flex>
                  <Text fontSize="0.8em" px="25px">
                    This will enable your Global Messages to play within this
                    zone.
                    <br />
                    You can find your Global Messages under "Messaging
                    Schedule".
                  </Text>
                </Grid>
                <Grid pt="10px">
                  <Flex flexDirection="row">
                    <CheckBox
                      mt="3px !important"
                      mr="5px !important"
                      checked={local}
                      onChange={() => setLocal(!local)}
                    />
                    <Text fontFamily="Montserrat" fontWeight="600">
                      Local Messaging
                    </Text>
                  </Flex>
                  <Text fontSize="0.8em" px="25px">
                    This will enable a specific message or messages to play
                    within this zone only.
                  </Text>
                  <Text fontSize="0.8em" px="25px">
                    Note: If Global & Local are both selected, then the selected
                    messages from both will play in this location.
                  </Text>
                  <Box pl="30px">{messages}</Box>
                </Grid>
              </Grid>
              <Flex
                flexDirection="row-reverse"
                position="absolute"
                ml="330px"
                mt="300px"
              >
                <UnstyledButton
                  color="white"
                  bg="#E21E26"
                  borderRadius="25px"
                  mr={20}
                  px={25}
                  onClick={(e) => setModalOpen(true)}
                >
                  Next
                </UnstyledButton>
              </Flex>
            </Flex>
          </Box>
        </Grid>
      </Box>
      <ZoneSelectionModal
        zone={selectedZone}
        displayed={modalOpen}
        handleClose={toggleModal}
        saveInfo={saveZoneMessaging}
      />
    </Box>
  );
};

export default ZonesMessaging;
