import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { StudioTypeContext, UserContext } from '../contexts'
import { BillingModal, CompanyForm, PasswordForm, ProfileForm, UserForm } from './index'
import { SublicenseModal, SublicenseItem } from '../Licenses'
import BasicSwitch from '../elements/BasicSwitch'


const Accounts = () => {
  const { studioType } = useContext(StudioTypeContext)
  const { plans, role } = useContext(UserContext)
  const [user, setUser] = useState({})
  const [userProfile, setUserProfile] = useState({})
  const [tenantPin, setTenantPin] = useState('')
  const [company, setCompany] = useState({})
  const [companyProfile, setCompanyProfile] = useState({})
  const [password, setPassword] = useState({})
  const [sublicenseFormDisplayed, setSublicenseFormDisplayed] = useState(false)
  const [cardModalDisplayed, setCardModalDisplayed] = useState(false)
  const [sublicenseSearch, setSublicenseSearch] = useState('')
  const [sublicenses, setSublicenses] = useState([])
  const [selectedSublicense, setSelectedSublicense] = useState()
  const [displayedSublicenses, setDisplayedSublicenses] = useState([])

  useEffect(() => {
    setClassName("main", "");
    $.get('/api/v2/accounts/current.json', (result) => {
      setUser(result.user)
      setUserProfile(result.user_profile)
      setCompany(result.company)
      setCompanyProfile(result.company_profile)
      setTenantPin(result.tenant_pin)
    })
  }, [])

  useEffect(() => {
    $.get(`/api/v2/sublicenses`, (result) => {
      setSublicenses(result)
      setDisplayedSublicenses(result)
    });
  }, [])

  const handleUserChange = (event) => {
    const newUser = {
      ...user,
      [event.target.name]: event.target.value
    }
    setUser(newUser)
  }

  const handlePasswordChange = (event) => {
    const newPassword = {
      ...password,
      [event.target.name]: event.target.value
    }
    setPassword(newPassword)
  }

  const handleSwitchChange = (event) => {
    const value = event.target.checked ? 'in_store' : 'creator'

    const newUser = {
      ...user,
      [event.target.name]: value
    }
    setUser(newUser)
  }

  const setSwitchValue = () => {
    return !(user.default_studio_type == 'creator')
  }

  const handleUserProfileChange = (event) => {
    const newUserProfile = {
      ...userProfile,
      [event.target.name]: event.target.value
    }
    setUserProfile(newUserProfile)
  }

  const handleCompanyChange = (event) => {
    const newCompany = {
      ...company,
      [event.target.name]: event.target.value
    }
    setCompany(newCompany)
  }

  const handleCompanyProfileChange = (event) => {
    const newCompanyProfile = {
      ...companyProfile,
      [event.target.name]: event.target.value
    }
    setCompanyProfile(newCompanyProfile)
  }

  const handleUserSubmit = (event) => {
    event.preventDefault()
    $.ajax({
        url: '/api/v2/accounts/user',
        type: 'PATCH',
        dataType: 'JSON',
        data: {user: user, user_profile: userProfile},
        success: (result) => {
          setUser(result.user)
          setUserProfile(result.user_profile)
          window.kk.notifier.notify("Personal information updated.", "success");
        },
        error: (error) => {
          window.kk.notifier.notify("Error updating personal information, please contact support.", "error");
        }
    })
  }

  const handleCompanySubmit = (event) => {
    event.preventDefault()
    $.ajax({
        url: '/api/v2/accounts/company',
        type: 'PATCH',
        dataType: 'JSON',
        data: {company: company, company_profile: companyProfile},
        success: (result) => {
          setCompany(result.company)
          setCompanyProfile(result.company_profile)
          window.kk.notifier.notify("Company information updated.", "success");
        },
        error: (error) => {
          window.kk.notifier.notify("Error updating personal information, please contact support.", "error");
        }
    })
  }

  const handlePasswordSubmit = (event) => {
    event.preventDefault()
    $.ajax({
        url: '/api/v2/accounts/password',
        type: 'PATCH',
        dataType: 'JSON',
        data: {'user': password},
        success: (result) => {
          setPassword({})
          window.kk.notifier.notify("Password information updated.", "success");
        },
        error: (error) => {
          window.kk.notifier.notify("Error updating password, please contact support.", "error");
        }
    })
  }

  const handleNewSublicenseClick = (event, sublicense, refresh) => {
    event.preventDefault()
    if (refresh) {
      return $.get(`/api/v2/sublicenses`, (result) => {
        setSublicenses(result)
        setDisplayedSublicenses(result)
        setSublicenseFormDisplayed(!sublicenseFormDisplayed)
        setSelectedSublicense(null)
        setSublicenseSearch('')
      });
    }
    setSublicenseFormDisplayed(!sublicenseFormDisplayed)
    setSelectedSublicense(sublicense)
  }

  const handleSublicenseDelete = (event, sublicense) => {
    event.preventDefault()
    $.ajax({
      url: `/api/v2/sublicenses/${sublicense.id}`,
      type: 'DELETE',
      success: (result) => {
        window.kk.notifier.notify("Deleted sublicense", "success");
        return $.get(`/api/v2/sublicenses`, (result) => {
          setsublicenses(result)
          setdisplayedSublicenses(result)
          setsublicenseFormDisplayed(false)
          setselectedSublicense(null)
          setsublicenseSearch('')
        });
      },
      error: (error) => {
        setIsLoading(false)
        window.kk.notifier.notify("You can not delete this client because they are attached to a license.", "error");
      }
    })
  }

  const handleSublicenseSearchChange = (event) => {
    event.preventDefault()
    const search = event.target.value
    const displayedSublicenses = sublicenses.filter((sublicense) => sublicense.name.toLowerCase().includes(search.toLowerCase()));
    setSublicenseSearch(search)
    setDisplayedSublicenses(displayedSublicenses)
  }

  const handleCardModalClick = (event) => {
    event.preventDefault()
    setCardModalDisplayed(!cardModalDisplayed)
  }

  var plan
  if (studioType && plans) {
    plan = studioType == 'creator' ? plans.creator : plans.instore
    plan = plan ? plan : plans.find((plan) => (plan && plan.active)) // fallback to first active plan
  }
  const planName = plan ? plan.name : "No Subscription"
  const planStatus = plan && (plan.status || plan.active) ? 'Active' : "No Subscription"

  var companyFormEnabled;
  const clientFormEnabled = (plan && ['commercial', 'enterprise'].includes(plan.type))
  const sublicensesList = displayedSublicenses.map((sublicense) => <SublicenseItem sublicense={sublicense} onEdit={handleNewSublicenseClick} onDelete={handleSublicenseDelete} onClick={handleNewSublicenseClick} key={sublicense.id} />);
  if (studioType === "creator") {
    companyFormEnabled = (role === "owner" && plan && plan.type != "personal")
  } else {
    if (plans && plans.creator) {
      companyFormEnabled = (role === "owner" && (plans.creator.type != "personal"))
    } else {
      companyFormEnabled = role === "owner"
    }
  }
  const billingInfo = role === "owner" ? <p className="text-link underline" onClick={handleCardModalClick}>Update Billing Information</p> : null
  return(
      <div className="accounts-form-container">
        <div className="authform accounts">
          <form onSubmit={handleUserSubmit}>
            <fieldset>
              <strong className="form-group-title">Personal Information</strong>
              <UserForm user={user} onUserChange={handleUserChange} />
              <strong className="form-group-title">Socials (one required)</strong>
              <ProfileForm profile={userProfile} onProfileChange={handleUserProfileChange}/>
              <strong className="form-group-title">Select Your Default Studio</strong>
              <div className="global-switch">
                <div className="switch-label">Creator</div>
                <BasicSwitch name='default_studio_type' isChecked={setSwitchValue} onSwitchChange={handleSwitchChange} />
                <div className="switch-label">In-store</div>
              </div>
              <input type="submit" value="Update" className='button btn btn-primary red' />
            </fieldset>
          </form>
        </div>
        <div className={companyFormEnabled ? "authform accounts" : "authform accounts not-active"}>
          <form onSubmit={handleCompanySubmit}>
            <fieldset disabled={!companyFormEnabled}>
              <strong className="form-group-title">Company Information</strong>
              <CompanyForm company={company} onCompanyChange={handleCompanyChange} />
              <strong className="form-group-title">Company Socials (one required)</strong>
              <ProfileForm profile={companyProfile} onProfileChange={handleCompanyProfileChange} />
              <input type="submit" value="Update" className='button company-btn btn btn-primary red' />
            </fieldset>
          </form>
        </div>
        <div className={clientFormEnabled ? "authform accounts long" : "authform accounts long not-active"}>
          <form>
            <fieldset disabled={!clientFormEnabled}>
              <div className="sublicensing-header">
                <strong className="form-group-title no-pad">Client Information</strong>
                {clientFormEnabled ? <div className="add-button" onClick={handleNewSublicenseClick}><i className="fas fa-plus"></i></div> : <div className="add-button"><i className="fas fa-plus"></i></div>}
              </div>
              <input
                name='sublicensingInformation'
                type='text_field'
                className='form-control sublicense-search'
                placeholder='Search'
                value={sublicenseSearch || ''}
                onChange={handleSublicenseSearchChange}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
              <div className="form-group no-pad">
                {clientFormEnabled ? sublicensesList : <span></span>}
              </div>
            </fieldset>
          </form>
        </div>
        <div className="authform accounts wide">
          <form className="horizontal" onSubmit={handlePasswordSubmit}>
            <div className="form-group-horizontal">
              <strong className="form-group-title">Plan Information</strong>
              <div className="form-group">
                <div className="inline capitalize"><p>Current {studioType} Plan: </p><p className="left-pad">{planName}</p></div>
                <div className="inline capitalize"><p>Account Status: </p><p className="left-pad">{planStatus}</p></div>
                {billingInfo}
                <p>4 Digit PIN Code: {tenantPin}</p>
                <p>If you need to update your PIN code <Link to='/contact' className="underline">Contact Us</Link></p>
              </div>
            </div>
            <div className="form-group-horizontal password-form">
              <strong className="form-group-title">Password Information</strong>
              <PasswordForm password={password} onPasswordChange={handlePasswordChange}/>
              <input type="submit" value="Update" className='button btn btn-primary red' />
            </div>
          </form>
        </div>
        <BillingModal modalDisplayed={cardModalDisplayed} showDisclaimer={true} handleClose={handleCardModalClick} />
        <SublicenseModal displayed={sublicenseFormDisplayed} handleClose={handleNewSublicenseClick} sublicense={selectedSublicense}/>
      </div>
  )
}

export default Accounts
