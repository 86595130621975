import React from 'react'
import styled from 'styled-components';
import {
  border, compose, color, layout, space, typography
} from 'styled-system'

const Input = styled.input`
  ${compose(border, color, layout, space, typography)}
`

Input.defaultProps = {
  border: 'none',
  borderBottom: '1px solid #eeeeee'
}

const Radio = (props) => {
  return (
    <Input type='radio' {...props}/>
  )
}

const RadioLabel = styled.label`
  ${compose(border, color, layout, space, typography)}
`

const TextArea = styled.textarea`
  ${compose(border, color, space)}
`

const BaseCheckBox = styled.input`
  ${compose(border, color, layout, space, typography)}
  accent-color: red;
`

const CheckBox = (props) => {
  return (
    <BaseCheckBox type='checkbox' {...props}/>
  )
}

export {CheckBox, Input, Radio, RadioLabel, TextArea}
