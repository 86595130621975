import React, { useEffect, useRef } from 'react'
import styled from 'styled-components';
import {
  border, compose, color, layout, space
} from 'styled-system'
import {Box} from './Containers'

const Range = styled.input`
  -webkit-appearance: none;
  transform: rotate(270deg) translate(-45%) translateY(-33px);
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #bdb4c0;
    background: white;
    margin-left: 4px;
  }
  ${compose(border, color, layout, space)}
`

const Slider = (props) => {
  const {value, setValue, displayed, toggleDisplayed} = props
  const node = useRef();

  useEffect(() => {
    if (displayed) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displayed])

  const handleClickOutside = (e) => {
    if (node && node.current && node.current.contains(e.target)) {
      return
    }
    toggleDisplayed(!displayed)
  };

  return (
    <Box
      ref={node}
      className='range-slider'
      display={displayed ? '' : 'none'}
      width='45px'
      height='125px'
      position='absolute'
      bottom={0}
      bg='#25303c'
      {...props}
    >
      <Range
        type='range'
        minWidth='110px'
        height='8px'
        mt='10px'
        mx='auto'
        borderRadius='10px'
        backgroundColor='white'
        min={0}
        max={1}
        step={0.01}
        value={value}
        onChange={event => {
          setValue(event.target.valueAsNumber)
        }}
      />
    </Box>
  )
}

export default Slider