import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Grid, GridItem, Input, Radio, Text } from '../elements'
import CardForm from './CardForm'
import CardRow from './CardRow'

const BillingModal = (props) => {
  const [displayed, setDisplayed] = useState(props.modalDisplayed)
  const [isLoading, setIsLoading] = useState(false)
  const [cards, setCards] = useState([])
  const [selectedCard, setSelectedCard] = useState()

  useEffect(() => {
    if (props.modalDisplayed) {
      setDisplayed(props.modalDisplayed)
    }
  }, [props.modalDisplayed])

  useEffect(() => {
    fetchCards()
  }, [])

  const closeModal = (event) => {
    if (event.target.classList.contains("billing-modal")) {
      setDisplayed(!displayed)
      props.handleClose(event)
    }
  }

  const fetchCards = () => {
    fetch('/api/v2/billing')
      .then(response => response.json())
      .then((data) => {
        setCards(data.cards)
      })
  }

  const updateSelected = (card) => {
    setSelectedCard(card)
  }

  const updateBilling = (event) => {
    $.ajax({
      url: '/api/v2/billing/default',
      type: 'POST',
      dataType: 'JSON',
      data: {
        source: selectedCard.id,
      },
      success: function (data) {
        window.kk.notifier.notify("Default billing method updated.", "success");
      },
      error: function (error) {
        window.kk.notifier.notify("Error updating default billing method, please contact support.", "error");
      }
    });
    setDisplayed(!displayed)
    props.handleSubmit(selectedCard)
    props.handleClose(event)
  }

  return (
    <Box className={displayed ? "modal billing-modal" : "hidden"} onClick={closeModal} {...props}>
      <div className="modal-dialog" >
        <div className="modal-content" >
          <Flex flexDirection='column' bg='white' px='75px' py='30px'>
            <Text fontSize='20px' fontWeight='500' mb='25px'>Update Payment Information</Text>
            <Text fontWeight='600'>Your credit and debit cards</Text>
            <Flex flexDirection='column' pb='10px' borderBottom='1px solid #eeeeee'>
              <Grid gridTemplateColumns='1fr 1fr 1fr' gridColumnGap='10px'>
                <Text fontSize='11px'>Card</Text>
                <Text fontSize='11px'>Name on card</Text>
                <Text fontSize='11px'>Expires</Text>
              </Grid>
              <Box overflowY='scroll' maxHeight='100px' mb='10px'>
                {cards.map((card) => <CardRow key={card.id} card={card} onSelect={updateSelected} />) }
              </Box>
              <Box>
                { props.showDisclaimer ? 
                  <Text>I authorize KrossKast, LLC to charge my credit card above for my active KrossKast subscription(s).
                  I understand that my billing information will be saved on file for future recurring transactions on my account.
                  Any and all payments are non-refundable.</Text>
                  : <Text></Text>
                }
              </Box>
              <Grid gridTemplateColumns='1fr 1fr 1fr'>
                <GridItem gridColumn='2/4' pl='53%'>
                  {isLoading ? <div className="loading"></div> : <Button onClick={updateBilling}>Update Billing</Button>}
                </GridItem>
              </Grid>
            </Flex>
            <CardForm onSubmit={fetchCards} />
          </Flex>
        </div>
      </div>
    </Box>
  )
}

export default BillingModal