import React, { createContext, useState } from "react";

export const MediaContext = createContext({
  media: { id: null, playing: false },
});

const MediaContextProvider = ({ children }) => {
  const [media, setMedia] = useState({ id: null, playing: false });

  const context = {
    media,
    setMedia,
  };

  return (
    <MediaContext.Provider value={context}>{children}</MediaContext.Provider>
  );
};

export default MediaContextProvider;
