import { nil } from 'ajv';
import React from 'react';

class MessagingSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: "",
            interval_unit: ""
        }
    }

    componentDidMount() {
        setClassName("main", "account");
        var that = this;
        this.serverRequest = $.get('/api/messaging_schedule.json', function (result) {

            that.setState({
                interval: result.interval || 1,
                interval_unit: result.interval_unit || ""
            })
        }).fail((err) => {
            window.location.replace('/users/sign_in');
        });
    }

    componentWillUnmount() {
        setClassName("main", "");
        this.serverRequest.abort();
    }

    updateMessagingSchedule(e) {
        e.preventDefault();
        var formData = {
            interval: this.state.interval,
            interval_unit: this.state.interval_unit
        }

        $.ajax({
            url: '/api/messaging_schedule',
            type: 'PUT',
            dataType: 'JSON',
            data: formData,
            success: function(data) {
                window.kk.notifier.notify("Messaging schedule saved successfully.", "success");
            },
            error: function(error) {
                window.kk.notifier.notify("Error saving messaging schedule, please contact support.", "error");
            }
        })
    }

    handleIntervalChange(event) {
        console.log('update interval', event.target.value);
        this.setState({
            interval: event.target.value,
        })
    }

    handleIntervalUnitChange(event) {
        console.log('update interval unit', event.target.value);
        this.setState({
            interval_unit: event.target.value
        })
    }

    render() {
        return (
            <div>
                <h1>Messaging Schedule</h1>
                <div className="bottom-padded col-sm-12">
                    <div className="messaging-schedule account-item">
                        <div className="row">
                            <form className="col-sm-12 messaging-form" onSubmit={this.updateMessagingSchedule}>
                                <div className="form-group">
                                    <div className="col-sm-1">
                                        <label className="control-label">Play Every: </label>
                                    </div>
                                    <div className="col-sm-2">
                                        <input
                                            name="interval"
                                            type="text"
                                            className="form-control"
                                            placeholder="interval"
                                            value={this.state.interval || 1}
                                            onChange={this.handleIntervalChange} 
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <select
                                            name="interval_unit"
                                            className="form-control"
                                            value={this.state.interval_unit}
                                            onChange={this.handleIntervalUnitChange}
                                        >
                                            {/*<option value="minute">Minute(s)</option>*/}
                                            <option value="song">Song(s)</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-2">
                                        <input type="submit" className="btn btn-xs btn-primary" value="Save"/>
                                    </div>
                                    <div className="cb"></div>
                                </div>
                            </form>
                        </div>
                        <hr/>
                    </div>
                </div>
            </div>
        )
    }
};

export default MessagingSchedule