import React from 'react';
import AdminsTable from './AdminsTable'

class Admins extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
        };
    }
    componentDidMount() {
        setClassName("main", "location");
        this.serverRequest = $.get('/api/locations.json', (result) => {
            // console.log(result);
            result.status === 301 ? window.location.replace(`${result.redirect}`) : this.setState({locations: result});
        }).fail((err) => {
            window.location.replace('/users/sign_in');
        });
    }

    componentWillUnmount() {
        this.serverRequest.abort();
        setClassName("main", "");
    }

    goto(event) {
        event.preventDefault();
        this.props.history.push('/support')
    }

    buildLocation(locations) {
        return locations.map((location, idx) => {
            location.userId = location.user ? location.user.id : '00001';
            location.username = location.user ? location.user.username : 'fake_user';
            location.companyName = location.tenant ? location.tenant.name : 'Unknown';
            location.currentChannel = '';
            return location
        })
    }

    render() {
        const combinedData = this.buildLocation(this.state.locations);
        return(
            <div className="locations">
                <div className="locations-table__wrapper">
                    <AdminsTable data={combinedData} />
                    <div className='links'>
                        <a href="/support" onClick={this.goto.bind(this)}>
                            <span className="glyphicon glyphicon-plus-sign"></span><span>Add User</span>
                        </a>
                    </div>
                      <div className="cb"></div>
                </div>
            </div>
        )
    }
}

export default Admins