import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Box } from './elements'
import BillingsTable from './BillingsTable'

class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = { billing: [] };
    }

    componentDidMount() {
        setClassName("main", "billings");
        this.serverRequest = $.get('/api/billing.json', (result) => {
            this.setState({
                billing: result
            });
        }).fail((err) => {
            alert(err.statusText)
        });
    }

    componentWillUnmount() {
      setClassName("main", '')
    }

    goto(event) {
        event.preventDefault();
        this.props.history.push('/support')
    }

    render() {
      const billingData = this.state.billing;
      const links = (
        <div className='links'>
            <a href="/support" onClick={this.goto.bind(this)}>
                <span><FontAwesomeIcon icon={solid('plus-circle')} /> Add Location</span>
            </a>
            &nbsp;
            <span>|</span>
            <a href="/terms-and-conditions">
                <span>&nbsp;Contract Terms</span>
            </a>
        </div>
      )
      return(
        <div>
            <Box overflow='visible' className="billings table__wrapper">
                <BillingsTable data={billingData} studioType={this.props.studioType}/>
                {this.props.studioType == 'creator' ? <span></span> : links}
            </Box>
        </div>
      )
    }
}

export default Billing
