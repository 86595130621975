import React from 'react';
import Clock from '../../images/clock.png'

class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: "",
            interval_unit: ""
        }
    }

    componentDidMount() {
        this.serverRequest = $.get('/api/messaging_schedule.json', (result) =>{
            this.setState({
                interval: result.interval || "",
                interval_unit: result.interval_unit || ""
            })
        }).fail((err) => {
            window.location.replace('/users/sign_in');
        });
    }

    componentWillUnmount() {
        this.serverRequest.abort();
    }

    updateMessagingSchedule(e) {
        e.preventDefault();
        var formData = {
            interval: this.state.interval,
            interval_unit: this.state.interval_unit
        }
        $.ajax({
            url: '/api/messaging_schedule',
            type: 'PUT',
            dataType: 'JSON',
            data: formData,
            success: function(data) {
                window.kk.notifier.notify("Messaging schedule saved successfully.", "success");
            },
            error: function(error) {
                window.kk.notifier.notify("Error saving messaging schedule, please contact support.", "error");
            }
        })
    }

    handleIntervalChange(event) {
        console.log('update interval', event.target.value);
        this.setState({
            interval: event.target.value
        })
    }

    handleIntervalUnitChange(event) {
        console.log('update interval unit', event.target.value);
        this.setState({
            interval_unit: event.target.value
        })
    }

    render() {
        return (
            <div className="col-sm-12">
                    <div className="schedule">
                        <div className="row">
                            <div className="instructions"><span className='icon'><img className="icon-img" src={Clock} /> &nbsp;</span><b>SELECT:</b>&nbsp;Messaging Interval</div>
                            <form className="col-sm-12 request-items" onSubmit={this.updateMessagingSchedule.bind(this)}>
                                <div className="form-row">
                                    <div className="col-sm-1 schedule-label">
                                        <label className="control-label">Play Every: </label>
                                    </div>
                                    <div className="col-xs-2 input-control-container">
                                        <input
                                            name="interval"
                                            type="text"
                                            maxLength="10"
                                            size="10"
                                            className="messaging-form-ctrl"
                                            placeholder="interval"
                                            value={this.state.interval || ""}
                                            onChange={this.handleIntervalChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-xs-2 input-control-container">
                                        <select
                                            name="interval_unit"
                                            className="form-control input-lg messaging-form-ctrl no-radius"
                                            value={this.state.interval_unit || "song"}
                                            onChange={this.handleIntervalUnitChange.bind(this)}
                                        >
                                            <option value="song">Song(s)</option>
                                        </select>
                                    </div>
                                    <div className="col-xs-2 input-control-container">
                                        <input type="submit" className="messaging-form-ctrl ctrl-btn" value="Save"/>
                                    </div>
                                    <div className="cb"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Schedule