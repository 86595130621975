import React from 'react';

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: this.props.checked,
        };
    }

    toggleCheckboxChange() {
        const { handleCheckboxChange, label } = this.props;

        this.setState(({ isChecked }) => (
            {
                isChecked: !isChecked,
            }
        ));

        handleCheckboxChange(label);
    }

    render() {
        const { id, name, label } = this.props;
        const { isChecked } = this.state;

        return (
            <div className="location messages checkbox">
                <div>
                    <input
                        id={id}
                        className="message checkbox"
                        type="checkbox"
                        name={name}
                        value={id}
                        defaultChecked={isChecked}
                        onChange={this.toggleCheckboxChange.bind(this)}
                    />
                    <label htmlFor={id}>{label}</label>
                </div>
            </div>
        );
    }
}

export default Checkbox