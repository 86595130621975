import React from 'react';

const AdminPasswordForm = (props) => {
    return (
        <form onSubmit={props.handleSubmit}>
            <input type="text" value={props.value} onChange={props.handleChange} maxLength={"4"} size={"4"} placeholder={"PIN"} autoFocus />
            <input type="submit" value="reset" />
        </form>
    );
};

class AdminsTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetPassword: false,
            password: 'xxxxxxxxx',
            pin: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ pin: event.target.value });
    }

    handleSubmit(event) {
        $.ajax({
            url: `/passwords/${this.state.pin}/${this.props.location.userId}`,
            type: 'get',
            success: ({ password }) => {
                this.setState({ password });
                this.resetPassword();
            },
            error: function(error) {
                alert('Please check your PIN')
            }
        });
        event.preventDefault();
    }

    resetPassword() {
        this.setState({
            resetPassword: !this.state.resetPassword
        });
    }

    trimStr(str) {
      if (str) {
        return str.length >= 12 ? `${str.substr(0, 8)}...` : str
      } else {
        return str
      }
    }

    render() {
        const { location } = this.props;
        const { resetPassword } = this.state;
        const pinForm = <AdminPasswordForm value={this.state.value} handleSubmit={this.handleSubmit} handleChange={this.handleChange} />
        return (
            <tr className='location'>
                <td>{location.code}</td>
                <td>
                    <span data-tooltip={location.username}>{this.trimStr(location.username)}</span>
                </td>
                <td className='location password'>
                    <div className='location password-container'>
                        <span className='location password-text'>{resetPassword ? (pinForm) : this.state.password}</span>
                        <span className={resetPassword ? 'location password-action cancel' : 'location password-action' }>
                            <a className="reset-password" href="#" onClick={() => this.resetPassword()}>{resetPassword ? 'cancel' : 'reset'}</a>
                        </span>
                    </div>
                </td>
                <td>{location.user["exclusive_only?"] ? 'KK Exclusive' : 'Full'}</td>
                <td>{location.companyName}</td>
                <td>{location.address}</td>
            </tr>
        )
    }
}

export default AdminsTableRow