import React from 'react';
import { debounce, detectScrollBottom } from './helpers'
import { FilterNav } from './FilterNav'
import { Song } from './Songs'
import { Channel } from './Channels'

class Browse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          channels: [],
          songs: [],
          activeCategory: 'Genres',
          activeTags: [],
          activeSearch: '',
          activeChannel: null,
          page: 1,
          isLoading: false,
          listComplete: false
        }

        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleChannelClick = this.handleChannelClick.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleClearAll = this.handleClearAll.bind(this);
        this.handleSliderClick = this.handleSliderClick.bind(this);
    }

    componentDidMount() {
      this.setChannels(this.state.activeCategory)
      this.setSongs()
      $('.app_content').scroll(debounce(() => {
        if (detectScrollBottom($('.app_content')) && !this.state.listComplete) {
          this.fetchMoreSongs()
        }
      }, 100));
    }

    componentWillUnmount() {
        this.serverRequest.abort();
    }

    setChannels(category) {
      this.serverRequest = $.get(`/api/v2/channels.json?creator=true&category=${category}`, (result) => {
          this.setState({
              channels: result.channels
          });
      });
    }

    setUrl(url, tags, search, channel_id) {
      if (tags.length) {
        url += `&tags=${tags}`
      }
      if (search) {
        url += `&search=${search}`
      }
      if (channel_id) {
        url += `&channel_id=${channel_id}`
      }
      return url
    }

    setSongs(tags=[], search, channel_id) {
      this.setState({
        isLoading: true,
        songs: []
      })
      var url = this.setUrl('/api/v2/songs.json?page=1', tags, search, channel_id)
      this.serverRequest = $.get(url, (result) => {
          this.setState({
              songs: result,
              isLoading: false
          });
      });
    }

    fetchMoreSongs() {
      if (this.state.isLoading) {
        this.serverRequest.abort()
      }
      this.setState({
        isLoading: true
      })
      $('.app_content').scrollTop(9999)
      var url = this.setUrl(
        `/api/v2/songs.json?page=${this.state.page + 1}`,
        this.state.activeTags,
        this.state.activeSearch,
        this.state.activeChannel
      )
      var currentSongs = this.state.songs
      this.serverRequest = $.get(url, (result) => {
          this.setState({
              songs: [...currentSongs, ...result],
              page: this.state.page + 1,
              isLoading: false,
              listComplete: !result.length
          });
      });
    }

    handleClearAll() {
      this.setSongs()
      this.setState({
        activeTags: [],
        activeSearch: '',
        activeChannel: null,
      })
    }

    handleSearchSubmit(search) {
      this.setSongs(this.state.activeTags, search, this.state.activeChannel)
      this.setState({
        activeSearch: search
      })
    }

    handleCategoryChange(event) {
      event.preventDefault()
      var category = event.target.name
      this.setState({
        activeCategory: category
      })
      this.setChannels(category)
    }

    handleChannelClick(event, channel) {
      event.preventDefault()
      var channelId = this.state.activeChannel != channel.id ? channel.id : null
      this.setState({
        activeChannel: channelId
      })
      this.setSongs(this.state.activeTags, this.state.activeSearch, channelId)
    }

    handleSliderClick(event) {
      var slideChannel;
      var newChannels;
      var currentChannels = this.state.channels
      if (event.target.matches(".left *")) {
        slideChannel = currentChannels.pop()
        newChannels = [slideChannel, ...currentChannels]
      }
      if (event.target.matches(".right *")) {
        slideChannel = currentChannels.shift()
        newChannels = [...currentChannels, slideChannel]
      }
      this.setState({
        activeChannel: null,
        channels: newChannels
      })
    }

    handleTagsChange(event) {
      event.preventDefault()
      var tag = event.target.name
      var currentTags = this.state.activeTags
      if (event.target.checked) {
        var newTags;
        currentTags.includes(tag) ? newTags = [...currentTags] : newTags = [...currentTags, tag]
      } else {
        var newTags = currentTags.filter(function(value, index, arr){ return value != tag;});
      }
      this.setState({
        activeTags: newTags
      })
      this.setSongs(newTags, this.state.activeSearch, this.state.activeChannel)
    }

    render() {
        const channels = this.state.channels.map((channel) => <Channel channel={channel} key={channel.id} onChannelClick={this.handleChannelClick} isActive={this.state.activeChannel === channel.id} />);
        const songs = this.state.songs.map((song) => <Song song={song} key={song.id} />);
        const leftSlide = (
          <div className="slider-control left" onClick={this.handleSliderClick}>
            <div className="slider-button slider-button-left">
              <i className="fas fa-chevron-left"></i>
            </div>
          </div>
        )
        const rightSlide = (
          <div className="slider-control right" onClick={this.handleSliderClick}>
            <div className="slider-button slider-button-right">
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
        )
        return(
          <div className="browse-content">
            <div className="filter-nav-content">
              <div className="content-header">
                <span className="content-header-text">Most Popular</span>
              </div>
              <div className="browse-channels channels">
                {this.state.channels.length ? leftSlide : <span></span>}
                {channels}
                {this.state.channels.length ? rightSlide : <span></span>}
              </div>
            </div>
            <FilterNav
              activeCategory={this.state.activeCategory}
              activeTags={this.state.activeTags}
              onSearchSubmit={this.handleSearchSubmit}
              onCategoryChange={this.handleCategoryChange}
              onActiveTagsChange={this.handleTagsChange}
              onClearAll={this.handleClearAll} />
            <div className="songs-container">
                {songs}
                {this.state.isLoading ? <div className="loading"></div> : null}
            </div>
          </div>
        )
    }
}

export default Browse
