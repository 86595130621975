import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Flex } from '../elements'
import RequestItem from './RequestItem'
import Checked from '../../images/checked.png'
import Microphone from '../../images/microphone.png'
import Support from '../../images/support.png'

const Requests = (props) => {
    const [talentRequests, setTalentRequests] = useState([])

    useEffect(() => {
        setClassName("main", "talent-requests");
        setRequests()
    }, []);

    const setRequests = () => {
        return $.get('/api/talent_requests.json', (result) => {
            setTalentRequests(result)
        }).fail((err) => {
            window.location.replace('/users/sign_in');
        });
    }

    const requestsWithStatus = (statusTypes) => {
        return talentRequests.reduce((requests, talentRequest) => {
            if (statusTypes.includes(talentRequest.status)) {
                requests.push(
                    <RequestItem
                        talentRequest={talentRequest}
                        key={talentRequest.id}
                        requestType={talentRequest.status}
                        refreshRequests={setRequests}
                    />
                )
            }
            return requests
        }, [])
        
    }

    const inProgress = requestsWithStatus(['created', 'declined'])
    const pending = requestsWithStatus(['pending_approval'])
    const approved = requestsWithStatus(['approved'])

    return(
        <div className="talent_requests bottom-padded">
            <h4 className="talent_requests header">In Production</h4>
            <div className="col-sm-12 bottom-padded">
                <div className="col-sm-12 row request new">
                    <div className="instructions"><span className='icon'><img className="icon-img" src={Microphone} /> &nbsp;</span><b>STEP 1:</b>&nbsp;Talent Requests in Production</div>
                    <div className="col-sm-9 request-item">
                        { 
                            inProgress.length ? inProgress
                            :
                            <div className="col-sm-12 talent item-container no-requests">
                                <p>No Talent Requests are in production right now.</p>
                                <p>To make a request for custom messaging or advertising, please visit the <Link to="/talent_requests">Talent Requests</Link> page.</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-sm-12 row request awaiting-approval">
                    <div className="instructions"><span className='icon'><img className="icon-img" src={Checked} /> &nbsp;</span><b>STEP 2:</b>&nbsp;Awaiting Approval</div>
                    <div className="col-sm-9 request-item">
                    {
                        pending.length ? pending
                        : 
                        <div className="col-sm-12 talent item-container no-requests">
                            <p>No Talent Requests to approve right now.</p>
                        </div>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Requests