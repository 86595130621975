import React, { useContext, useEffect, useState } from 'react';
import { CreditsContext, UserContext } from '../contexts'
import twelveCredits from '../../images/12-credits'
import TalentItem from './TalentItem'
import { CreditMenu, CreditPackModal } from '../Credits'
import { Box, ClickableText, Flex, Grid, GridItem, Heading, Image, Text } from '../elements'

const Talent = () => {
  const { role } = useContext(UserContext)
  const [talents, setTalents] = useState([])
  const [hideCredits, setHideCredits] = useState(false)
  const [modalDisplayed, setModalDisplayed] = useState(false)
  const [selectedPack, setSelectedPack] = useState({})
  const [availableCredits, setAvailableCredits] = useState(0)

  useEffect(() => {
    fetch('/api/v2/current_credits')
      .then(response => response.json())
      .then((data) => {
        setAvailableCredits(data.credits)
      })
  })

  useEffect(() => {
    setClassName("main", "talent-requests");

    $.get('/api/talents.json', function (result) {
      setTalents(result)
    }).fail((err) => {
      window.location.replace('/users/sign_in');
    });
  }, []);

  const toggleModal = (pack) => {
    setSelectedPack(pack)
    setModalDisplayed(!modalDisplayed)
  }

  return (
    <CreditsContext.Provider value={{ availableCredits, setAvailableCredits }} >
      <Flex flexDirection='column' height='100%'>
        <Box py={10} bg='#f8f8f8' mt='0px' pl='10px'>
          <Heading
            mb='0px'
            fontFamily='Montserrat, Roboto, sans-serif'
            fontSize='14px'
            fontWeight='bold'
            lineHeight='25px'
          >
            Talent Requests
          </Heading>
        </Box>
        <Grid gridTemplateColumns='2fr 12fr 2fr' height={hideCredits ? '70px' : '296px'}>
          { role == 'owner' ?
            <>
              <GridItem gridColumn={2} py='20px'>
                <CreditMenu selectPack={toggleModal} hideCredits={hideCredits}/>
              </GridItem>
              <GridItem gridColumn={3} py='20px'>
                <Flex flexDirection='row' gap='10px'>
                  <Image src={twelveCredits} maxHeight='35px' ml='0px' mr='7px'/>
                  <Flex flexDirection='column'>
                    <Text mb='0px'>Total Credits: {availableCredits}</Text>
                    <ClickableText onClick={() => setHideCredits(!hideCredits)}>{hideCredits ? "Show" : "Hide"} Credit Packs</ClickableText>
                  </Flex>
                </Flex>
              </GridItem>
            </>
            : <></>
          }
        </Grid>

        <Flex flexDirection="row" flexWrap='wrap' p='15px'>
          {
            talents.map((talent) => (
              <TalentItem talent={talent} key={talent.slug} toggleCreditsModal={toggleModal} />
            ))
          }
        </Flex>
        <CreditPackModal selected={selectedPack} displayed={modalDisplayed} handleClose={toggleModal} />
      </Flex>
    </CreditsContext.Provider>
  )
};

export default Talent;
