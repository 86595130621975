import React from 'react';
import { debounce, detectScrollBottom } from '../helpers'
import { FilterNav } from '../FilterNav'
import { Song } from '../Songs'

class Licenses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          songs: [],
          activeCategory: 'Genres',
          activeTags: [],
          activeSearch: '',
          activeProjectCategory: null,
          page: 1,
          isLoading: false,
          listComplete: false
        }

        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleClearAll = this.handleClearAll.bind(this);
        this.handleExtraFilterChange = this.handleExtraFilterChange.bind(this);
    }

    componentDidMount() {
      this.setSongs()
      $('.app_content').scroll(debounce(() => {
        if (detectScrollBottom($('.app_content')) && !this.state.listComplete) {
          this.fetchMoreSongs()
        }
      }, 100));
    }

    setSongs(tags=[], search, projectCategory) {
      this.setState({
        isLoading: true,
        songs: []
      })
      var url = '/api/v2/songs.json?page=1&licensed=true'
      if (tags.length) {
        url += `&tags=${tags}`
      }
      if (search) {
        url += `&search=${search}`
      }
      if (projectCategory) {
        url += `&project_category=${projectCategory}`
      }
      this.serverRequest = $.get(url, (result) => {
          this.setState({
              songs: result,
              isLoading: false
          });
      });
    }

    fetchMoreSongs() {
      if (this.state.isLoading) {
        this.serverRequest.abort()
      }
      this.setState({
        isLoading: true
      })
      var nextPage = this.state.page + 1
      var url = `/api/v2/songs.json?page=${nextPage}&licensed=true`
      var tags = this.state.activeTags
      var search = this.state.activeSearch
      var projectCategory = this.state.activeProjectCategory
      var currentSongs = this.state.songs
      if (tags.length) {
        url += `&tags=${tags}`
      }
      if (search) {
        url += `&search=${search}`
      }
      if (projectCategory) {
        url += `&project_category=${projectCategory}`
      }
      this.serverRequest = $.get(url, (result) => {
          this.setState({
              songs: [...currentSongs, ...result],
              page: nextPage,
              isLoading: false,
              listComplete: !result.length
          });
      });
    }

    handleClearAll() {
      this.setSongs()
      this.setState({
        activeTags: [],
        activeSearch: ''
      })
    }

    handleSearchSubmit(search) {
      this.setSongs(this.state.activeTags, search, this.state.activeProjectCategory)
      this.setState({
        activeSearch: search
      })
    }

    handleCategoryChange(event) {
      event.preventDefault()
      var category = event.target.name
      this.setState({
        activeCategory: category
      })
    }

    handleTagsChange(event) {
      event.preventDefault()
      var tag = event.target.name
      var currentTags = this.state.activeTags
      if (event.target.checked) {
        var newTags;
        currentTags.includes(tag) ? newTags = [...currentTags] : newTags = [...currentTags, tag]
      } else {
        var newTags = currentTags.filter(function(value, index, arr){ return value != tag;});
      }
      this.setState({
        activeTags: newTags
      })
      this.setSongs(newTags, this.state.activeSearch, this.state.activeProjectCategory)
    }

    handleExtraFilterChange(projectCategory) {
      this.setState({
        activeProjectCategory: projectCategory
      })
      this.setSongs(this.state.activeTags, this.state.activeSearch, projectCategory)
    }

    render() {
        const songs = this.state.songs.map((song) => <Song song={song} key={song.id} licenseDropdown={true}/>);
        return(
          <div className="browse-content">
            <FilterNav
              activeCategory={this.state.activeCategory}
              activeTags={this.state.activeTags}
              onSearchSubmit={this.handleSearchSubmit}
              onCategoryChange={this.handleCategoryChange}
              onActiveTagsChange={this.handleTagsChange}
              onExtraFilterChange={this.handleExtraFilterChange}
              extraFilter={'Project Categories'}
              onClearAll={this.handleClearAll} />
            <div className="songs-container">
                {songs}
                {this.state.isLoading ? <div className="loading"></div> : null}
                {this.state.isLoading ? <div className="loading-buffer"></div> : null}
            </div>
          </div>
        )
    }
}

export default Licenses