import React from 'react';

class SublicenseItem extends React.Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleClick(event) {
    this.props.onClick(event, this.props.sublicense)
  }

  handleEdit(event) {
    this.props.onEdit(event, this.props.sublicense)
  }

  handleDelete(event) {
    this.props.onDelete(event, this.props.sublicense)
  }

  render() {
    var deleteIcon = this.props.onDelete ? <i className="fas fa-trash left-pad" onClick={this.handleDelete}></i> : <span></span>
    return (
      <div className="sublicense-item" data-sublicense-item-id={this.props.sublicense.id}>
        <div className="name" onClick={this.handleClick}>{this.props.sublicense.name}</div>
        <div className="buttons">
          <i className="fas fa-pen" onClick={this.handleEdit}></i>
          {deleteIcon}
        </div>
      </div>
    )
  }
}

export default SublicenseItem