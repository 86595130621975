import React from 'react';
import AdminsTableRow from './AdminsTableRow'

class AdminsTable extends React.Component {

    render() {
        const { data } = this.props;
        const rows = data.map((location,idx) => {
            return (
                <AdminsTableRow
                    key={`${idx}-${location.id}`}
                    location={location}
                />
            )
        });
        return (
                <table className="locations-table" style={{tableLayout: 'auto', width: '100%'}}>
                    <thead>
                    <tr>
                        <th>User</th>
                        <th>Username</th>
                        <th>Password</th>
                        <th>Subscription</th>
                        <th>Company Name</th>
                        <th title='company address'>Company Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
        )
    }
}

export default AdminsTable