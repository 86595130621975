import React from 'react';

class Switch extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = { isChecked: this.props.isChecked}
    }

    handleChange () {
        this.props.onChange();
        this.setState({isChecked: !this.state.isChecked});
    }

    render () {
        return(
            <div className="onoffswitch">
                <input type="checkbox" name={this.props.name} className="onoffswitch-checkbox" id={this.props.id} onClick={this.handleChange.bind(this)} defaultChecked={this.state.isChecked}/>
                    <label className="onoffswitch-label" htmlFor={this.props.id}>
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                    </label>
            </div>
        );
    }
}

export default Switch