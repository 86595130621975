import React, { useContext } from 'react';
import { CreditsContext } from '../contexts'
import {Divider, Flex, Grid, Text} from '../elements'

const CreditCalculator = (props) => {
  const { availableCredits } = useContext(CreditsContext)

  return (
    <Grid {...props}>
      <Flex flexDirection="row" justifyContent="space-between">
        <Text>Available Credits:</Text>
        <Text>{availableCredits} credits</Text>
      </Flex>
      <Flex flexDirection="row" justifyContent="space-between">
        <Text>Talent Request Cost:</Text>
        <Text>1 credits</Text>
      </Flex>
      <Divider />
      <Flex flexDirection="row" justifyContent="space-between">
        <Text color='#f44336'>Remaining Credits:</Text>
        <Text color='#f44336'>{Math.max(availableCredits - 1, 0)} credits</Text>
      </Flex>
    </Grid>
  )
}

export default CreditCalculator