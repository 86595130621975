import React from "react";
import defaultImage from "../../images/channels-artwork-default.png";

const Channel = ({ channel, children, hideDescription, hideName, playing }) => {

  const imageSource = channel.album_art.thumb.url
    ? channel.album_art.thumb.url
    : defaultImage;

  return (
    <div className={playing ? "channel active-channel" : "channel"}>
      <div className="channel-content" data-channel-id={channel.id}>
        <div className="channel-image-holder">
          <img
            className="channel-artwork"
            src={imageSource}
            height="200"
            widht="200"
            onError={(e) => {
              e.currentTarget.src = defaultImage
            }}
          />

          {hideDescription ? (
            <></>
          ) : (
            <div className="description">{channel.description}</div>
          )}
          {children}
        </div>
        {!hideName && (
          <div className="channel-info-box">
            <div className="channel-info-status"></div>
            <div className="channel-info">
              <div className="title">{channel.name}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Channel;
