import React from 'react'
import {Box} from './Containers'

const Spinner = (props) => {
  return (
    <Box {...props} >
      <div className="loading"></div>
    </Box>
  )
}

export default Spinner
