import React from 'react';

class ProfileForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      facebook: null,
      instagram: null,
      podcast: null,
      twitch: null,
      twitter: null,
      youtube: null,
      website: null
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps({profile}) {
    if (profile) {
      this.setState({
        facebook: profile.facebook,
        instagram: profile.instagram,
        podcast: profile.podcast,
        twitch: profile.twitch,
        twitter: profile.twitter,
        youtube: profile.youtube,
        website: profile.website
      })
    }
  }

  handleChange(event) {
    this.props.onProfileChange(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      name: value
    });
  }

  render() {
    return (
      <div className="form-group">
        <div className="form-field-container">
          <input
            name='facebook'
            type='text_field'
            className='form-control social'
            placeholder='Facebook'
            value={this.state.facebook || ''}
            onChange={this.handleChange} />
          <i className="fab fa-facebook-f profile-icon"></i>
        </div>
        <div className="form-field-container">
          <input
            name='instagram'
            type='text_field'
            className='form-control social'
            placeholder='Instagram'
            value={this.state.instagram || ''}
            onChange={this.handleChange} />
          <i className="fab fa-instagram-square profile-icon"></i>
        </div>
        <div className="form-field-container">
          <input
            name='podcast'
            type='text_field'
            className='form-control social'
            placeholder='Podcast'
            value={this.state.podcast || ''}
            onChange={this.handleChange} />
          <i className="fas fa-rss profile-icon"></i>
        </div>
        <div className="form-field-container">
          <input
            name='twitch'
            type='text_field'
            className='form-control social'
            placeholder='Twitch'
            value={this.state.twitch || ''}
            onChange={this.handleChange} />
          <i className="fab fa-twitch profile-icon"></i>
        </div>
        <div className="form-field-container">
          <input
            name='twitter'
            type='text_field'
            className='form-control social'
            placeholder='Twitter'
            value={this.state.twitter || ''}
            onChange={this.handleChange} />
          <i className="fab fa-twitter profile-icon"></i>
        </div>
        <div className="form-field-container">
          <input
            name='youtube'
            type='text_field'
            className='form-control social'
            placeholder='Youtube'
            value={this.state.youtube || ''}
            onChange={this.handleChange} />
          <i className="fab fa-youtube profile-icon"></i>
        </div>
        <div className="form-field-container">
          <input
            name='website'
            type='text_field'
            className='form-control social'
            placeholder='Website'
            value={this.state.website || ''}
            onChange={this.handleChange} />
          <i className="fas fa-user profile-icon"></i>
        </div>
      </div>
    );
  }
}

export default ProfileForm