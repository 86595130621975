import React from 'react';

class PasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      current_password: '',
      password: '',
      password_confirmation: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.onPasswordChange(event);
    var target = event.target;
    var name = target.name;
    var value = target.value

    this.setState({
      name: value
    });
  }

  componentWillReceiveProps({password}) {
    this.setState({
      current_password: password.current_password,
      password: password.password,
      password_confirmation: password.password_confirmation,
    })
  }

    render() {
      return (
          <div className="form-group">
            <input
              name='current_password'
              type='password'
              className='form-control'
              placeholder='Current Password'
              value={this.state.current_password || ''}
              onChange={this.handleChange} />
            <input
              name='password'
              type='password'
              className='form-control'
              placeholder='Password'
              value={this.state.password || ''}
              onChange={this.handleChange} />
            <input
              name='password_confirmation'
              type='password'
              className='form-control'
              placeholder='Password Confirmation'
              value={this.state.password_confirmation || ''}
              onChange={this.handleChange} />
          </div>
      );
    }
  }

export default PasswordForm