import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  StudioTypeContext,
  UserContext,
  MediaContextProvider,
} from "./contexts";
import Page from "./Page";
import Browse from "./Browse";
import Favorites from "./Favorites";
import { Licenses } from "./Licenses";
import { Accounts } from "./Accounts";
import { Admins } from "./Admin";
import Billing from "./Billing";
import Support from "./Support";
import { Channels } from "./Channels";
import { Locations } from "./Locations";
import { Talent, Requests } from "./Talent";
import { Messaging } from "./Messaging";

const stripePublishableKey =
  process.env.RAILS_ENV == "production"
    ? "pk_live_zJgw5hOMeF2aUzOs13y3L21c"
    : "pk_test_ePBkw66K1yVTCSrIA3AjjiTv";
const stripePromise = loadStripe(stripePublishableKey);

const App = (props) => {
  const { initialRoute, initialStudioType } = props;
  const { redirect, path } = initialRoute;
  const [authorizations, setAuthorizations] = useState({
    admin: null,
    role: "",
    plans: {},
  });

  const [studioType, setStudioType] = useState(initialStudioType);

  useEffect(() => {
    fetch("/api/authorization.json")
      .then((response) => response.json())
      .then((data) => {
        setAuthorizations({
          admin: data.admin,
          role: data.role,
          userRole: data.user_role,
          tenantName: data.tenant_name,
          plans: data.plans,
        });
        setStudioType(data.studio_type);
      });
  }, []);

  const redirectTo = () => {
    if (redirect) {
      return <Redirect to={path} />;
    } else {
      return studioType == "creator" ? (
        <Redirect to="/browse" />
      ) : (
        <Redirect to="/channels" />
      );
    }
  };

  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <UserContext.Provider value={authorizations}>
          <StudioTypeContext.Provider value={{ studioType, setStudioType }}>
            <MediaContextProvider>
              <Route
                render={(props) => (
                  <Page {...props}>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={() => {
                          return redirectTo();
                        }}
                      />
                      <Route
                        path="/dashboard"
                        render={() => {
                          return redirectTo();
                        }}
                      />
                      <Route path="/browse" component={Browse} />
                      <Route path="/favorites" component={Favorites} />
                      <Route path="/my-licenses" component={Licenses} />
                      <Route path="/accounts" component={Accounts} />
                      <Route path="/admins" component={Admins} />
                      <Route path="/billing" component={Billing} />
                      <Route path="/support" component={Support} />
                      <Route path="/channels" component={Channels} />
                      <Route path="/locations" component={Locations} />
                      <Route path="/billing" component={Billing} />
                      <Route path="/talent" component={Talent} />
                      <Route path="/talent_requests" component={Requests} />
                      <Route path="/messaging_schedule" component={Messaging} />
                    </Switch>
                  </Page>
                )}
              />
            </MediaContextProvider>
          </StudioTypeContext.Provider>
        </UserContext.Provider>
      </Elements>
    </BrowserRouter>
  );
};

export default App;
