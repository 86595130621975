import styled from 'styled-components';
import { GridItem } from '../elements'

const CreditPackCard = styled(GridItem)`
  margin: 15px;
  :hover {
    cursor: pointer;
    border: 2px solid #f44336;
  }
`

export default CreditPackCard