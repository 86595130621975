import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Box, ClickableText, Flex, Input } from '../elements'

const SearchBar = (props) => {
  const [search, setSearch] = useState()
  const { submitSearch } = props

 const submit = () => {
   event.preventDefault();
   event.stopPropagation();
   submitSearch(search)
 }

 const clear = () => {
   setSearch('')
   submitSearch('')
 }

 const keyDown = () => {
  if (event.key === 'Enter') {
    submit()
  }
 }

  return (
    <Flex className="search-container" {...props}>
      <Input
        name='search'
        width='100%'
        ml='5px'
        py='5px'
        borderBottom='none'
        type='text_field'
        placeholder='Search'
        value={search || ''}
        onKeyDown={keyDown}
        onChange={event => setSearch(event.target.value)} />
      {search ?
        <ClickableText mr='10px' mt='5px' mb='0px' fontSize='1.15em' onClick={clear}>
          <FontAwesomeIcon color='grey' icon={solid('times')} />
        </ClickableText>
        : <></>
      }
      <ClickableText mr='10px' mt='5px' mb='0px' fontSize='1.15em' onClick={submit}>
        <FontAwesomeIcon color='grey' icon={solid('search')} />
      </ClickableText>
    </Flex>
  )
}

export default SearchBar
