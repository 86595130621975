import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  ClickableText,
  Flex,
  Grid,
  Input,
  Text,
  UnstyledButton,
} from "../elements";

const ZoneInfoRow = ({ zone, edit, updateZoneData }) => {
  const { code, username, occupied } = zone;
  const [name, setName] = useState(zone.name);
  const [device, setDevice] = useState(zone.device);
  const [pin, setPin] = useState();
  const [password, setPassword] = useState("*******");
  const [editPassword, setEditPassword] = useState(false);

  const color = occupied ? "green" : "#E21E26";
  const signedIn = occupied ? "Yes" : "No";

  const changeName = () => {
    setName(event.target.value);
    updateZoneData(code, "name", event.target.value);
  };

  const changeDevice = () => {
    setDevice(event.target.value);
    updateZoneData(code, "device", event.target.value);
  };

  const resetPassword = (event) => {
    event.preventDefault();
    $.ajax({
      url: `/passwords/${pin}/${zone.userId}`,
      type: "get",
      success: ({ password }) => {
        setPassword(password);
        setEditPassword(false);
      },
      error: function (error) {
        alert("Please check your PIN");
      },
    });
  };

  return (
    <Grid h={25} py={10} pl={20} gridTemplateColumns="5fr 4fr 4fr 5fr 4fr">
      <Flex flexDirection="row">
        <Text fontFamily="Montserrat" fontWeight="500" mt="3px">
          Zone {code}:{" "}
        </Text>
        {edit ? (
          <Input
            fontFamily="Montserrat"
            height="25px"
            maxWidth={150}
            ml="7px"
            border="1px solid"
            borderBottom="1px solid"
            onChange={changeName}
            value={name || ""}
          />
        ) : (
          <Text pl={10} fontFamily="Montserrat" mt="3px">
            {name}
          </Text>
        )}
      </Flex>
      {edit ? (
        <Input
          fontFamily="Montserrat"
          height="25px"
          maxWidth={150}
          border="1px solid"
          borderBottom="1px solid"
          onChange={changeDevice}
          value={device || ""}
        />
      ) : (
        <Text fontFamily="Montserrat" mt="3px">
          {device}
        </Text>
      )}
      <Text fontFamily="Montserrat" mt="3px">
        {username}
      </Text>
      <Flex flexDirection="row" mt="3px">
        {editPassword ? (
          <>
            <Input
              height="25px"
              width="50px"
              border="1px solid"
              borderBottom="1px solid"
              maxLength={"4"}
              size={"4"}
              placeholder={"PIN"}
              value={pin}
              autoFocus
            />
            <UnstyledButton onClick={resetPassword}>Reset</UnstyledButton>
          </>
        ) : (
          <Text width="60px">{password}</Text>
        )}
        <Text pl={10}>|</Text>
        <ClickableText
          pl={10}
          fontFamily="Montserrat"
          onClick={() => setEditPassword(!editPassword)}
        >
          {editPassword ? "Cancel" : "Reset"}
        </ClickableText>
      </Flex>
      <Flex mt="3px">
        <Text fontSize="0.75em">
          <FontAwesomeIcon icon={solid("circle")} color={color} />
        </Text>
        <Text pl="7px" fontFamily="Montserrat">
          {signedIn}
        </Text>
      </Flex>
    </Grid>
  );
};

export default ZoneInfoRow;
