import React, { useState } from 'react';
import {Box, Image} from '../elements'
import TalentModal from './TalentModal'
import TalentButtons from './TalentButtons'

const TalentItem = (props) => {
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const [buttonsDisplayed, setButtonsDisplayed] = useState(false);

  const stopAudio = () => {
    const audio = window.kk.player.overlayAudio
    if (audio) { audio.unload() }
    window.kk.player.overlayAudio = undefined
  }

  const toggleModal = () => {
    if (modalDisplayed) { stopAudio() }
    setModalDisplayed(!modalDisplayed)
  }

  return (
    <Box 
      minHeight='150px'
      padding='20px'
      onMouseEnter={() => setButtonsDisplayed(true)}
      onMouseLeave={() => setButtonsDisplayed(false)}
    >
      <Box className="talent-content box-item">
        <Box className="talent-image-holder" position='relative'>
          <Image src={props.talent.image.thumb.url} m='0'/>
          <div className="description">{props.talent.description}</div>
          <TalentButtons
            talent={props.talent}
            stopAudio={stopAudio}
            toggleModal={toggleModal}
            displayed={buttonsDisplayed}
          />
        </Box>
        <div className="talent-info-box">
          <div className="talent-info-status"></div>
          <div className="talent-info">
            <div className="title">{props.talent.name}</div>
          </div>
        </div>
      </Box>
      <TalentModal
          talent={props.talent}
          stopAudio={stopAudio}
          displayed={modalDisplayed}
          toggleModal={toggleModal}
          toggleCreditsModal={props.toggleCreditsModal}
      />
    </Box>
  )
};

export default TalentItem