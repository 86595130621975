import styled from 'styled-components';
import { border, color, compose, layout, space, typography, variant } from 'styled-system'

const BasicButton = styled.button`
  &:hover {
    color: black;
    background-color: white;
    cursor: pointer;
  }
  ${compose(border, color, space, layout, typography)}
`

BasicButton.defaultProps = {
  border: 'none',
  borderRadius: '25px',
  color: 'black'
}

const Button = styled.button`
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #f44336;
  padding: 0.375rem 0.75rem;
  &:hover {
    color: white;
    background-color: #f44336;
  }
  &:focus {
    outline: unset;
  }
  ${compose(color, space, layout, typography)}
`;

Button.defaultProps = {
  display: 'block',
  color: '#f44336',
}

const UnstyledButton = styled.button`
  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: scale(0.95);
  }
  ${compose(border, color, space, layout, typography)}
`;

export { BasicButton, Button, UnstyledButton }
