import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, ClickableText, Flex, Grid, Spinner, Text } from "../elements";
import LocationRow from "./LocationRow";
import Pagination from "../Pagination";

const Locations = () => {
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    perPage: 8,
  });

  useEffect(() => {
    setClassName("main", "locations");
  }, []);

  const fetchLocations = () => {
    fetch(
      `/api/v2/sites?exclude_zones=true&page=${paginationParams.page}&per_page=${paginationParams.perPage}`
    )
      .then((response) => response.json())
      .then((data) => {
        setLocations(data.sites);
        setPageInfo(data.page_info);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchLocations();
  }, [paginationParams]);

  const locationRows = locations.map((location) => {
    return (
      <LocationRow
        key={location.id}
        location={location}
        fetchLocations={fetchLocations}
      />
    );
  });

  return (
    <Flex
      flexDirection="column"
      minHeight={800}
      boxShadow="0px 0px 2px #bdb4c0"
      borderRadius="6px"
      position="relative"
      bg="white"
      m={20}
      mb={80}
      py={20}
    >
      <Grid
        gridTemplateColumns="1fr 4fr 3fr 4fr 4fr 2fr"
        p={10}
        borderBottom="0.5px solid #EAF0F1"
      >
        <Text textAlign="left" fontFamily="Montserrat" fontWeight="bold">
          #
        </Text>
        <Text textAlign="left" fontFamily="Montserrat" fontWeight="bold">
          Location Name
        </Text>
        <Text textAlign="left" fontFamily="Montserrat" fontWeight="bold">
          Address
        </Text>
        <Text textAlign="left" fontFamily="Montserrat" fontWeight="bold">
          Time Zone
        </Text>
        <Text textAlign="left" fontFamily="Montserrat" fontWeight="bold">
          Settings
        </Text>
        <Text textAlign="left" fontFamily="Montserrat" fontWeight="bold">
          Zones
        </Text>
      </Grid>
      <Box>
        {loading ? (
          <Spinner mt="25px" />
        ) : (
          <>
            {locationRows}
            <Flex flexDirection="row" mt={30} ml={10}>
              <Text fontFamily="Montserrat" fontWeight="600">
                Add Location
              </Text>
              <ClickableText
                pl="5px"
                fontSize="0.85em"
                onClick={() => history.push("/support")}
              >
                <FontAwesomeIcon icon={solid("circle-plus")} />
              </ClickableText>
            </Flex>
          </>
        )}
        <Pagination
          pageInfo={pageInfo}
          paginationParams={paginationParams}
          setPaginationParams={setPaginationParams}
        />
      </Box>
    </Flex>
  );
};

export default Locations;
