import React from 'react';
import styled from 'styled-components';
import {
  border, compose, color, layout, space, typography
} from 'styled-system'

const Text = styled.p`
  ${compose(border, color, layout, space, typography)}
`

const HoverText = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`

const HoverBackgroundText = styled(Text)`
  &:hover {
    cursor: pointer;
    background: rgba(210, 210, 210, 0.5);
  }
`

const ClickableText = (props) => {
  return (
    props.hoverColor ? <HoverBackgroundText {...props} /> : <HoverText {...props} />
  )
}

const UnderlinedText = styled(ClickableText)`
  text-decoration: underline;
`

const Heading = styled(Text)`
  text-transform: uppercase;
`

export {ClickableText, Heading, Text, UnderlinedText}
