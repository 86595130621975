import styled from 'styled-components';
import {
  border, compose, color, space
} from 'styled-system'


const Divider = styled.hr`
  ${compose(border, color, space)}
`

Divider.defaultProps = {
  margin: '0px',
  mb: '10px',
  border: '1px solid #eeeeee;'  
}

export default Divider