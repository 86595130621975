import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, Flex, Grid, GridItem, Input, Radio, Text } from '../elements'

const CardForm = ({ onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipcode, setZipcode] = useState('')
  const stripe = useStripe();
  const elements = useElements();

  const clearForm = () => {
    setName('')
    setAddress('')
    setCity('')
    setState('')
    setZipcode('')
  }

  const handleBillingSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) { return; }

    setIsLoading(true)

    const data = {
      name: name,
      address_line1: address,
      address_city: city,
      address_state: state,
      address_zip: zipcode,
      address_country: 'US'
    }
    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement, data);

    if (error) {
      window.kk.notifier.notify("Error adding billing, please contact support.", "error");
      setIsLoading(false)
    } else {
      sendToken(token);
      cardElement.clear()
      clearForm()
      onSubmit()
    }
  };

  const sendToken = (token) => {
    $.ajax({
      url: '/api/v2/billing',
      type: 'POST',
      data: {
        stripe_token: token
      },
      success: (result) => {
        window.kk.notifier.notify("Updated billing information.", "success");
        setIsLoading(false)
      },
      error: (error) => {
        setIsLoading(false)
        window.kk.notifier.notify("Error adding billing, please contact support.", "error");
      }
    })
  }

  return (
    <Grid pt='15px' gridRowGap='20px' gridColumnGap='15px'>
      <GridItem gridColumn='1/4'>
        <Text fontWeight='600'>Add a credit or debit card</Text>
      </GridItem>
      <GridItem gridColumn='1/4'>
        <Input
          name='name'
          type='text_field'
          placeholder='Name on Card'
          width='100%'
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </GridItem>
      <GridItem gridColumn='1/4'>
        <Input
          name='address'
          type='text_field'
          placeholder='Billing Address'
          width='100%'
          value={address}
          onChange={(event) => setAddress(event.target.value)}
        />
      </GridItem>
      <GridItem gridColumn={1}>
        <Input
          name='city'
          type='text_field'
          placeholder='City'
          width='100%'
          value={city}
          onChange={(event) => setCity(event.target.value)}
        />
      </GridItem>
      <GridItem gridColumn={2}>
        <Input
          name='state'
          type='text_field'
          placeholder='State'
          value={state}
          onChange={(event) => setState(event.target.value)}
        />
      </GridItem>
      <GridItem gridColumn={3}>
        <Input
          name='zipcode'
          type='text_field'
          placeholder='ZIP'
          maxLength="5"
          value={zipcode}
          onChange={(event) => setZipcode(event.target.value)}
        />
      </GridItem>
      <GridItem gridColumn='1/4' borderBottom='1px solid #eeeeee'>
        <CardElement options={{ hidePostalCode: true }} />
      </GridItem>
      <GridItem gridColumn={3} pl='35%'>
        {isLoading ? <div className="loading"></div> : <Button onClick={handleBillingSubmit}>Add Card</Button>}
      </GridItem>
    </Grid>
  )
}

export default CardForm