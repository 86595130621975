import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import AudioPlayer from "./AudioPlayer";

const Page = (props) => {
  const [open, setOpen] = useState(true);
  const sidebarClass = open ? "" : "collapsed";

  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <div className="main_container">
      <div className={"app_container " + sidebarClass}>
        <div className="sidebar">
          <Sidebar {...props} />
          <div className="sidebar_control" onClick={toggleSidebar}>
            <div className="more-vertical-solid icon"></div>
          </div>
          <div className="sidebar_details"></div>
        </div>
        <div className="app_content">
          <div className="content">{props.children}</div>
          <div id="music_player">
            <AudioPlayer />
          </div>
        </div>
      </div>
      <div id="portal_area"></div>
    </div>
  );
};

export default Page;
