import React from 'react';
import { Icon } from '../elements'

class UserForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      default_studio_type: '',
      job_title: '',
      number: '',
      email: '',
      username: '',
      profile_image_name: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleProfileImageUpload = this.handleProfileImageUpload.bind(this);
  }

  componentWillReceiveProps({user}) {
    var url = user.profile_image ? user.profile_image.url : null
    var imageName = url ? url.split('/').pop() : ''
    this.setState({
      first_name: user.first_name,
      last_name: user.last_name,
      job_title: user.job_title,
      number: user.number,
      email: user.email,
      username: user.username,
      profile_image_name: imageName,
    })
  }

  handleProfileImageUpload(event) {
    event.preventDefault()
    var formData = new FormData();
    var file = event.target.files[0]
    formData.append('user[profile_image]', file);
    data =
    $.ajax({
        url: '/api/v2/accounts/user/profile_image',
        type: 'PATCH',
        dataType: 'JSON',
        data: formData,
        success: (result) => {
          imageName = result.user.profile_image.url
          // split and return just filename
          this.setState({
            profile_image_name: imageName
          });
          $('#profile-image-thumb').attr('src', imageName)
          window.kk.notifier.notify("Profile photo updated.", "success");
        },
        error: (error) => {
          window.kk.notifier.notify("Error uploading photo. Please try again later.", "error");
        },
        cache: false,
        contentType: false,
        processData: false
    })

  }

  handleChange(event) {
    this.props.onUserChange(event);
    var target = event.target;
    var name = target.name;
    var value = target.value

    this.setState({
      name: value
    });
  }

  render() {
    var uploadPhotoSvgData = (
      <g>
        <g>
          <polygon points="45,16 32,0 19,16 28,16 28,40 36,40 36,16" className='form-icon'/>
        </g>
        <g>
          <path
          d="M52.4,64H12c-4.4,0-8-3.7-8-8.3V29.8c0-4.3,3.4-7.8,7.6-7.8H22v8H12v25.7c0,0.2,0.1,0.3,0.1,0.3L52,56V30.2c0-0.1,0-0.2-0.1-0.2H42v-8h10c4.4,0,8,3.7,8,8.2v26C60,60.5,56.6,64,52.4,64z"
          className='form-icon' />
        </g>
      </g>
    )

    return (
        <div className="form-group top">
          <input
            name='first_name'
            type='text_field'
            className='form-control'
            placeholder='First Name'
            value={this.state.first_name || ''}
            onChange={this.handleChange}
            autoFocus />
          <input
            name='last_name'
            type='text_field'
            className='form-control'
            placeholder='Last Name'
            value={this.state.last_name || ''}
            onChange={this.handleChange} />
          <input
            name='job_title'
            type='text_field'
            className='form-control'
            placeholder='Job Title'
            value={this.state.job_title || ''}
            onChange={this.handleChange} />
          <input
            name='number'
            type='tel'
            className='form-control'
            placeholder='Number'
            value={this.state.number || ''}
            onChange={this.handleChange} />
          <input
            name='email'
            type='email_field'
            className='form-control'
            placeholder='Email'
            value={this.state.email || ''}
            onChange={this.handleChange} />
          <input
            name='username'
            type='text_field'
            className='form-control'
            placeholder='Username'
            value={this.state.username || ''}
            onChange={this.handleChange} />
          <div className="form-field-container form-control">
            <input
            name='profile_image_name'
            type='text_field'
            className='form-field'
            placeholder='Profile Photo'
            value={this.state.profile_image_name || ''}
            disabled={true} />
            <input
            name='profile_image'
            type='file'
            id='fileUpload'
            className='form-control image-upload'
            onChange={this.handleProfileImageUpload} />
            <label htmlFor="fileUpload"><Icon itemClassName='form-icon' svgClassName='form-field-icon' fill='#7f7f7f' title="ProfilePhotoUpload" svgData={uploadPhotoSvgData} transformScale={'0.25'} height={"20"} width={"20"}/></label>
          </div>
        </div>
    );
  }
}

export default UserForm