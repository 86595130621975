import React from 'react';

class SongModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed: this.props.displayed,
      licensed: this.props.licensed,
      playlists: [],
      playlistsDisplayed: false,
      playlistsLoading: false,
      addingToPlaylist: false
    }

    this.handleClose = this.handleClose.bind(this)
    this.handleRelicense = this.handleRelicense.bind(this)
    this.displayPlaylists = this.displayPlaylists.bind(this)
    this.hidePlaylists = this.hidePlaylists.bind(this)
    this.getWatermark = this.getWatermark.bind(this)
    this.addToPlaylist = this.addToPlaylist.bind(this)
    this.handleCreatePlaylistClick = this.handleCreatePlaylistClick.bind(this)
  }

  componentWillReceiveProps({displayed, licensed}) {
    this.setState({
      displayed: displayed,
      licensed: licensed,
    })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClose);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClose);
  }

  handleClose(event) {
    var classList = event.target.classList
    if (classList.contains("dots-button")) {
      return
    }
    if (!event.target.matches('.song-modal *') && this.state.displayed) {
      this.props.handleClose(event)
    }
  }

  handleRelicense(event) {
    this.props.handleRelicense(event)
  }

  handleCreatePlaylistClick(event) {
    this.props.handleClose(event)
    this.props.handleMyPlaylistsClick(event)
  }

  displayPlaylists(event) {
    this.setState({
      playlistsLoading: true,
      playlistsDisplayed: true
    })
    this.serverRequest = $.get(`/api/v2/my_playlists`, (result) => {
        this.setState({
          playlistsLoading: false,
          playlists: result
        });
    });
  }

  addToPlaylist(event) {
    var playlist = event.target.dataset.playlistId
    var url = `/api/v2/my_playlists/${playlist}/add_song`
    this.setState({
      addingToPlaylist: true
    })
    this.serverRequest =
      $.ajax({
          url: url,
          type: 'POST',
          data: {
            song_id: this.props.song.id
          },
          success: (result) => {
            this.setState({
              addingToPlaylist: false
            })
            window.kk.notifier.notify("Added song to playlist.", "success");
          },
          error: (error) => {
            this.setState({
              addingToPlaylist: false
            })
            window.kk.notifier.notify("Error adding song to playlist, please contact support.", "error");
          }
      })
  }

  hidePlaylists(event) {
    this.setState({
      playlistsDisplayed: false
    })
  }

  getWatermark(event) {
    event.preventDefault()
    if (!this.props.song.watermark) {
      window.kk.notifier.notify("This song does not have a sample.", "error");
      return this.props.handleClose(event)
    }
    this.setState({
      isLoading: true
    })
    data =
    $.ajax({
        url: `/api/v2/songs/${this.props.song.id}/watermark`,
        type: 'GET',
        success: (response, status, xhr) => {
          var filename = "";
          var disposition = xhr.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
          }

          var type = xhr.getResponseHeader('Content-Type');
          var blob = new Blob([response], { type: type });

          if (typeof window.navigator.msSaveBlob !== 'undefined') {
              // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
              window.navigator.msSaveBlob(blob, filename);
          } else {
              var URL = window.URL || window.webkitURL;
              var downloadUrl = URL.createObjectURL(blob);

              if (filename) {
                  // use HTML5 a[download] attribute to specify filename
                  var a = document.createElement("a");
                  // safari doesn't support this yet
                  if (typeof a.download === 'undefined') {
                      window.location.href = downloadUrl;
                  } else {
                      a.href = downloadUrl;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                  }
              } else {
                  window.location.href = downloadUrl;
              }

              setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
          }
          this.setState({
            isLoading: false
          })
          window.kk.notifier.notify("Watermark succesfully downloaded.", "success");
          this.props.handleClose(event)
        },
        error: (error) => {
          this.setState({
            isLoading: false
          })
          window.kk.notifier.notify("Error downloading watermark, please contact support.", "error");
          this.props.handleClose(event)
        }
    })
  }

  render() {
    var playlists = this.state.playlists.map((playlist) => <div key={playlist.id} className="playlist-item" onClick={this.addToPlaylist} data-playlist-id={playlist.id} >{playlist.name}</div>);
    return (
      <div className={this.state.displayed ? "song-modal" : "hidden"} onClick={this.handleClose} onMouseLeave={this.hidePlaylists}>
        <div className="song-dropdown">
          <div className="dropdown-group">
            <div className="dropdown-item"
              onMouseOver={this.displayPlaylists}>
              <span className="add-to-playlist">Add to My Playlists</span>
              <i className="fas fa-chevron-right"></i>
            </div>
            <div className="dropdown-item" onMouseOver={this.hidePlaylists} onClick={this.handleCreatePlaylistClick}>Create new Playlist</div>
          </div>
          <span className="divider"></span>
          <div className="dropdown-group" onMouseOver={this.hidePlaylists}>
            {this.state.licensed ? <div className="dropdown-item" onClick={this.handleRelicense}>Relicense Song</div> : <div></div>}
            {this.state.isLoading ? <div className="loading loading-small"></div> : <div className="dropdown-item" onClick={this.getWatermark}>Try the Sample</div>}
          </div>
        </div>
        <div className={this.state.playlistsDisplayed ? "playlists-dropdown" : "hidden"}>
          {this.state.addingToPlaylist ? <div className="loading"></div> : <div className="dropdown-group">{playlists}</div>}
        </div>
      </div>
    )
  }
}

export default SongModal