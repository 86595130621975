import React from 'react';

const Icon = (props) => {
    const height = props.height ? props.height : "32"
    const width = props.width ? props.width : "32"
    const viewbox = `0 0 ${width} ${height}`
    return (
        <svg
            version="1.1"
            width={width} height={height}
            viewBox={viewbox}
            aria-labelledby="title"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className={props.svgClassName}
        >
            <style>
                {
                    `.${props.itemClassName} { fill:${props.fill} }`
                }
            </style>
            <title id="title">{props.title}</title>
            <g transform={`scale(${props.transformScale ? props.transformScale : '0.04'})`}>
                {props.svgData}
            </g>
        </svg>
    );
};

export default Icon