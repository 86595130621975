import React from 'react';
import { UserContext } from '../contexts'
import SongModal from './SongModal'
import { MyPlaylistsModal } from '../Playlists'
import { LicenseModal, LicenseDropdownModal } from '../Licenses'
import Dots from '../../images/3-dots.png'
import StopButton from '../../images/red-stop-button.png'
import PlayButton from '../../images/play-button-grey.png'
import SolidHeart from '../../images/heart-compact-solid-red.png'
import OutlineHeart from '../../images/heart-compact-outline.png'
import Chevron from '../../images/double-chevron-solid.png'

class Song extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      song: props.song,
      playing: false,
      wavesurfer: null,
      licenseModalDisplayed: false,
      songModalDisplayed: false,
      licenseDropdownModalDisplayed: false,
      isLicensed: props.song.is_licensed,
      myPlaylistsModalDisplayed: false
    }

    this.playSong = this.playSong.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.handleDotsClick = this.handleDotsClick.bind(this)
    this.handleLicenseClick = this.handleLicenseClick.bind(this)
    this.handleLicenseDropdownClick = this.handleLicenseDropdownClick.bind(this)
    this.handleLicenseSubmit = this.handleLicenseSubmit.bind(this)
    this.handleLicenseSubmit = this.handleLicenseSubmit.bind(this)
    this.handleMyPlaylistsClick = this.handleMyPlaylistsClick.bind(this)
  }

  componentDidMount() {
    var that = this
    var song = this.state.song
    var wavesurfer = WaveSurfer.create({
        container: `#waveform-${this.state.song.id}`,
        waveColor: '#ecf0f1',
        progressColor: '$kk-red',
        cursorColor: 'white',
        height: 50,
        barMinHeight: 1
    });
    wavesurfer.song = song.file_url;
    wavesurfer.backend.peaks = song.waveform ? song.waveform : [0, 0, 0, 0, 0, 0]
    wavesurfer.drawBuffer();
    wavesurfer.loaded = false

    wavesurfer.on('seek', (progress) => {
      this.seekSong(progress)
    })
    this.setState({
      wavesurfer: wavesurfer
    })
  }

  componentWillUnmount() {
    var currentSong = window.kk.player.currentSong
    if (currentSong && currentSong.id == this.state.song.id) {
      return
    }
    this.state.wavesurfer.destroy()
    this.state.wavesurfer.arraybuffer = null
  }

  seekSong(progress) {
    if (!this.state.playing && progress > 0) {
      this.playSong(null, progress)
    }
  }

  playSong(event, progress) {
    event ? event.preventDefault() : null
    var that = this
    if (!this.state.playing) {
      window.kk.player.playWavesurfer(
          this.state.song, this.state.wavesurfer, progress,
          () => {
            that.setState({
              playing: true
            })
          },
          () => {
              that.setState({
                  playing: false
              });
          }
      );
    } else {
      window.kk.player.currentSong.wavesurfer.stop();
    }
  }

  handleFavoriteClick(event) {
    event.preventDefault()
    $.ajax({
        url: `/api/v2/songs/${this.state.song.id}/favorite`,
        type: 'POST',
        dataType: 'JSON',
        data: {favorite: !this.state.song.is_favorited},
        success: (result) => {
          this.setState({
            song: result,
          });
        },
        error: (error) => {
          window.kk.notifier.notify("Error favoriting song, please try again later.", "error");
        }
    })
  }

  handleDotsClick(event) {
    event.preventDefault()
    this.setState({
      songModalDisplayed: !this.state.songModalDisplayed
    })
  }

  handleLicenseDropdownClick(event) {
    event.preventDefault()
    this.setState({
      licenseDropdownModalDisplayed: !this.state.licenseDropdownModalDisplayed
    })
  }

  handleLicenseClick(event) {
    event.preventDefault()
    var plan = this.context.plans.creator
    if (plan && plan.status == "active") {
      this.setState({
        licenseModalDisplayed: !this.state.licenseModalDisplayed
      })
    } else {
      window.location.href = "/plans?creator=true";
    }
  }

  handleLicenseSubmit(success) {
    if (success) {
      this.setState({
        isLicensed: true,
        licenseModalDisplayed: false
      })
    }
  }

  handleMyPlaylistsClick(event) {
    event.preventDefault()
    this.setState({
      myPlaylistsModalDisplayed: !this.state.myPlaylistsModalDisplayed
    })
  }

  render() {
      const song = this.state.song;
      const audioButton = this.state.playing ? StopButton : PlayButton
      const tags = this.state.song.tags.map((tag) => <span className="tag" key={tag.id}>{tag.name}</span>)
      const heart = this.state.song.is_favorited ? SolidHeart : OutlineHeart
      const licenseDropdown = this.props.licenseDropdown ? <input type="image" src={Chevron} className="license-dropdown-button" onClick={this.handleLicenseDropdownClick} /> : <span></span>
      const licenseButton = this.state.isLicensed ? <span className='licensed'>Licensed</span> : <input type="submit" value="License" className='button btn btn-primary red' onClick={this.handleLicenseClick} />
      return (
          <div className={this.state.licenseDropdownModalDisplayed ? "song pop-up" : "song"}>
              <div className={this.state.licenseDropdownModalDisplayed ? "song-content pop-up" : "song-content"} data-song-id={song.id}>
                  <input type="image" src={audioButton} className="audio-btn" onClick={this.playSong} />
                  <div className="song-info">
                      <span className="title">{song.name}</span>
                      <span className="type">KK Exclusive</span>
                  </div>
                  <div className="song-waveform" id={`waveform-${song.id}`}>
                  </div>
                  <div className="song-length">
                    <span className="length">{song.display_length}</span>
                  </div>
                  <div className="tags-container song-tags">
                    {tags}
                  </div>
                  <div className="song-buttons">
                    {licenseDropdown}
                    <input type="image" src={Dots} className="dots-button" onClick={this.handleDotsClick} />
                    <input type="image" src={heart} className="heart-button" onClick={this.handleFavoriteClick} />
                    {licenseButton}
                  </div>
              </div>
              <LicenseModal songId={this.state.song.id} displayed={this.state.licenseModalDisplayed} handleClose={this.handleLicenseClick} handleSubmit={this.handleLicenseSubmit} />
              <SongModal song={this.state.song} displayed={this.state.songModalDisplayed} licensed={this.state.isLicensed} handleClose={this.handleDotsClick} handleRelicense={this.handleLicenseClick} handleMyPlaylistsClick={this.handleMyPlaylistsClick} />
              <LicenseDropdownModal songId={this.state.song.id} displayed={this.state.licenseDropdownModalDisplayed} handleClose={this.handleLicenseDropdownClick} />
              <MyPlaylistsModal displayed={this.state.myPlaylistsModalDisplayed} handleClose={this.handleMyPlaylistsClick} />
          </div>
      )
  }
}

Song.contextType = UserContext;
export default Song