import React from 'react'
import { Box, Flex, Grid, Input, Radio, RadioLabel, Text } from '../elements'

const ZoneOptionRow = ({zone, rowType, gridTemplate, checked, selectZone}) => {
  const {code, name} = zone
  const scheduleText = zone.messageSchedule ?
    `Play every ${zone.messageSchedule.interval} songs`
    : 'Not set'

  const getMessageText = ({global, local}) => {
    if (global && local) {
      return 'Global & Local'
    } else if (global) {
      return 'Global'
    } else if (local) {
      return 'Local'
    } else {
      return 'No Messaging'
    }
  }
  const extraColumns = rowType === 'zonesMusic' ?
    <Text fontFamily='Montserrat' mt='3px'>{zone.channel}</Text>
  : <>
      <Text fontFamily='Montserrat' mt='3px'>{getMessageText(zone.messaging)}</Text>
      <Text fontFamily='Montserrat' mt='3px'>{scheduleText}</Text>
    </>

  const columnTemplate = rowType === 'zonesMusic' ? '9fr 7fr' : '5fr 4fr 4fr'

  return (
    <Grid
      h={25}
      py={10}
      pl={20}
      gridTemplateColumns={columnTemplate}
    >
    <Flex flexDirection='row' mt='3px'>
      <Radio
        id={`zone-${code}`}
        mt='2px !important'
        mr='10px !important'
        type='radio'
        checked={checked}
        onChange={() => selectZone(code)}
      />
      <RadioLabel htmlFor={`zone-${code}`} />
      <Text fontFamily='Montserrat' fontWeight='600'>Zone {code}:</Text>
      <Text pl={10} fontFamily='Montserrat'>{name}</Text>
    </Flex>
    {extraColumns}
    </Grid>
  )
}

export default ZoneOptionRow
