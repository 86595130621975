import React from 'react';
import {Category, Tag} from './index'

class FilterNav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      search: null,
      activeCategory: this.props.activeCategory,
      activeTags: this.props.activeTags,
      tags: [],
      categories: [],
      extraFilter: null
    }

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this.setCategories()
    this.setTags(this.state.activeCategory)
  }

  componentWillReceiveProps({activeTags}) {
    this.setState({
      activeTags: activeTags
    })
  }

  projectCategories() {
    return [
      {id: "advertising", name: "Advertising"},
      {id: "audioBooks", name: "Audio Books"},
      {id: "corporate", name: "Corporate"},
      {id: "church", name: "Church"},
      {id: "documentaries", name: "Documentaries"},
      {id: "entertainment", name: "Entertainment"},
      {id: "anniversaries", name: "Events: Anniversaries"},
      {id: "birthdays", name: "Events: Birthdays"},
      {id: "corporate", name: "Events: Corporate"},
      {id: "mitzvahs", name: "Events: Mitzvahs"},
      {id: "reunion", name: "Events: Reunion"},
      {id: "weddings", name: "Events: Weddings"},
      {id: "fitness", name: "Fitness"},
      {id: "food", name: "Food"},
      {id: "healthCare", name: "Health Care"},
      {id: "hobby", name: "Hobby"},
      {id: "realEstate", name: "Real Estate"},
      {id: "sports", name: "Sports"},
      {id: "tutorial", name: "Tutorial"}
    ]
  }

  setCategories() {
    this.serverRequest = $.get('/api/v2/categories', (result) => {
      this.setState({categories: result})
    })
  }

  setTags(category) {
    if (category === 'My Playlists') {
      this.serverRequest = $.get(`/api/v2/my_playlists`, (result) => {
          this.setState({
            tags: result,
            search: ''
          });
      });
    } else if (category === 'Project Categories') {
      this.setState({
        tags: this.projectCategories(),
        search: ''
      })
    } else {
      this.serverRequest = $.get(`/api/v2/tags.json?category=${category}`, (result) => {
          this.setState({
              tags: result.tags,
              search: ''
          });
      });
    }
  }

  handleSearchChange(event) {
    this.setState({
      search: event.target.value
    });
  }

  handleSearchClear(event) {
    event.preventDefault()
    var search = ''
    this.props.onSearchSubmit(search)
    this.setState({
      search: search
    });
  }

  handleClearAll(event) {
    event.preventDefault()
    var search = ''
    var activeTags = []
    var activeCategory = ''
    this.props.onClearAll()
    this.setState({
      search: search,
      activeTags: activeTags,
      activeCategory: activeCategory
    });
  }

  handleCategoryChange(event) {
    this.props.onCategoryChange(event)
    var category = event.target.name
    this.setState({
      activeCategory: category,
      search: ''
    });
    this.setTags(category)
  }

  handleTagChange(event) {
    if (['My Playlists', 'Project Categories'].includes(this.state.activeCategory)) {
      var value = event.target.checked ? event.target.name : null
      this.setState({
        extraFilter: value
      });
      this.props.onExtraFilterChange(value)
    } else {
      var tag = event.target.name
      var currentTags = this.state.activeTags
      if (event.target.checked) {
        var newTags;
        currentTags.includes(tag) ? newTags = [...currentTags] : newTags = [...currentTags, tag]
      } else {
        var newTags = currentTags.filter(function(value, index, arr){ return value != tag;});
      }
      this.setState({
        activeTags: newTags
      })
      this.props.onActiveTagsChange(event)
    }
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.props.onSearchSubmit(this.state.search)
    }
  }

  handleSearchSubmit(event) {
    event.preventDefault()
    this.props.onSearchSubmit(this.state.search)
  }

  render() {
      var categoryNames = this.state.categories.map((category) => category.name)
      if (this.props.extraFilter) {
        categoryNames = [...categoryNames, this.props.extraFilter]
      }
      var categories = categoryNames.map((name) => <Category name={name} onClick={this.handleCategoryChange} isActive={name == this.state.activeCategory} key={name}/>);
      var tags = this.state.tags.map((tag) => <Tag name={tag.name} onTagChange={this.handleTagChange} isActive={this.state.activeTags.includes(tag.name) || this.state.extraFilter == tag.name} key={tag.id} />);
      return(
          <div className="filter-nav-content">
              <div className="content-header">
                <span className="content-header-text">All Audio</span>
              </div>
              <div className="category-and-search">
                <div className="categories-container">
                  {categories}
                </div>
                <div className="search-container">
                  <input
                    name='search'
                    type='text_field'
                    className='form-control search-input'
                    placeholder='Search'
                    value={this.state.search || ''}
                    onKeyDown={this.onKeyDown}
                    onChange={this.handleSearchChange} />
                  {this.state.search ? <i className="fas fa-times search-clear" onClick={this.handleSearchClear}></i> : <span></span>}
                  <i className="fas fa-search search-button" onClick={this.handleSearchSubmit}></i>
                </div>
                {(this.state.search || this.state.activeTags.length > 0) ?
                  <div onClick={this.handleClearAll} className="clear-filters">
                    <span>Remove Filters</span>
                    <i className="fas fa-times clear-filters-x" onClick={this.handleSearchClear}></i>
                  </div>
                  : <span></span>}
              </div>
              <div className="tags-container">
                {tags}
              </div>
          </div>
      )
  }
}

export default FilterNav