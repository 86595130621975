import React, { useState } from "react";
import ConfirmModal from "../shared/ConfirmModal";

const DisableMessageModal = ({
  onClose,
  updateStatus,
  updateMessageConfirmation,
}) => {
  const [dontShow, setDontShow] = useState(false);
  const modalBody = () => {
    return (
      <div className="confirm_container">
        <h4>Confirm disable message</h4>
        <p>
          By disabling this message, it will be automatically removed from all
          of your locations and zones within this Master Account.
        </p>
        <div className="label-input">
          <label
            htmlFor="decline_confirmation"
            className="control control-checkbox"
            style={{
              position: "relative",
            }}
          >
            <input
              type="checkbox"
              id="decline_confirmation"
              checked={dontShow}
              onChange={(e) => {
                setDontShow(e.target.checked);
              }}
            />
            <div
              className="control_indicator"
              style={{ left: 0, right: "unset", top: "2px" }}
            ></div>
            Don’t show this message again.
          </label>
        </div>
        <button
          className="red-btn"
          onClick={(e) => {
            updateStatus(e, "disable");
            updateMessageConfirmation(!dontShow);
          }}
        >
          Confirm
        </button>
      </div>
    );
  };

  return <ConfirmModal content={modalBody()} onClose={onClose} />;
};

export default DisableMessageModal;
