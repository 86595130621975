import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Box, ClickableText, Flex, Grid, UnstyledButton, Text } from '../elements'

const ZoneSelectionModal = ({zone, displayed, handleClose, saveInfo}) => {
  const [selectedOption, setSelectedOption] = useState('zone')
  const [dropdown, setDropdown] = useState(false)

  const options = {
    zone: 'Apply In This Zone Only',
    location: 'Apply Across All Zones In This Location',
    global: 'Apply Across All Zones In All Locations'
  }

  const selectOption = (event, key) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedOption(key)
    setDropdown(false)
  }

  const toggleDropdown = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setDropdown(!dropdown)
  }

  const maybeCloseDropdown = (event) => {
    event.stopPropagation()
    event.preventDefault()
    dropdown && setDropdown(!dropdown)
  }

  const handleSumbit = (event) => {
    event.stopPropagation()
    event.preventDefault()
    saveInfo(selectedOption)
    handleClose()
  }

  const closeModal = (event) => {
    const modal = event.target.classList.contains("zone-selection-modal")
    const dialog = event.target.classList.contains("modal-dialog")
    if (modal || dialog) {
      handleClose(event)
    }
  }

  const dropdownOptions = Object.keys(options).map(key => {
    return (
      <ClickableText
        key={key}
        mb='0px'
        pl='10px'
        py='10px'
        borderTop='solid 1px #B7B7B7'
        hoverColor={true}
        onClick={event => selectOption(event, key)}
      >
        {options[key]}
      </ClickableText>
    )
  })

  return (
    <Flex
      bg='rgba(0, 0, 0, 0.5)'
      className={displayed ? "modal zone-selection-modal" : "hidden"}
      onClick={closeModal}
    >
      <div className="modal-dialog modal-lg">
        <Box onClick={maybeCloseDropdown} className="modal-content" width='500px' height='300px' ml='35%' mt='15%'>
          <Flex flexDirection='column' justifyContent='space-around' p='10%' height='100%'>
            <Text
              pr={10}
              mb='15px'
              fontFamily='Montserrat'
              fontSize='1.35em'
              fontWeight='600'
              textAlign='center'>
              Zone {zone && zone.code}: {zone && zone.name}
            </Text>
            <Box mb='15px'>
              <Flex bg='#f5f5f5' onClick={toggleDropdown}>
                <ClickableText ml='10px' mt='10px' mr='auto'>{options[selectedOption]}</ClickableText>
                <Box mt='10px' mr='10px'>
                  <FontAwesomeIcon icon={solid('caret-down')} color='black' />
                </Box>
              </Flex>
              { dropdown &&
                <Flex flexDirection='column' bg='#F0F0F0'>
                  {dropdownOptions}
                </Flex>
              }
            </Box>
            { !dropdown &&
              <Text>Please allow up to 3 songs to play before any changes take effect.</Text>
            }
            <Flex justifyContent='center'>
              <UnstyledButton
                color='white'
                bg='#E21E26'
                borderRadius='25px'
                height='35px'
                width='85px'
                onClick={handleSumbit}
              >Save</UnstyledButton>
            </Flex>
          </Flex>
        </Box>
      </div>
    </Flex>
  )
}

export default ZoneSelectionModal
