import React from 'react';

class Tag extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isActive: this.props.isActive
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps({isActive}) {
    this.setState({
      isActive: isActive
    })
  }

  handleChange(event) {
    var isActive = !this.state.isActive
    this.props.onTagChange(event)
    this.setState({
      isActive: isActive
    })
  }

  render() {
    return (
      <label className={this.state.isActive ? 'tag active-tag' : 'tag'}>
        {this.props.name}
        <input name={this.props.name} type="checkbox" checked={this.state.isActive} onChange={this.handleChange} />
      </label>
    )
  }
}

export default Tag