import React from 'react';
import { useContext } from 'react';
import { StudioTypeContext, UserContext } from './contexts'
import { NavLink } from 'react-router-dom'
import { Icon } from './elements'

const messageScheduleSvgData =
  <path
    d="M12 15H1a1.017 1.017 0 0 1-1-1.034V2.582a1.018 1.018 0 0 1 1-1.035h1v.517a1.5 1.5 0 1 0 3 0v-.517h3v.517a1.5 1.5 0 1 0 3 0v-.517h1a1.018 1.018 0 0 1 1 1.035v11.379A1.018 1.018 0 0 1 12 15zm0-9.827H1v8.793h11V5.173zM3.5 7.759H2V6.2h1.5v1.552zm0 2.586H2V8.793h1.5v1.552zm0 2.586H2V11.38h1.5v1.551zM6 7.759H4.5V6.2H6v1.552zm0 2.586H4.5V8.793H6v1.552zm0 2.586H4.5V11.38H6v1.551zm2.5-5.172H7V6.2h1.5v1.552zm0 2.586H7V8.793h1.5v1.552zm0 2.586H7V11.38h1.5v1.551zM11 7.759H9.5V6.2H11v1.552zm0 2.586H9.5V8.793H11v1.552zm0 2.586H9.5V11.38H11v1.551zM9.484 3.1A1 1 0 0 1 8.5 2.081V1.015a.985.985 0 1 1 1.969 0v1.066A1 1 0 0 1 9.484 3.1zm-6 0A1 1 0 0 1 2.5 2.081V1.015a.985.985 0 1 1 1.969 0v1.066A1 1 0 0 1 3.484 3.1z"
    className="sidebar-icon" />

const billingSvgData =
  <path
    d="M309.43 219.944c-19-10.5-39.2-18.5-59.2-26.8-11.6-4.8-22.7-10.4-32.5-18.2-19.3-15.4-15.6-40.4 7-50.3 6.4-2.8 13.1-3.7 19.9-4.1 26.2-1.4 51.1 3.4 74.8 14.8 11.8 5.7 15.7 3.9 19.7-8.4 4.2-13 7.7-26.2 11.6-39.3 2.6-8.8-.6-14.6-8.9-18.3-15.2-6.7-30.8-11.5-47.2-14.1-21.4-3.3-21.4-3.4-21.5-24.9-.1-30.3-.1-30.3-30.5-30.3-4.4 0-8.8-.1-13.2 0-14.2.4-16.6 2.9-17 17.2-.2 6.4 0 12.8-.1 19.3-.1 19-.2 18.7-18.4 25.3-44 16-71.2 46-74.1 94-2.6 42.5 19.6 71.2 54.5 92.1 21.5 12.9 45.3 20.5 68.1 30.6 8.9 3.9 17.4 8.4 24.8 14.6 21.9 18.1 17.9 48.2-8.1 59.6-13.9 6.1-28.6 7.6-43.7 5.7-23.3-2.9-45.6-9-66.6-19.9-12.3-6.4-15.9-4.7-20.1 8.6-3.6 11.5-6.8 23.1-10 34.7-4.3 15.6-2.7 19.3 12.2 26.6 19 9.2 39.3 13.9 60 17.2 16.2 2.6 16.7 3.3 16.9 20.1.1 7.6.1 15.3.2 22.9.1 9.6 4.7 15.2 14.6 15.4 11.2.2 22.5.2 33.7-.1 9.2-.2 13.9-5.2 13.9-14.5 0-10.4.5-20.9.1-31.3-.5-10.6 4.1-16 14.3-18.8 23.5-6.4 43.5-19 58.9-37.8 42.8-52 26.5-128.1-34.1-161.6z"
    className="sidebar-icon" />

const locationSvgData =
  <path
    d="M408.5 207v-59.833h-42.459v-32.27h-30v32.27h-42.46V207H271V93h-36.419V34h-48.667V0h-30v34h-48.666v59h-36.42v419h370.344V207H408.5zM157.414 457.333h-30V427h30v30.333zm0-56h-30V371h30v30.333zm0-56h-30V315h30v30.333zm0-56h-30V259h30v30.333zm0-56h-30V203h30v30.333zm0-56h-30V147h30v30.333zm57 280h-30V427h30v30.333zm0-56h-30V371h30v30.333zm0-56h-30V315h30v30.333zm0-56h-30V259h30v30.333zm0-56h-30V203h30v30.333zm0-56h-30V147h30v30.333zm120.505 280h-30V427h30v30.333zm0-56h-30V371h30v30.333zm0-56h-30V315h30v30.333zm0-56h-30V259h30v30.333zm57 168h-30V427h30v30.333zm0-56h-30V371h30v30.333zm0-56h-30V315h30v30.333zm0-56h-30V259h30v30.333z"
    className="sidebar-icon" />

const channelsSvgData =
  <path
    d="M5.002 2v10.205c-.684-.298-1.633-.298-2.551.174-1.364.702-1.749 1.98-1.224 2.791s1.96 1.152 3.325.451c.982-.506 1.456-1.311 1.449-2.023V5.8l8-1.6v7.005c-.685-.298-1.633-.298-2.551.174-1.364.702-1.749 1.98-1.224 2.791.524.811 1.959 1.152 3.324.451.98-.505 1.455-1.309 1.449-2.021V0L5.002 2z"
    className="sidebar-icon" />

const supportSvgData =
  <path
    d="M477.371 127.44c-22.843-28.074-53.871-50.249-93.076-66.523-39.204-16.272-82.035-24.41-128.478-24.41-34.643 0-67.762 4.805-99.357 14.417-31.595 9.611-58.812 22.602-81.653 38.97-22.845 16.37-41.018 35.832-54.534 58.385C6.757 170.833 0 194.484 0 219.228c0 28.549 8.61 55.3 25.837 80.234 17.227 24.931 40.778 45.871 70.664 62.811-2.096 7.611-4.57 14.846-7.426 21.693-2.855 6.852-5.424 12.474-7.708 16.851-2.286 4.377-5.376 9.233-9.281 14.562-3.899 5.328-6.849 9.089-8.848 11.275-1.997 2.19-5.28 5.812-9.851 10.849-4.565 5.048-7.517 8.329-8.848 9.855-.193.089-.953.952-2.285 2.567-1.331 1.615-1.999 2.423-1.999 2.423l-1.713 2.566c-.953 1.431-1.381 2.334-1.287 2.707.096.373-.094 1.331-.57 2.851-.477 1.526-.428 2.669.142 3.433v.284c.765 3.429 2.43 6.187 4.998 8.277 2.568 2.092 5.474 2.95 8.708 2.563 12.375-1.522 23.223-3.606 32.548-6.276 49.87-12.758 93.649-35.782 131.334-69.097 14.272 1.522 28.072 2.286 41.396 2.286 46.442 0 89.271-8.138 128.479-24.417 39.208-16.272 70.233-38.448 93.072-66.517 22.843-28.062 34.263-58.663 34.263-91.781.001-33.119-11.418-63.718-34.254-91.787z"
    className="sidebar-icon" />

const talentSvgData = (
  <g>
    <path
      d="M7.6 8.1c-.3-.1-.9-.1-1.2-.2-.2-.1-.4-.1-.5-.3s-.1-.9-.1-.9.2-.2.3-.4c.1-.3.1-.9.1-.9s.3.1.3-.4c.1-.4.2-.6.2-.8 0-.3-.2-.2-.2-.2s.2-.3.2-1.2c0-1-.7-1.9-2.1-1.9-1.4 0-2.1.9-2.1 1.9 0 .9.2 1.2.2 1.2s-.1-.1-.2.2c0 .3.1.5.2.8.1.5.3.4.3.4s.1.6.2.9c.1.2.3.5.3.5s0 .8-.1.9c-.1.1-.4.1-.6.2-.2.1-.8.1-1.2.2-.4.1-1.5.7-1.5 2.2h9c0-1.4-1.1-2-1.5-2.2z"
      className="sidebar-icon" />
    <path
      d="M8.4 6.6s-.8 0-1.1-.3c.5-.8.2-1.6.3-2.5.1-.8.6-1.3 1.5-1.3s1.4.5 1.5 1.3c.1.8-.2 1.8.3 2.5-.3.3-1.1.3-1.1.3v.5c.1.1.3.2.4.2.3.1.6.1.8.2.3.1.7.6.7 1.2H9.3c-.1-.3-.6-.7-1-1-.3-.1-.6-.1-.8-.1 0 0-.1-.2.3-.3 0 0 .6-.1.6-.2v-.5z"
      className="sidebar-icon" />
  </g>
)

const talentRequestSvgData = (
  <g>
    <path
      d="M44 28c-.552 0-1 .447-1 1v6c0 7.72-6.28 14-14 14s-14-6.28-14-14v-6c0-.553-.448-1-1-1s-1 .447-1 1v6c0 8.485 6.644 15.429 15 15.949V56h-5c-.552 0-1 .447-1 1s.448 1 1 1h12c.552 0 1-.447 1-1s-.448-1-1-1h-5v-5.051c8.356-.52 15-7.465 15-15.949v-6c0-.553-.448-1-1-1z"
      className="sidebar-icon" />
    <path
      d="M29 46c6.065 0 11-4.935 11-11V11c0-6.065-4.935-11-11-11S18 4.935 18 11v24c0 6.065 4.935 11 11 11z"
      className="sidebar-icon" />
  </g>
)

const heartSvgData =
  <path
    d="M59.9,29.9c2.6-3.1,4.1-7,4.1-11.4C64,8.8,56.2,1,46.7,1C40.5,1,35.1,4.3,32,9.2C28.9,4.3,23.5,1,17.3,1    C7.8,1,0,8.8,0,18.5c0,4.3,1.6,8.3,4.1,11.4L32,63L59.9,29.9z"
    className="sidebar-icon" />

const myLicensesSvgData =
  <path
    d="M5.002 2v10.205c-.684-.298-1.633-.298-2.551.174-1.364.702-1.749 1.98-1.224 2.791s1.96 1.152 3.325.451c.982-.506 1.456-1.311 1.449-2.023V5.8l8-1.6v7.005c-.685-.298-1.633-.298-2.551.174-1.364.702-1.749 1.98-1.224 2.791.524.811 1.959 1.152 3.324.451.98-.505 1.455-1.309 1.449-2.021V0L5.002 2z"
    className="sidebar-icon" />

const adminsSvgData = (
  <g>
    <path
      d="M7.6 8.1c-.3-.1-.9-.1-1.2-.2-.2-.1-.4-.1-.5-.3s-.1-.9-.1-.9.2-.2.3-.4c.1-.3.1-.9.1-.9s.3.1.3-.4c.1-.4.2-.6.2-.8 0-.3-.2-.2-.2-.2s.2-.3.2-1.2c0-1-.7-1.9-2.1-1.9-1.4 0-2.1.9-2.1 1.9 0 .9.2 1.2.2 1.2s-.1-.1-.2.2c0 .3.1.5.2.8.1.5.3.4.3.4s.1.6.2.9c.1.2.3.5.3.5s0 .8-.1.9c-.1.1-.4.1-.6.2-.2.1-.8.1-1.2.2-.4.1-1.5.7-1.5 2.2h9c0-1.4-1.1-2-1.5-2.2z"
      className="sidebar-icon" />
    <path
      d="M8.4 6.6s-.8 0-1.1-.3c.5-.8.2-1.6.3-2.5.1-.8.6-1.3 1.5-1.3s1.4.5 1.5 1.3c.1.8-.2 1.8.3 2.5-.3.3-1.1.3-1.1.3v.5c.1.1.3.2.4.2.3.1.6.1.8.2.3.1.7.6.7 1.2H9.3c-.1-.3-.6-.7-1-1-.3-.1-.6-.1-.8-.1 0 0-.1-.2.3-.3 0 0 .6-.1.6-.2v-.5z"
      className="sidebar-icon" />
  </g>
)

const eyeSvgData = (
  <g>
    <g>
      <path
        d="M63.8,31.4C61.1,28.3,46.6,13,32,13C17.4,13,2.9,28.3,0.2,31.4c-0.3,0.4-0.3,0.9,0,1.3C2.9,35.8,17.4,51,32,51
        c14.6,0,29.1-15.2,31.8-18.3C64.1,32.3,64.1,31.7,63.8,31.4z M32,44c-6.6,0-12-5.4-12-12s5.4-12,12-12s12,5.4,12,12
        S38.6,44,32,44z"
        className="sidebar-icon" />
    </g>
    <g>
      <circle cx="32" cy="32" r="7" className="sidebar-icon" />
    </g>
  </g>
)

const accountsSvgData = (
  <g>
    <path className="sidebar-icon" d="M1046.54,115.55h-728a12,12,0,0,0-12,12v512a12,12,0,0,0,12,12h728a12,12,0,0,0,12-12v-512A12,12,0,0,0,1046.54,115.55Zm-379,456a12,12,0,0,1-12,12h-286a12,12,0,0,1-12-12v-369a12,12,0,0,1,12-12h286a12,12,0,0,1,12,12Zm333.82-55.09a12,12,0,0,1-12,12H734.78a12,12,0,0,1-12-12V499.25a12,12,0,0,1,12-12H989.36a12,12,0,0,1,12,12Zm0-83a12,12,0,0,1-12,12H734.78a12,12,0,0,1-12-12V416.28a12,12,0,0,1,12-12H989.36a12,12,0,0,1,12,12Zm0-83a12,12,0,0,1-12,12H734.78a12,12,0,0,1-12-12V333.31a12,12,0,0,1,12-12H989.36a12,12,0,0,1,12,12Zm0-83a12,12,0,0,1-12,12H734.78a12,12,0,0,1-12-12V250.34a12,12,0,0,1,12-12H989.36a12,12,0,0,1,12,12Z" transform="translate(-306.54 -115.55)"/>
    <path className="sidebar-icon" d="M507.64,377.45a68,68,0,1,0-70.22-65.64A68,68,0,0,0,507.64,377.45Z" transform="translate(-306.54 -115.55)"/>
    <path className="sidebar-icon" d="M535.44,395.61H475.25a74.35,74.35,0,0,0-74.12,74.13v61.81H609.57V469.74A74.35,74.35,0,0,0,535.44,395.61Z" transform="translate(-306.54 -115.55)"/>
  </g>
)

const Sidebar = (props) => {
  const { admin, plans, role } = useContext(UserContext)
  const { studioType, setStudioType } = useContext(StudioTypeContext)

  const onStudioTypeChange = (event, studioType) => {
    event.preventDefault()
    var path = studioType == 'instore' ? '/locations' : '/browse'
    props.history.push(path)
    setStudioType(studioType)
  }

  const shouldShowStudioLinks = plans && plans.creator && plans.instore

  const creatorLinks = (
    <div>
      <ul className="nav nav-pills nav-stacked">
          <li className="heading">
              Music
              <hr/>
          </li>
          <li className="list-item">
              <NavLink to="/browse" activeClassName="active">
                  <span className="link-icon"><i className="far fa-play-circle fa-2x sidebar-icon browse-play"></i></span>
                  <span className="link-text">Browse</span>
              </NavLink>
          </li>
          <li className="list-item">
              <NavLink to="/favorites" activeClassName="active">
                  <span className="link-icon"><Icon itemClassName='sidebar-icon' svgClassName='sidebar-nav-icon' fill='#fff' title="favorites" transformScale={'0.3'} svgData={heartSvgData}/></span>
                  <span className="link-text">Favorites</span>
              </NavLink>
          </li>
          <li className="list-item">
              <NavLink to="/my-licenses" activeClassName="active">
                  <span className="link-icon"><Icon itemClassName='sidebar-icon' svgClassName='sidebar-nav-icon' fill='#fff' title="talent" transformScale={'1.2'} svgData={myLicensesSvgData}/></span>
                  <span className="link-text">My Licenses</span>
              </NavLink>
          </li>
          <li className="heading">
              Account
              <hr/>
          </li>
          <li className="list-item">
              <NavLink to="/accounts" activeClassName="active">
                  <span className="link-icon"><Icon itemClassName='sidebar-icon' svgClassName='sidebar-nav-icon' fill='#fff' title="Accounts" transformScale={'0.03'} svgData={accountsSvgData}/></span>
                  <span className="link-text">Accounts</span>
              </NavLink>
          </li>
          {
              (plans && plans.creator &&  plans.creator.type == 'enterprise') ? (
                  <li className="list-item">
                      <NavLink to="/admins" activeClassName="active">
                          <span className="link-icon"><Icon itemClassName='sidebar-icon' svgClassName='sidebar-nav-icon' fill='#fff' title="Admins" transformScale={'2.1'} svgData={adminsSvgData}/></span>
                          <span className="link-text">Admins</span>
                      </NavLink>
                  </li>
              ) : <span></span>
          }
          <li className="list-item">
              <NavLink to="/billing" activeClassName="active">
                  <span className="link-icon"><Icon itemClassName='sidebar-icon' svgClassName='sidebar-nav-icon' fill='#fff' title="Billing" svgData={billingSvgData}/></span>
                  <span className="link-text">Billing</span>
              </NavLink>
          </li>
          <li className="list-item">
              <NavLink to="/support" activeClassName="active">
                  <span className="link-icon"><Icon itemClassName='sidebar-icon' svgClassName='sidebar-nav-icon' fill='#fff' title="Support" svgData={supportSvgData}/></span>
                  <span className="link-text">Support</span>
              </NavLink>
          </li>
      </ul>
      {
        ((role == 'owner' || admin) && shouldShowStudioLinks) ? (
          <ul className="studio-type-links">
            <li><span><Icon itemClassName='sidebar-icon' svgClassName='sidebar-nav-icon' fill='#fff' title="studio-type" transformScale={'0.25'} svgData={eyeSvgData} height={"16"} width={"25"}/></span><a href='/' onClick={(event) => onStudioTypeChange(event, 'creator')} className='studio-type'>Creator Studio</a></li>
            <li><a href='/' onClick={(event) => onStudioTypeChange(event, 'instore')} className='studio-type'>Instore Studio</a></li>
          </ul>
        ) : <span></span>
      }
    </div>
  )

  const instoreLinks = (
    <div>
      <ul className="nav nav-pills nav-stacked">
        <li className="heading">
          Music
          <hr />
        </li>
        <li className="list-item">
          <NavLink to="/channels" activeClassName="active">
            <span className="link-icon">
              <Icon
                itemClassName="sidebar-icon"
                svgClassName="sidebar-nav-icon"
                fill="#fff"
                transformScale={"1.2"}
                title="channels"
                svgData={channelsSvgData}
              />
            </span>
            <span className="link-text">Channels</span>
          </NavLink>
        </li>
        {role !== "member" && (
          <>
            <li className="heading">
              Messaging
              <hr />
            </li>
            <li className="list-item">
              <NavLink to="/talent" activeClassName="active">
                <span className="link-icon">
                  <Icon
                    itemClassName="sidebar-icon"
                    svgClassName="sidebar-nav-icon"
                    fill="#fff"
                    title="talent"
                    transformScale={"2.1"}
                    svgData={talentSvgData}
                  />
                </span>
                <span className="link-text">Talent Request</span>
              </NavLink>
            </li>
            <li className="list-item">
              <NavLink to="/talent_requests" activeClassName="active">
                <span className="link-icon talent-request_ico">
                  <Icon
                    itemClassName="sidebar-icon"
                    svgClassName="sidebar-nav-icon"
                    fill="#fff"
                    transformScale={"0.4"}
                    title="Talent Requests"
                    svgData={talentRequestSvgData}
                  />
                </span>
                <span className="link-text">In Production</span>
              </NavLink>
            </li>
            <li className="list-item">
              <NavLink to="/messaging_schedule" activeClassName="active">
                <span className="link-icon">
                  <Icon
                    itemClassName="sidebar-icon"
                    svgClassName="sidebar-nav-icon"
                    fill="#fff"
                    transformScale={"1.3"}
                    title="Talent Requests"
                    fillRule={"evenodd"}
                    svgData={messageScheduleSvgData}
                  />
                </span>
                <span className="link-text">Messaging Schedule</span>
              </NavLink>
            </li>
          </>
        )}
        <li className="heading">
          Manage
          <hr />
        </li>
        {role !== "member" && (
          <li className="list-item">
            <NavLink to="/accounts" activeClassName="active">
              <span className="link-icon">
                <Icon
                  itemClassName="sidebar-icon"
                  svgClassName="sidebar-nav-icon"
                  fill="#fff"
                  title="Accounts"
                  transformScale={"0.03"}
                  svgData={accountsSvgData}
                />
              </span>
              <span className="link-text">Accounts</span>
            </NavLink>
          </li>
        )}
        {role === "member" && !admin ? (
          <span></span>
        ) : (
          <li className="list-item">
            <NavLink to="/locations" activeClassName="active">
              <span className="link-icon">
                <Icon
                  itemClassName="sidebar-icon"
                  svgClassName="sidebar-nav-icon"
                  fill="#fff"
                  title="Locations"
                  svgData={locationSvgData}
                />
              </span>
              <span className="link-text">Locations</span>
            </NavLink>
          </li>
        )}
        {role === "member" && !admin ? (
          <span></span>
        ) : (
          <li className="list-item">
            <NavLink to="/billing" activeClassName="active">
              <span className="link-icon">
                <Icon
                  itemClassName="sidebar-icon"
                  svgClassName="sidebar-nav-icon"
                  fill="#fff"
                  title="Billing"
                  svgData={billingSvgData}
                />
              </span>
              <span className="link-text">Billing</span>
            </NavLink>
          </li>
        )}
        <li className="list-item">
          <NavLink to="/support" activeClassName="active">
            <span className="link-icon">
              <Icon
                itemClassName="sidebar-icon"
                svgClassName="sidebar-nav-icon"
                fill="#fff"
                title="Support"
                svgData={supportSvgData}
              />
            </span>
            <span className="link-text">Support</span>
          </NavLink>
        </li>
      </ul>
      {(role == "owner" || admin) && shouldShowStudioLinks ? (
        <ul className="studio-type-links">
          <li>
            <a
              href="/"
              onClick={(event) => onStudioTypeChange(event, "creator")}
              className="studio-type"
            >
              Creator Studio
            </a>
          </li>
          <li>
            <span>
              <Icon
                itemClassName="sidebar-icon"
                svgClassName="sidebar-nav-icon"
                fill="#fff"
                title="favorites"
                transformScale={"0.25"}
                svgData={eyeSvgData}
                height={"16"}
                width={"25"}
              />
            </span>
            <a
              href="/"
              onClick={(event) => onStudioTypeChange(event, "instore")}
              className="studio-type"
            >
              Instore Studio
            </a>
          </li>
        </ul>
      ) : (
        <span></span>
      )}
    </div>
  );

  const loading = (
    <div>
      <ul className="nav nav-pills nav-stacked">
      </ul>
    </div>
  )
  const content = studioType === 'creator' ? creatorLinks : instoreLinks

  return(
    studioType ? content : loading
  )
}

export default Sidebar
