import React from 'react';
import { Checkbox } from '../elements'

const assignMessages = (locationId) => {
    return (event) => {
        event.preventDefault();
        const form = $(event.target);
        $.ajax({
            url: `/locations/${locationId}/assigned_messages`,
            type: 'POST',
            dataType: 'JSON',
            data: form.serialize(),
            success: function(data) {
                $('.close').click();
                window.kk.notifier.notify("Settings Saved");
            },
            error: function(error) {
                window.kk.notifier.notify("Error assigning messages to location, please contact support.", `${error}`);
            }
        })
    };
};

const assignMessagingSchedule = (locationId) => {
    return (event) => {
        event.preventDefault();
    };
};

const intervalChange = () => {}

const toggleCheckboxes = (event) => {
    const checkboxes = document.getElementsByName("local_assignments[]");
    checkboxes.forEach((checkbox) => {
        checkbox.checked = event.target.checked
    })
};

const submitForm = () => {
    document.querySelector("input[type='submit']").click()
};

class LocationMessagesModal extends React.Component {
    constructor(props) {
        super(props);
        if (!props.user.messaging_schedule) {
            props.user.messaging_schedule = {
                interval: 0,
                interval_unit: 'song',
            };
        }
        super(props);
        this.state = {
            localChecked: this.props.localMessaging > 0,
        };
    }

    submitAllForm(locations) {
        $.ajax({
            url: '/api/update_messaging_schedule.json',
            type: 'POST',
            dataType: 'JSON',
            data: {
                type: 'all',
                interval: $('#intervalInput').val(),
                interval_unit: $('#intervalUnitInput').val(),
            },
            success: function(data) {
                window.kk.notifier.notify("Messaging Schedule updated");
                window.location.reload();
            },
            error: function(error) {
                window.kk.notifier.notify("Error updating messaging schedule, please contact support.", `${error}`);
                window.location.reload();
            }
        });
    };

    submitSingleForm(user) {
        // single
        $.ajax({
            url: '/api/update_messaging_schedule.json',
            type: 'POST',
            dataType: 'JSON',
            data: {
                type: 'single',
                interval: $('#intervalInput').val(),
                interval_unit: $('#intervalUnitInput').val(),
                user_id: this.props.user.id
            },
            success: function(data) {
                window.kk.notifier.notify("Messaging Schedule updated");
                window.location.reload();
            },
            error: function(error) {
                window.kk.notifier.notify("Error updating messaging schedule, please contact support.", `${error}`);
                window.location.reload();
            }
        });
    };

    toggleButtons(id) {
      if (id) {
        $(".button-item.active").removeClass("active")
      }
      $(id).addClass("active")
    }

    render() {
        const { assigned, global } = this.props.messages;
        const assignedCheckboxes = assigned.map((message, idx) => {
            return <Checkbox
                key={`${idx}-${message.id}`}
                name="local_assignments[]"
                id={message.id}
                checked={!!message.assigned}
                label={`${message.name}`}
                handleCheckboxChange={this.props.onCheckBoxChange}/>
        });
        const toggleButtons = this.toggleButtons()
        return (
            <div className="modal fade" id="location-settings-modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <ul className="nav nav-tabs" id="location-settings-nav-tabs" role="tablist">
                                <li className="nav-item active" onClick={() => this.toggleButtons("#messaging-buttons")}>
                                    <a className="messaging-link nav-link" id="messaging-tab" data-toggle="tab" href="#messaging" role="tab" aria-controls="messaging" aria-selected="true">Messaging</a>
                                </li>
                                <li className="nav-item" onClick={() => this.toggleButtons("#messaging-schedule-buttons")}>
                                  <a className="nav-link" id="automation-tab" data-toggle="tab" href="#messaging-schedule" role="tab" aria-controls="automation" aria-selected="false">Messaging Schedule</a>
                                </li>
                                <li className="nav-item" onClick={() => this.toggleButtons("#automation-buttons")}>
                                    <a className="nav-link" id="automation-tab" data-toggle="tab" href="#automation" role="tab" aria-controls="automation" aria-selected="false">Automation</a>
                                </li>
                            </ul>
                        </div>
                        <div className="modal-body mx-6">
                            <div className="container-fluid">
                                <div className="tab-content" id="location-settings-tab-content">
                                    <h2 className="location-header">{`Location ${this.props.locationCode}`}</h2>
                                    <div className="row tab-pane fade in active" id="messaging" role="tabpanel" aria-labelledby="messaging-tab">
                                        <form id="message-assignment section-content" className="md-form mb-5 message assignments" onSubmit={assignMessages(this.props.locationId)}>
                                            <div className="messaging-fieldset">
                                                <fieldset className="col-md-6">
                                                    <legend className="messaging-fieldset legend">Messaging: Settings</legend>
                                                    <div>
                                                        <input
                                                            id="global-messaging"
                                                            className="default-message"
                                                            type="checkbox"
                                                            name="global_messaging"
                                                            value="enable"
                                                            defaultChecked={global}/>
                                                        <label htmlFor="global-messaging">Global Messaging</label>
                                                        <p className="messaging-fieldset notice">This will enable the Global Messages to play within this location.</p>
                                                        <input
                                                            id="local-messaging"
                                                            className="default-message"
                                                            type="checkbox"
                                                            name="local_messaging"
                                                            value="enable"
                                                            onClick={toggleCheckboxes}
                                                            defaultChecked={this.state.localChecked}/>
                                                        <label htmlFor="local-messaging">Local Messaging</label>
                                                        <p className="messaging-fieldset notice">This will enable a specific message or messages to play within this location only.</p>
                                                        <p className="messaging-fieldset notice">
                                                            If Global & Local are both selected,
                                                            then the selected messages from both will play in this location.
                                                        </p>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="col-md-6 checkboxes">
                                                    <legend className="messaging-fieldset legend">Live Messages</legend>
                                                    {assignedCheckboxes}
                                                </fieldset>
                                            </div>
                                            <input className="btn btn-primary btn-sm" type="submit" value="Save" />
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="messaging-schedule" role="tabpanel" aria-labelledby="messaging-schedule-tab">
                                      <form id="messagingScheduleForm" ref="messagingForm" className="col-sm-12 request-items" onSubmit={assignMessagingSchedule}>
                                          <br/>
                                          <br/>
                                          <div className="form-row">
                                              <div className="col-sm-2 schedule-label">
                                                  <label className="control-label">Play Every: </label>
                                              </div>
                                              <div className="col-xs-4 input-control-container">
                                                  <input id="intervalInput" name="interval" type="text" maxLength="10" size="10" className="messaging-form-ctrl input-md" placeholder="interval" defaultValue={this.props.user.messaging_schedule.interval} />
                                              </div>
                                              <div className="col-xs-4 input-control-container">
                                                  <select id="intervalUnitInput" className="form-control input-lg messaging-form-ctrl no-radius" defaultValue="minute" value={this.props.user.messaging_schedule.interval_unit} name="interval_unit" onChange={intervalChange}>
                                                      {/*<option value="minute">Minute(s)</option>*/}
                                                      <option value="song">Song(s)</option>
                                                  </select>
                                              </div>
                                              <div className="cb"></div>
                                          </div>
                                      </form>
                                    </div>
                                    <div className="tab-pane fade" id="automation" role="tabpanel" aria-labelledby="automation-tab">Coming Soon</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                          <div className="button-item active" id="messaging-buttons">
                            <button type="button" onClick={submitForm} className="btn btn-primary">Save changes</button>
                            <button type="button" onClick={this.props.closeModal} className="btn btn-secondary" data-dismiss="modal">Close</button>
                          </div>
                          <div className="button-item" id="messaging-schedule-buttons">
                            <div className="notice-text">Please allow up to 8 songs to play before any changes take effect</div>
                            <button type="button" onClick={this.submitSingleForm.bind(this)} data-type="single" className="btn btn-primary">Save</button>
                            <button type="button" onClick={this.submitAllForm.bind(this)} data-type="all" className="btn btn-primary">Save for All</button>
                            <button type="button" onClick={this.props.closeModal} className="btn btn-secondary" data-dismiss="modal">Close</button>
                          </div>
                          <div className="button-item" id="automation-buttons">
                            <button type="button" onClick={this.props.closeModal} className="btn btn-secondary" data-dismiss="modal">Close</button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LocationMessagesModal