import React from 'react';
import License from './License'

class LicenseDropdownModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed: this.props.displayed,
      licenses: [],
      isLoading: false
    }

    this.handleClose = this.handleClose.bind(this)
  }

  componentWillReceiveProps({displayed}) {
    this.setState({
      displayed: displayed
    })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClose);
    this.setState({
      isLoading: true
    })
    var url = `/api/v2/licenses.json?song_id=${this.props.songId}`
    this.serverRequest = $.get(url, (result) => {
        this.setState({
            licenses: result,
            isLoading: false
        });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClose);
  }

  handleClose(event) {
    var classList = event.target.classList
    if (classList.contains("license-dropdown-button")) {
      return
    }
    if (event.target.matches('.license-dropdown-bg') && this.state.displayed) {
      this.props.handleClose(event)
    }
    if (!event.target.matches('.license-dropdown-modal *') && this.state.displayed) {
      this.props.handleClose(event)
    }
  }

  render() {
    var licenses = this.state.licenses.map((license) => <License license={license} key={license.id}/>);
    return (
      <div className={this.state.displayed ? "license-dropdown-modal" : "hidden"} onClick={this.handleClose}>
        <div className="license-dropdown-bg"></div>
        <div className="license-dropdown">
          <div className="dropdown-group">
            <div className="license-content">
              <strong>Project Title</strong>
              <strong>Project Description</strong>
              <strong>Sublicensing Information</strong>
              <strong>Project Category</strong>
              <strong>Date</strong>
              <strong>License Number</strong>
            </div>
            {licenses}
          </div>
        </div>
      </div>
    )
  }
}

export default LicenseDropdownModal