import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Flex } from "../elements";

const Pagination = ({ pageInfo, setPaginationParams, paginationParams }) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(pageInfo?.current_page ?? 1);
  }, [pageInfo]); // Only re-run the effect if pageInfo changes

  return (
    <Flex justifyContent="center">
      <ReactPaginate
        nextLabel=">"
        onPageChange={(event) => {
          const newPage = event.selected + 1;
          if (newPage !== paginationParams.page) {
            setPaginationParams({
              ...paginationParams,
              page: newPage,
            });
          }
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageInfo?.total_pages}
        active
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        forcePage={currentPage - 1}
        renderOnZeroPageCount={null}
      />
    </Flex>
  );
};

export default React.memo(Pagination);
