import React from 'react';

class Category extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      className: this.props.isActive ? 'category active' : 'category'
    }
  }

  componentWillReceiveProps({isActive}) {
    var className = isActive ? 'category active' : 'category'
    this.setState({
      className: className
    })
  }

  render() {
    return (
      <a name={this.props.name} onClick={this.props.onClick} className={this.state.className}>{this.props.name}</a>
    )
  }
}

export default Category