import React, { useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Box, ClickableText, Flex, Grid, Text, UnstyledButton } from '../elements'
import ZoneInfoRow from './ZoneInfoRow'

const ZonesInfo = ({zones, edit, setZoneData, zoneData, updateLocation}) => {
  const history = useHistory()
  

  useEffect(() => {
    let initialData = zones.reduce((data, zone) => {
      data[zone.code] = {code: zone.code, name: zone.name, device: zone.device}
      return data
    }, {})
    setZoneData(initialData)
  }, [])

  const saveInfo = (event) => {
    event.preventDefault()
    updateLocation(Object.values(zoneData))
  }

  const updateZoneData = (code, field, value) => {
    let zone = zoneData[code]
    zone[field] = value
    zoneData[code] = zone
  }

  const zoneRows = zones.map((zone, index) => {
      return (
        <ZoneInfoRow
          key={zone.code}
          zone={zone}
          edit={edit}
          updateZoneData={updateZoneData}
        />
      )
  })

  return (
    <Box bg='white' height={500} overflowY='scroll' borderRadius='6px'>
    <Flex justifyContent="space-between">
      <div>
      <Text
        p={10}
        pl={15}
        pt={20}
        fontFamily='Montserrat'
        fontSize='1.5em'
        fontWeight='bold'
      >
        Zones Info
        
      </Text>
      </div>
      <div>
      {edit && (
            <UnstyledButton
              color="white"
              bg="#E21E26"
              borderRadius="25px"
              mr={50}
              px={25}
              mt={2}
              onClick={saveInfo}
            >
              Save
            </UnstyledButton>
        )}
        </div>
      </Flex>
      <Grid
        py={10}
        pl={20}
        gridTemplateColumns='5fr 4fr 4fr 5fr 4fr'
        bg='#F3F6FA'
      >
        <Text m={0} fontFamily='Montserrat' fontWeight='600'>Zone Name</Text>
        <Text m={0} fontFamily='Montserrat' fontWeight='600'>Zone Device</Text>
        <Text m={0} fontFamily='Montserrat' fontWeight='600'>Zone Username</Text>
        <Text m={0} fontFamily='Montserrat' fontWeight='600'>Zone Password</Text>
        <Text m={0} fontFamily='Montserrat' fontWeight='600'>Signed In</Text>
      </Grid>
      <Box pt={10}>
        {zoneRows}
      </Box>
      <Grid
        h={25}
        py={10}
        pl={20}
        gridTemplateColumns='5fr 3fr 3fr 3fr 3fr'
      >
        <Flex>
          <Text fontFamily='Montserrat' fontWeight='600'>Add Zone</Text>
          <ClickableText pl='5px' fontSize='0.85em' onClick={() => history.push('/support')}>
            <FontAwesomeIcon icon={solid('circle-plus')} />
          </ClickableText>
        </Flex>
      </Grid>
    </Box>
  )
}

export default ZonesInfo
