import React, { useEffect, useState } from 'react';
import { Box, Grid, Image, Text } from '../elements'
import CreditPackCard from './CreditPackCard'

const CreditPack = (props) => {
  const { currentPack, credits, costInCents, savePercent, thumbnail, onClick } = props
  const [selected, setSelected] = useState(false)
  const [coinsThumb, setCoinsThumb] = useState(thumbnail)

  useEffect(() => {
    if (currentPack) {
      setSelected(currentPack.credits == credits);
    }
  }, [currentPack])

  useEffect(() => {
    if (thumbnail) {
      setCoinsThumb(thumbnail);
    }
  }, [thumbnail])

  const handleSelect = () => {
    onClick(props)
  }

  return (
    <CreditPackCard
      {...props}
      onClick={handleSelect}
      border={selected ? '2px solid #f44336' : '2px solid #eeeeee'}
      maxWidth='200px'
    >
      <Grid gridTemplateRows='4fr 1fr 1fr 1fr' alignContent='center' height='165px'>
        <Box m='auto'>
          <Image {...coinsThumb} />
        </Box>
        <Box mx='auto'>
          <Text fontWeight='500' fontSize='18px' mb='0'>{credits} Credits</Text>
        </Box>
        <Box mx='auto'>
          <Text fontSize='12px' mb='0'>${costInCents / 100}</Text>
        </Box>
        <Box mx='auto'>
          <Text color='#f44336' fontSize='12px' mb='0' pb='10px'>Save {savePercent}</Text>
        </Box>
      </Grid>
    </CreditPackCard>
  )
}

export default CreditPack