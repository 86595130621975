import React from 'react';
import MyPlaylistItem from './MyPlaylistItem'

class MyPlaylistsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      displayed: false,
      isLoading: false,
      playlistsLoading: false,
      playlists: []
    }

    this.setPlaylists = this.setPlaylists.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleNewPlaylistSubmit = this.handleNewPlaylistSubmit.bind(this)
    this.handlePlaylistDelete = this.handlePlaylistDelete.bind(this)
  }

  setPlaylists() {
    this.serverRequest = $.get(`/api/v2/my_playlists`, (result) => {
        this.setState({
          playlistsLoading: false,
          playlists: result
        });
    });
  }

  componentDidMount() {
    this.setState({
      playlistsLoading: true
    })
    this.setPlaylists()
  }

  componentWillReceiveProps({displayed}) {
    this.setState({
      displayed: displayed,
    })
  }

  handleChange(event) {
    event.preventDefault()
    var target = event.target;
    var name = target.name;
    var value = target.value

    this.setState({
      [name]: value
    });
  }

  handleClose(event) {
    if (event.target.classList.contains("my-playlists-modal")) {
      this.setState({
        name: ''
      })
      this.props.handleClose(event)
    }
  }

  handlePlaylistDelete(event) {
    event.preventDefault()
    this.setPlaylists()
  }

  handleNewPlaylistSubmit(event) {
    event.preventDefault()
    this.setState({
      isLoading: true
    })
    this.serverRequest =
    $.ajax({
        url: '/api/v2/my_playlists',
        type: 'POST',
        data: {
          playlist: {
            name: this.state.name
          }
        },
        success: (result) => {
          var currentPlaylists = this.state.playlists
          this.setState({
            playlists: [result, ...currentPlaylists],
            isLoading: false,
            name: ''
          });
          window.kk.notifier.notify("Added new playlist.", "success");
        },
        error: (error) => {
          this.setState({
            isLoading: false
          });
          window.kk.notifier.notify("Error adding playlist, please contact support.", "error");
        }
    })
  }

  render() {
    var playlists = this.state.playlists.map((playlist) => <MyPlaylistItem key={playlist.id} id={playlist.id} name={playlist.name} handleDelete={this.handlePlaylistDelete}/>)
    return (
      <div className={this.state.displayed ? "modal my-playlists-modal" : "hidden"} onClick={this.handleClose}>
        <div className="authform">
          <form onSubmit={this.handleNewPlaylistSubmit}>
            <div className="modal-top">
              <div>
                <strong>My Playlists</strong>
              </div>
            </div>
            <div className="form-group playlist-name">
              <input
                name='name'
                type='text_field'
                className='form-control'
                placeholder='Playlist Name'
                value={this.state.name || ''}
                onChange={this.handleChange} />
            </div>
            <div className="form-group playlist-container">
              {playlists}
            </div>
            <div className="form-group">
              {this.state.isLoading ? <div className="loading"></div> : <input type="submit" value="Save" className='button btn btn-primary red save-button' />}
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default MyPlaylistsModal