import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../contexts";
import { Box } from "../elements";
import { Channel } from ".";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex, UnstyledButton } from "../elements";
import { MediaContext } from "../contexts";

const ChannelPlayWrapper = ({
  channel,
  player,
  hiddenStuff,
  shownStuff,
  hideName,
}) => {
  const { role } = useContext(UserContext);
  const [showButtons, setShowButtons] = useState(false);
  const [playing, setPlaying] = useState(false);
  const { media, setMedia } = useContext(MediaContext);

  useEffect(() => {
    const currentChannel = player.currentChannelId == channel.id;
    const playing = currentChannel && player.playing;
    setPlaying(playing);
  }, [player]);

  const playChannel = (event) => {
    event.preventDefault();
    setMedia({
      id: channel.id,
      playing: !media.playing || channel.id !== media.id,
    });
    if (playing) {
      setPlaying(false);
      player.pause();
    } else {
      if (player.playing || player.loading) {
        player.pause();
      }
      player.loadChannel(channel.id);
      player.playNextSong();
      setPlaying(true);
    }
  };

  const icon =
    media.playing && media.id === channel.id ? solid("pause") : solid("play");

  return (
    <Box
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      {shownStuff && shownStuff(channel)}
      <Channel
        channel={channel}
        hideDescription={false}
        onChannelClick={() => null}
        hideName={hideName}
      >
        {(showButtons || (media.id === channel.id && media.playing)) && (
          <Flex
            position="absolute"
            zIndex={2}
            top={0}
            height="100%"
            width="100%"
            pt="73%"
            bg="rgb(0 0 0 / 25%)"
            borderRadius="6px"
          >
            <UnstyledButton
              bg="#E21E26"
              borderRadius="50%"
              height="35px"
              width="35px"
              mx="10px"
              p="10px"
              pt="8px"
              onClick={playChannel}
            >
              <FontAwesomeIcon icon={icon} color="white" />
            </UnstyledButton>
            {role !== "member" && hiddenStuff(channel)}
          </Flex>
        )}
      </Channel>
    </Box>
  );
};

export default ChannelPlayWrapper;
