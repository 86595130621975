import React from 'react';

class CompanyForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      number: '',
      address: '',
      number_of_locations: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps({company}) {
    if (company) {
      this.setState({
        name: company.name,
        address: company.address,
        number: company.number,
        number_of_locations: company.number_of_locations,
      })
    }
  }

  handleChange(event) {
    this.props.onCompanyChange(event);
    var target = event.target;
    var name = target.name;
    var value = target.value

    this.setState({
      name: value
    });
  }

  render() {
    return (
        <div className="form-group top">
          <input
            name='name'
            type='text_field'
            className='form-control'
            placeholder='Company Name'
            value={this.state.name || ''}
            onChange={this.handleChange}
            autoFocus
            disabled={true} />
          <input
            name='number'
            type='text_field'
            className='form-control'
            placeholder='Company Number'
            value={this.state.number || ''}
            onChange={this.handleChange} />
          <input
            name='address'
            type='text_field'
            className='form-control'
            placeholder='Company Address'
            value={this.state.address || ''}
            onChange={this.handleChange} />
          <input
            name='number_of_locations'
            type='text_field'
            className='form-control'
            placeholder='Number of Locations'
            value={this.state.number_of_locations || ''}
            onChange={this.handleChange} />
        </div>
    );
  }
}

export default CompanyForm
