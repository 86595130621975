import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import css from "@styled-system/css";
import { Box, Grid, Text, UnderlinedText } from "../elements";
import Zones from "./Zones";
import LocationModal from "./LocationModal";
import CheckWarning from "../../images/check-warning.png";
import CheckGood from "../../images/check-good.png";

const LocationRow = ({ location, fetchLocations }) => {
  const [rowOpen, setRowOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { code, name, address, timezone, song_played_within_required_time } =
    location;
  const [songPlayedWithinRequiredTime, setSongPlayedWithinRequiredTime] =
    useState(song_played_within_required_time);
  const [zones, setZones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setModalOpen(false);
    fetchLocations();
  };

  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const stateFullNames = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const refreshData = () => {
    setIsLoading(true);
    fetch(`/api/v2/sites/${location.id}`)
      .then((response) => response.json())
      .then((data) => {
        setSongPlayedWithinRequiredTime(data.song_played_within_required_time);
        setZones(data.zones);
        setIsLoading(false);
      });
  };
  const parseAddress = (adr) => {
    let originalAddressLength = adr.length;
    if (!adr.includes(",")) {
      return adr;
    }
    const address = {};
    const regex =
      /((\d+)\s)?(((([A-Z][a-z]+\s*)+),?\s?)?)((#\d+,\s)?((([A-Z][a-z]+\s?)+),?\s?)?([A-Z]+,?)?)(\s(\d+))?/;
    const match = adr.match(regex);
    if (match) {
      address.street_number = match[2] || "";
      address.street = match[5] || "";
      address.city = match[10] || "";
      address.house = match[8] || "";
      address.state = match[11] || "";
      address.zip = match[13] || "";
    }

    const regexStr = states.join("|");
    const stateMatch = new RegExp(`${regexStr}`);
    const stateFullNamesMatch = new RegExp(`${stateFullNames.join("|")}`);
    let stateString = "";

    if (adr.match(stateMatch)) {
      stateString = adr.match(stateMatch)[0];
    } else if (adr.match(stateFullNamesMatch)) {
      stateString = adr.match(stateFullNamesMatch)[0];
    }

    let line_1 =
      address.street_number + " " + address.street + " " + address.house;
    let line_2 = address.city + " " + stateString + ", " + address.zip;

    let newAddress = line_1 + line_2;
    if (newAddress.length / originalAddressLength < 0.7) {
      if (adr.includes(",") && adr.split(",").length > 2) {
        let adrArray = adr.split(",");
        let firstLine = adrArray[0];
        adrArray.shift();
        return (
          <span style={{ fontFamily: "Montserrat" }}>
            <p style={{ margin: "unset", fontFamily: "Montserrat" }}>
              {firstLine}
            </p>
            <p style={{ margin: "unset", fontFamily: "Montserrat" }}>
              {adrArray.join()}
            </p>
          </span>
        );
      } else {
        return adr;
      }
    } else {
      return (
        <span style={{ fontFamily: "Montserrat" }}>
          <p style={{ margin: "unset", fontFamily: "Montserrat" }}>{line_1}</p>
          <p style={{ margin: "unset", fontFamily: "Montserrat" }}>{line_2}</p>
        </span>
      );
    }
  };

  const icon = rowOpen ? solid("caret-down") : solid("caret-right");
  return (
    <Box pt={20} borderBottom="0.5px solid #EAF0F1">
      <Grid
        p={10}
        gridTemplateColumns="1fr 4fr 3fr 4fr 4fr 2fr"
        fontFamily="Montserrat"
      >
        <Box onClick={() => setRowOpen(!rowOpen)}>
          {code} <FontAwesomeIcon icon={icon} />
        </Box>
        <Text fontFamily="Montserrat" textAlign="left">
          {name}
        </Text>
        <Text fontFamily="Montserrat" textAlign="left">
          {parseAddress(address)}
        </Text>
        <Text fontFamily="Montserrat" textAlign="left">
          {timezone}
        </Text>
        <UnderlinedText
          fontFamily="Montserrat"
          textDecoration="underline"
          onClick={openModal}
        >
          Edit
        </UnderlinedText>
        <div>
          {songPlayedWithinRequiredTime ? (
            <div>
              <span
                style={{
                  fontFamily: "Montserrat",
                }}
              >
                Good{" "}
              </span>
              <img
                src={CheckGood}
                width="14px"
                style={{ display: "inline", verticalAlign: "unset" }}
              />
            </div>
          ) : (
            <div>
              <span
                style={{
                  fontFamily: "Montserrat",
                }}
              >
                Check{" "}
              </span>
              <img
                src={CheckWarning}
                width="14px"
                style={{ display: "inline", verticalAlign: "unset" }}
              />
            </div>
          )}
          <Text
            fontFamily="Montserrat"
            textAlign="left"
            fontSize="11px"
            color="#1F2933"
          >
            Played within 24 hrs
          </Text>
        </div>
      </Grid>
      {rowOpen && (
        <Zones zones={zones} refreshData={refreshData} isLoading={isLoading} />
      )}
      {modalOpen && (
        <LocationModal
          location={location}
          displayed={modalOpen}
          handleClose={closeModal}
          setZones={setZones}
          zones={zones}
          refreshData={refreshData}
        />
      )}
    </Box>
  );
};

export default LocationRow;
