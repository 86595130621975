import React, { useEffect, useState } from 'react';
import { Box, Flex, Grid, GridItem, Text } from '../elements'
import threeCredits from '../../images/3-credits'
import sixCredits from '../../images/6-credits'
import nineCredits from '../../images/9-credits'
import twelveCredits from '../../images/12-credits'
import CreditPack from './CreditPack'

const CreditMenu = ({selectPack, selectedPack, hideCredits}) => {
  const [creditPacks, setCreditPacks] = useState([])

  useEffect(() => {
    fetch('/api/v2/credits')
      .then(response => response.json())
      .then((data) => {
        setCreditPacks(data.credit_packs)
      })
  }, [])

  const getThumbnail = (creditPack) => {
    switch(creditPack.credits) {
      case 3:
        return {src: threeCredits, maxHeight: '30px'}
      case 6:
        return {src: sixCredits, maxHeight: '40px'}
      case 9:
        return {src: nineCredits, maxHeight: '50px'}
      case 12:
        return {src: twelveCredits, maxHeight: '60px'}
    }
  }

  return (
    <Box>
      <Flex flexDirection='column' justifyContent='center'>
        <Text mx='auto' fontSize='18px'>Messaging & Branding Credit Packs</Text>
        <Text mx='auto' fontSize='11px'>1 Credit = 1 Messaging or Branding</Text>
      </Flex>
      <Grid gridTemplateColumns='3fr 3fr 3fr 3fr' minHeight='200px'>
        {hideCredits ? <></> : creditPacks.map((creditPack) =>
          <CreditPack
            key={creditPack.id}
            gridRow={2}
            currentPack={selectedPack}
            credits={creditPack.credits}
            price={creditPack.price}
            costInCents={creditPack.cost_in_cents}
            savePercent={creditPack.save_percent}
            thumbnail={getThumbnail(creditPack)}
            onClick={selectPack}
          />
        )}
      </Grid>
    </Box>
  )
}

export default CreditMenu