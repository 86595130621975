
import React from 'react';
import { Flex, Grid, Radio, Text } from '../elements'

const CardRow = ({card, onSelect}) => {
  const dateColor = card.expired ? 'red' : 'black'

  const selectCard = () => {
    onSelect(card)
  }

  return (
    <Grid gridTemplateColumns='1fr 1fr 1fr'>
      <Flex>
        <Radio id={card.last_four} name="card" value={card.last_four} onClick={selectCard} />
        <Text ml='5px'>{card.brand} {card.last_four}</Text>
      </Flex>
      <Text>{card.name || "No Name"}</Text>
      <Text color={dateColor}>{card.exp_date}</Text>
    </Grid>
  )
}

export default CardRow