import React from 'react';
import UserContext from '../contexts/UserContext'
import { BasicSwitch } from '../elements'
import {SublicenseItem, SublicenseModal} from './index'
import { Tag } from '../FilterNav'

class LicenseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectTitle: '',
      projectDescription: '',
      projectCategory: '',
      sublicenseSearch: '',
      selectedSublicense: null,
      sublicenses: [],
      displayedSublicenses: [],
      displayed: this.props.displayed,
      songId: this.props.songId,
      platformsDisplayed: 'Eligible',
      isLoading: false,
      sublicenseSearchDisplayed: false,
      sublicenseFormDisplayed: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleLicenseSubmit = this.handleLicenseSubmit.bind(this)
    this.handleUpgrade = this.handleUpgrade.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.setSwitchValue = this.setSwitchValue.bind(this)
    this.handleNewSublicenseClick = this.handleNewSublicenseClick.bind(this)
    this.handleSublicenseEnter = this.handleSublicenseEnter.bind(this)
    this.handleSublicenseSearchClose = this.handleSublicenseSearchClose.bind(this)
    this.handleSublicenseSearchChange = this.handleSublicenseSearchChange.bind(this)
    this.handleSublicenseClick = this.handleSublicenseClick.bind(this)
  }

  componentDidMount() {
    this.serverRequest = $.get(`/api/v2/sublicenses`, (result) => {
        this.setState({
          sublicenses: result
        });
    });
  }

  componentWillReceiveProps({displayed}) {
    this.setState({
      displayed: displayed,
    })
  }

  handleChange(event) {
    event.preventDefault()
    var target = event.target;
    var name = target.name;
    var value = target.value

    this.setState({
      [name]: value
    });
  }

  handleCategoryChange(event) {
    var category = event.target.name
    this.setState({
      projectCategory: category
    })
  }

  handleClose(event) {
    if (event.target.classList.contains("license-modal")) {
      this.setState({
        projectTitle: '',
        projectDescription: '',
        projectCategory: ''
      })
      this.props.handleClose(event)
    }
  }

  handleLicenseSubmit(event) {
    event.preventDefault()
    if (!this.state.projectTitle) {
      window.kk.notifier.notify("Project Title is required.", "error");
      return
    }
    if (!this.state.projectDescription) {
      window.kk.notifier.notify("Project Description is required.", "error");
      return
    }
    if (!this.state.projectCategory) {
      window.kk.notifier.notify("Project Category is required. Please select one.", "error");
      return
    }
    this.setState({
      isLoading: true
    })
    var sublicense = this.state.selectedSublicense ? this.state.selectedSublicense.id : null
    data =
    $.ajax({
        url: '/api/v2/licenses',
        type: 'POST',
        data: {
          license: {
            project_title: this.state.projectTitle,
            project_description: this.state.projectDescription,
            project_category: this.state.projectCategory,
            song_id: this.state.songId,
            sublicense_id: sublicense
          }
        },
        success: (response, status) => {
          var link = document.createElement('a');
          link.href = `/api/v2/licenses/${response.id}/download`
          link.download = response.filename;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          this.setState({
            projectTitle: '',
            projectDescription: '',
            projectCategory: '',
            isLoading: false
          })
          this.props.handleSubmit(true)
          window.kk.notifier.notify("License succesfully created.", "success");
        },
        error: (error) => {
          this.setState({
            isLoading: false
          })
          window.kk.notifier.notify("Error creating license, please contact support.", "error");
        }
    })
  }

  handleUpgrade(event) {
    event.preventDefault()
    window.location.href = "/plans?creator=true";
  }

  handleSwitchChange(event) {
    var value = event.target.checked ? 'Non-Eligible' : 'Eligible'

    this.setState({
      platformsDisplayed: value
    });
  }

  handleNewSublicenseClick(event, sublicense, refresh) {
    event.preventDefault()
    if (refresh) {
      return $.get(`/api/v2/sublicenses`, (result) => {
          this.setState({
            sublicenses: result,
            displayedSublicenses: result,
            sublicenseFormDisplayed: !this.state.sublicenseFormDisplayed,
            selectedSublicense: null,
            sublicenseSearch: ''
          });
      });
    }
    this.setState({
      sublicenseFormDisplayed: !this.state.sublicenseFormDisplayed,
      selectedSublicense: sublicense
    });
  }

  handleSublicenseEnter(event) {
    event.preventDefault()
    document.addEventListener('mousedown', this.handleSublicenseSearchClose);
    if (event.target.value == "") {
      this.setState({
        sublicenseSearchDisplayed: true,
        displayedSublicenses: this.state.sublicenses
      })
    } else {
      this.setState({
        sublicenseSearchDisplayed: true
      })
    }
  }

  handleSublicenseSearchClose(event) {
    if (!event.target.matches('.sublicense-item *,.sublicense-item')) {
      document.removeEventListener('mousedown', this.handleClose);
      this.setState({
        sublicenseSearchDisplayed: false
      })
    }
  }

  handleSublicenseClick(event, sublicense) {
    event.preventDefault()
    this.setState({
      sublicenseSearchDisplayed: false,
      sublicenseSearch: sublicense.name,
      selectedSublicense: sublicense
    })
  }

  handleSublicenseSearchChange(event) {
    event.preventDefault()
    search = event.target.value
    displayedSublicenses = this.state.sublicenses.filter((sublicense) => sublicense.name.toLowerCase().includes(search.toLowerCase()));
    this.setState({
      sublicenseSearch: search,
      selectedSublicense: null,
      displayedSublicenses: displayedSublicenses
    })
  }

  setSwitchValue() {
    return !(this.state.platformsDisplayed == 'Eligible')
  }

  render() {
    var plans = this.context.plans
    var planType = plans.creator ? plans.creator.type : 'none'
    var category_names = [
      "Advertising", "Audio Books", "Corporate", "Church", "Documentaries", "Entertainment",
      "Events: Anniversaries", "Events: Birthdays", "Events: Corporate",
      "Events: Mitzvahs", "Events: Reunion", "Events: Weddings", "Fitness", "Food",
      "Health Care", "Hobby", "Real Estate", "Sports", "Tutorial", "Other"
    ]
    var categories = category_names.map((category, index) => <Tag name={category} onTagChange={this.handleCategoryChange} isActive={this.state.projectCategory === category} key={index} />);
    var sublicenses = this.state.displayedSublicenses.map((sublicense) => <SublicenseItem sublicense={sublicense} onEdit={this.handleNewSublicenseClick} onClick={this.handleSublicenseClick} key={sublicense.id} />);
    var personal = (
      <div className="platforms">
        <span className="platform"><i className="fab fa-facebook-f"></i>Facebook</span>
        <span className="platform"><i className="fab fa-instagram-square"></i>Instagram</span>
        <span className="platform"><i className="fas fa-rss"></i>Podcasts</span>
        <span className="platform"><i className="fab fa-twitch"></i>Twitch</span>
        <span className="platform"><i className="fab fa-twitter"></i>Twitter</span>
        <span className="platform"><i className="fab fa-youtube"></i>YouTube</span>
      </div>
    )
    var commercial = (
      <div className="platforms">
        <span className="platform"><i className="fas fa-user"></i>Personal Website</span>
        <span className="platform"><i className="fas fa-globe"></i>Company Website</span>
        <span className="platform"><i className="fas fa-chart-line"></i>Digital Ads</span>
        <a name={'commercial'} onClick={this.handleUpgrade} className={this.state.platformsDisplayed == 'Eligible' ? 'hidden' : 'upgrade-plan-button'}>Upgrade to Commercial</a>
      </div>
    )
    var enterprise = (
      <div className="platforms">
        <span className="platform"><i className="fas fa-mobile-alt"></i>Digital Apps</span>
        <span className="platform"><i className="fas fa-camera"></i>Indy Films</span>
        <span className="platform"><i className="fas fa-star"></i>Movies</span>
        <span className="platform"><i className="fas fa-laptop"></i>Streaming Services</span>
        <span className="platform"><i className="fas fa-microphone-alt"></i>Radio Commercials</span>
        <span className="platform"><i className="fas fa-tv"></i>Television</span>
        <a name={'enterprise'} onClick={this.handleUpgrade} className={this.state.platformsDisplayed == 'Eligible' ? 'hidden' : 'upgrade-plan-button'}>Upgrade to Enterprise</a>
      </div>
    )
    var sublicensing = (
      <div className="form-group sublicensing-information">
        <input
          name='sublicensingInformation'
          type='text_field'
          className={this.state.sublicenseSearchDisplayed ? 'form-control sublicense-search-flat' : 'form-control sublicense-search'}
          value={this.state.sublicenseSearch || ''}
          onChange={this.handleSublicenseSearchChange}
          onClick={this.handleSublicenseEnter} />
        <div className={this.state.sublicenseSearchDisplayed ? "sublicense-search-dropdown" : "hidden"}>
          {sublicenses}
        </div>
      </div>
    )
    return (
      <div className={this.state.displayed ? "modal license-modal" : "hidden"} onClick={this.handleClose}>
        <div className="authform modal-form">
          <form onSubmit={this.handleLicenseSubmit}>
            <div className="modal-top">
              <div>
                <strong>License Details</strong>
              </div>
              <div className="user-info">
                <span>Licensee: <p className="capitalize">{this.context.tenantName}</p></span>
                <span>Plan: <p className="capitalize">{planType}</p></span>
              </div>
            </div>
            <div className="horizontal-form-group">
              <strong className="form-group-title">Project Information:</strong>
              {['commercial', 'enterprise'].includes(planType) ? (
                <div className="sublicensing-header">
                  <strong className="form-group-title">Sublicensing Information:</strong>
                  <div className="add-button" onClick={this.handleNewSublicenseClick}><i className="fas fa-plus"></i></div>
                </div>
              ) : <span></span> }
              <div className="form-group project-information">
                <input
                  name='projectTitle'
                  type='text_field'
                  className='form-control'
                  placeholder='Project Title'
                  value={this.state.projectTitle || ''}
                  onChange={this.handleChange} />
                <textarea
                  name='projectDescription'
                  className='form-control'
                  placeholder='Project Description'
                  value={this.state.projectDescription || ''}
                  onChange={this.handleChange} />
              </div>
              {['commercial', 'enterprise'].includes(planType) ? sublicensing : <span></span> }
            </div>
            <strong className="form-group-title">Project Category:</strong>
            <div className="form-group category-container">
              {categories}
            </div>
            <div className="platform-header">
              <strong className="form-group-title">{this.state.platformsDisplayed} Platforms</strong>
              {planType == 'enterprise' ? <span></span> : (<div className="global-switch">
                <BasicSwitch name='platforms-type' isChecked={this.setSwitchValue} onSwitchChange={this.handleSwitchChange} />
              </div>)}
            </div>
            <div className="form-group">
              <div className={this.state.platformsDisplayed == 'Eligible' ? "platforms-container" : "hidden"} >
                {personal}
                {['commercial', 'enterprise'].includes(planType) ? commercial : <span></span>}
                {planType == 'enterprise' ? enterprise : <span></span>}
              </div>
              <div className={this.state.platformsDisplayed == 'Eligible' ? "hidden" : "platforms-container"}>
                {['commercial', 'enterprise'].includes(planType) ? <span></span> : commercial}
                {planType == 'enterprise' ? <span></span> : enterprise}
              </div>
            </div>
            <strong className="form-group-title">Agreement:</strong>
            <div className="form-group agreement">
              <p>I acknowledge I am creating a <strong>SINGLE-USE</strong> synchronization license agreement for this "musical work" (song, instrumental, sound effect) that I will download to my device upon clicking the "Submit" button below. I am aware that this agreement legally grants me <strong>ONE</strong> use of the musical work in connection with <strong>ONE</strong> specific video/episode/project of my choice.</p>
              <p>If I plan on using this same "musical work" in another video, episode or project, I will return to this page and create another license agreement specifying that particular video, episode or project. This complies with KrossKast's <a href='/terms-and-conditions'>Terms Of Use</a> and legally protects me and my use of this copyrightecd material.</p>
            </div>
            <div className="form-group">
              {this.state.isLoading ? <div className="loading"></div> : <input type="submit" value="Submit" className='button btn btn-primary red license-button' />}
            </div>
          </form>
        </div>
        <SublicenseModal displayed={this.state.sublicenseFormDisplayed} handleClose={this.handleNewSublicenseClick} sublicense={this.state.selectedSublicense}/>
      </div>
    )
  }
}

LicenseModal.contextType = UserContext;
export default LicenseModal