import React from "react";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../contexts";
import classNames from "classnames";
import { difference, filter, drop, cloneDeep, debounce } from "lodash";
import ApproveDeclineConfirmation from "./ApproveDeclineConfirmation";
import ConfirmModal from "../shared/ConfirmModal";
import ReplacementConfirmation from "./ReplacementConfirmation";
import EmptyDeclined from "./EmptyDeclined";
import ChannelPlayWrapper from "./ChannelPlayWrapper";
import { UnstyledButton } from "../elements";
import { Bars } from "react-loader-spinner";

const Channels = () => {
  const { role } = useContext(UserContext);
  const [approvedChannels, setApprovedChannels] = useState([]);
  const [allApprovedChannels, setAllApprovedChannelsCount] = useState(0);
  const [declinedChannels, setDeclinedChannels] = useState([]);
  const [approvedTab, setApprovedTab] = useState(true);
  const [bulkOn, setBulkOn] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selection, setSelection] = useState([]);
  const [channelConfirmation, setChannelConfirmation] = useState(null);
  const [confirmationChannelIds, setConfirmationChannelIds] = useState([]);
  const [showConfirm, setShowConfirm] = useState(null);
  const [searchQ, setSearchQ] = useState("");
  const [loading, setLoading] = useState(true);

  const currentChannels = approvedTab
    ? [...approvedChannels]
    : [...declinedChannels];

  useEffect(() => {
    setClassName("main", "channels");
    $.get("/api/v2/channel_confirmation", (result) => {
      setChannelConfirmation(result);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    setSelection([]);
    setBulkOn(false);
    setSelectAll(false);
    setSearchQ("");
    refetch();
  }, [approvedTab]);

  useEffect(() => {
    if (selectAll && allApprovedChannels.length === selection.length) {
      setSelection(drop(selection));
    }
  }, [approvedChannels]);

  useEffect(() => {}, [
    approvedChannels,
    declinedChannels,
    allApprovedChannels,
  ]);

  const refetch = () => {
    if (approvedTab) {
      $.get(`/api/approved_channels.json?q=${searchQ}`, (result) => {
        setApprovedChannels(result);
      }).done(() => {
        setTimeout(() => {
          setLoading(false);
        }, 400);
      });
      $.get(`/api/approved_channels.json`, (result) => {
        setAllApprovedChannelsCount(result);
      }).done(() => {
        setTimeout(() => {
          setLoading(false);
        }, 400);
      });
    } else {
      $.get(`/api/declined_channels.json?q=${searchQ}`, (result) => {
        setDeclinedChannels(result);
      }).done(() => {
        setTimeout(() => {
          setLoading(false);
        }, 400);
      });
    }
  };

  const refreshUI = () => {
    setSelection(difference(selection, confirmationChannelIds));
    setConfirmationChannelIds([]);
    setShowConfirm(null);
    refetch();
  };

  const fetchSetToPlay = (channel_ids) => {
    $.get(
      `/api/v2/tenant_channels/set_to_play.json?channel_ids=${channel_ids.join(
        ","
      )}`,
      (result) => {
        result.set_to_play
          ? showPopup("replacement", channel_ids)
          : declineChannel(channel_ids);
      }
    );
  };

  const showPopup = (which, channel_ids) => {
    setConfirmationChannelIds(channel_ids);
    setShowConfirm(which);
  };

  const handleConfirm = (_confirm) => {
    if (_confirm) updateServerConfirmation();
    approvedTab
      ? fetchSetToPlay(confirmationChannelIds)
      : approveChannel(confirmationChannelIds);
  };

  const handleApprove = (channel_ids) => {
    channelConfirmation.approve_confirmation
      ? showPopup("confirmation", channel_ids)
      : approveChannel(channel_ids);
  };

  const handleDecline = (channel_ids) => {
    channelConfirmation.decline_confirmation
      ? showPopup("confirmation", channel_ids)
      : fetchSetToPlay(channel_ids);
  };

  const approveChannel = (channel_ids) => {
    $.ajax({
      url: `/api/v2/tenant_channels/approve`,
      dataType: "text",
      type: "POST",
      data: { channel_ids: channel_ids },
    }).done(refreshUI);
  };

  const declineChannel = (channel_ids, replacementChannelId = null) => {
    $.ajax({
      url: `/api/v2/tenant_channels/decline`,
      dataType: "text",
      type: "POST",
      data: {
        channel_ids: channel_ids,
        replacement_channel_id: replacementChannelId,
      },
    }).done(refreshUI);
  };

  const declineSelected = () => {
    if (selection.length === 0) return;
    handleDecline(selection);
  };

  const approveSelected = () => {
    if (selection.length === 0) return;
    handleApprove(selection);
  };

  useEffect(() => {
    if (bulkOn) {
      setSelectAll(false);
    }
  }, [bulkOn]);

  useEffect(() => {
    if (selectAll) {
      setBulkOn(false);
      const forSelection =
        approvedTab && allApprovedChannels.length === approvedChannels.length
          ? drop(currentChannels)
          : cloneDeep(currentChannels);

      setSelection(forSelection.map((c) => c.id));
    } else {
      setSelection([]);
    }
  }, [selectAll]);

  const shownStuff = (channel) => {
    return (
      <div className="shown-stuff">
        {!approvedTab && (
          <div className="declined-eye">
            <i className="fa fa-eye-slash"></i>
          </div>
        )}
        {(bulkOn || selectAll) && (
          <label className="control control-checkbox">
            <input
              type="checkbox"
              checked={selection.includes(channel.id)}
              onChange={(e) =>
                e.target.checked
                  ? addToSelection(channel.id)
                  : removeFromSelection(channel.id)
              }
            />
            <div className="control_indicator"></div>
          </label>
        )}
      </div>
    );
  };

  const hiddenStuff = (channel) => {
    if (allApprovedChannels.length !== 1 || !approvedTab)
      return (
        <UnstyledButton
          color="white"
          bg="#E21E26"
          borderRadius="25px"
          height="35px"
          padding="6px 14px"
          fontFamily="Montserrat"
          onClick={
            approvedTab
              ? () => handleDecline([channel.id])
              : () => handleApprove([channel.id])
          }
        >
          {approvedTab ? "Decline" : "Approve"}
        </UnstyledButton>
      );
  };

  const addToSelection = (channelId) => {
    if (approvedTab && selection.length === approvedChannels.length - 1) {
      return;
    }
    setSelection([...selection, channelId]);
  };

  const removeFromSelection = (channelId) => {
    setSelection(difference(selection, [channelId]));
  };

  const updateServerConfirmation = () => {
    const params = approvedTab
      ? { decline_confirmation: false }
      : { approve_confirmation: false };

    $.ajax({
      url: `/api/v2/channel_confirmation/`,
      dataType: "json",
      type: "PUT",
      data: params,
    }).done((result) => {
      setChannelConfirmation(result);
    });
  };

  const channels = () => {
    if (
      !approvedTab &&
      declinedChannels.length === 0 &&
      searchQ === "" &&
      !loading
    ) {
      return <EmptyDeclined setApproved={setApprovedTab} />;
    }
    if (currentChannels.length === 0) {
      return <p>Your search returned no results</p>;
    }
    return (
      <div style={{ width: "100%" }}>
        <div className="channels-boxes-grid">
          {currentChannels.map((channel, index) => {
            return (
              <ChannelPlayWrapper
                player={window.kk.player}
                channel={channel}
                key={channel.id}
                hiddenStuff={hiddenStuff}
                shownStuff={shownStuff}
                hideName={true}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const replacementChannels = filter(allApprovedChannels, (a) => {
    return !confirmationChannelIds.includes(a.id);
  });

  const onReplacementConfirm = (replacementChannelId) => {
    declineChannel(confirmationChannelIds, replacementChannelId);
  };

  const confirmationPopupContent = () => {
    return showConfirm === "replacement" ? (
      <ReplacementConfirmation
        replacementChannels={replacementChannels}
        onConfirm={onReplacementConfirm}
      />
    ) : (
      <ApproveDeclineConfirmation
        approval={approvedTab}
        onConfirm={handleConfirm}
      />
    );
  };

  const handleSearch = debounce(() => {
    refetch();
  }, 300);

  useEffect(() => {
    handleSearch();
  }, [searchQ]);

  const channelsHeading = () => {
    return role !== "member" ? (
      <div className="heading-desc">
        <h3>Select your music channels</h3>
        <p>
          Preview and approve/decline the music channels that will be available
          to be played within your locations.
        </p>
      </div>
    ) : (
      <div className="heading-desc">
        <h3>Select your music channel</h3>
        <p>
          These are the approved music channels set by your administrator that
          are available to play within your location.
        </p>
      </div>
    );
  };

  return (
    <div className="channels-root">
      {channelsHeading()}
      {showConfirm && (
        <ConfirmModal
          content={confirmationPopupContent()}
          onClose={() => {
            setShowConfirm(null);
          }}
        />
      )}
      <div className="channels-filters">
        {role !== "member" && (
          <div className="left-side">
            <button
              className={classNames({ active: approvedTab }, "button")}
              onClick={() => setApprovedTab(true)}
            >
              <i className="fa fa-eye"></i>
              Approved
            </button>

            <button
              className={classNames({ active: !approvedTab }, "button")}
              onClick={() => setApprovedTab(false)}
            >
              <i className="fa fa-eye-slash"></i>
              Declined
            </button>
          </div>
        )}
        <div className="right-side">
          {role !== "member" && (
            <div className="left">
              <h5>Filter:</h5>
              <label
                htmlFor="bulk-select"
                className="control control-checkbox"
                style={{
                  position: "relative",
                  marginLeft: "20px",
                  fontWeight: 500,
                }}
              >
                <input
                  type="checkbox"
                  id="bulk-select"
                  checked={bulkOn}
                  onChange={() => setBulkOn(!bulkOn)}
                />
                <div
                  className="control_indicator"
                  style={{ left: 0, right: "unset", top: "2px" }}
                ></div>
                Bulk Select
              </label>
              <label
                htmlFor="select-all"
                className="control control-checkbox"
                style={{
                  position: "relative",
                  marginLeft: "20px",
                  fontWeight: 500,
                }}
              >
                <input
                  type="checkbox"
                  id="select-all"
                  checked={selectAll}
                  onChange={(e) => setSelectAll(!selectAll)}
                />
                <div
                  className="control_indicator"
                  style={{ left: 0, right: "unset", top: "2px" }}
                ></div>
                Select All
              </label>
              <div style={{ width: "200px" }}>
                {approvedTab ? (
                  <button
                    className="red-btn"
                    onClick={declineSelected}
                    style={
                      selection.length > 0
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    Decline Selected
                  </button>
                ) : (
                  <button
                    className="red-btn"
                    onClick={approveSelected}
                    style={
                      selection.length > 0
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    Approve Selected
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="channels-search-container">
            <i className="fas fa-search search-button"></i>
            <input
              name="search"
              type="text_field"
              className="form-control search-input"
              placeholder="Search..."
              value={searchQ}
              onChange={(e) => setSearchQ(e.target.value)}
            />
            {searchQ && (
              <i
                className="fas fa-times search-clear"
                onClick={() => {
                  setLoading(true);
                  setSearchQ("");
                }}
              ></i>
            )}
          </div>
        </div>
      </div>
      <div className="channels-content">
        <div className="channels">
          <div className="channels-header">
            <h4
              style={{
                fontWeight: "600",
              }}
            >
              {approvedTab ? "Approved Channels" : "Declined Channels"}
            </h4>
            {approvedTab &&
              selection.length === allApprovedChannels.length - 1 && (
                <p>
                  Note: one channel must be approved at all times when "Select
                  All" channels is checked
                </p>
              )}
          </div>
          {loading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Bars
                height="80"
                width="80"
                color="red"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <div className="channel-boxes">{channels()}</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Channels;
