import React from 'react';
import { UserContext } from '../contexts'
import { buildRequestData } from '../helpers/messaging'
import Schedule from './Schedule'
import { RequestItem } from '../Talent';
import Speaker from '../../images/speaker.png'
import SpeakerDisabled from '../../images/speaker-disabled.png'

class Messaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message_requests: [] };
  }

  componentDidMount() {
    setClassName("main", "messaging");
    this.updateData();
  }

  componentWillUnmount() {
    setClassName("main", "");
  }

  updateData() {
    this.serverRequest = $.get("/api/talent_requests.json", (result) => {
      this.setState({
        message_requests: buildRequestData(result),
      });
    }).fail((err) => {
      window.location.replace("/users/sign_in");
    });
  }

  disabledMessages() {
    const { message_requests } = this.state;
    const disabledReqs =
      message_requests["disabled"] === undefined
        ? []
        : message_requests["disabled"];
    return message_requests["approved"] === undefined
      ? disabledReqs
      : disabledReqs.concat(message_requests["approved"]);
  }

  render() {
    const { message_requests } = this.state;
    const disabledReqs = this.disabledMessages();
    return (
      <div className="messaging bottom-padded">
        <h4 className="messaging header">Messaging Schedule</h4>
        <span className="messaging sub-header">
          Congratulations on creating your messages! Below, you can choose which
          messages are Live or Disabled.
        </span>
        {["master", "admin"].includes(this.context.userRole) ? (
          <div></div>
        ) : (
          <div>
            <Schedule />
          </div>
        )}
        <div className="col-sm-12 row live-requests">
          <div className="instructions">
            <span className="icon">
              <img className="icon-img" src={Speaker} /> &nbsp;
            </span>
            <b>SELECT:</b>&nbsp;Live Messages
          </div>
          <div className="col-md-9 request-items">
            {message_requests["live"] ? (
              message_requests["live"].map((req, idx) => {
                return (
                  <RequestItem
                    key={`${req.id}-${idx}`}
                    talentRequest={req}
                    requestType="live"
                    updateCallback={this.updateData.bind(this)}
                    refreshRequests={this.updateData.bind(this)}
                  />
                );
              })
            ) : (
              <span className="no-live-messages">
                No messages are live right now.
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-12 row disabled-requests bottom-padded">
          <div className="instructions">
            <span className="icon">
              <img className="icon-img" src={SpeakerDisabled} /> &nbsp;
            </span>
            <b>SELECT:</b>&nbsp;Disabled Messages
          </div>
          <div className="col-md-9 request-items">
            {disabledReqs.length ? (
              disabledReqs.map((req, idx) => {
                return (
                  <RequestItem
                    key={`${req.id}-${idx}`}
                    talentRequest={req}
                    requestType={req.status}
                    updateCallback={this.updateData.bind(this)}
                    refreshRequests={this.updateData.bind(this)}
                  />
                );
              })
            ) : (
              <span className="no-disabled-messages">
                No disabled messages at the moment.
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Messaging.contextType = UserContext;
export default Messaging;
