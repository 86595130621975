import React from 'react';

class MyPlaylistItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      isEditing: false,
      isSubmitting: false,
      isDeleting: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleChange(event) {
    event.preventDefault()
    var target = event.target;
    var name = target.name;
    var value = target.value

    this.setState({
      [name]: value
    });
  }

  handleClick(event) {
    if (!event.target.matches(`[data-playlist-item-id='${this.props.id}'] *`)) {
      document.removeEventListener('mousedown', this.handleClick);
      this.setState({
        isEditing: false
      })
    }
  }

  handleEdit(event) {
    this.setState({
      isEditing: true
    })
    document.addEventListener('mousedown', this.handleClick);
  }

  handleSubmit(event) {
    this.setState({
      isSubmitting: true
    })
    this.serverRequest =
    $.ajax({
        url: `/api/v2/my_playlists/${this.props.id}`,
        type: 'PATCH',
        data: {
          playlist: {
            name: this.state.name
          }
        },
        success: (result) => {
          this.setState({
            isSubmitting: false,
            isEditing: false
          });
          window.kk.notifier.notify("Updated playlist.", "success");
        },
        error: (error) => {
          this.setState({
            isSubmitting: false
          });
          window.kk.notifier.notify("Error updating playlist, please contact support.", "error");
        }
    })
  }

  handleDelete(event) {
    this.setState({
      isDeleting: true
    })
    this.serverRequest =
    $.ajax({
        url: `/api/v2/my_playlists/${this.props.id}`,
        type: 'DELETE',
        success: (result) => {
          this.setState({
            isDeleting: false
          });
          this.props.handleDelete(event)
          window.kk.notifier.notify("Deleted playlist.", "success");
        },
        error: (error) => {
          this.setState({
            isDeleting: false
          });
          window.kk.notifier.notify("Error deleting playlist, please contact support.", "error");
        }
    })
  }

  render() {
    var inputField = <input
      name='name'
      type='text_field'
      className='form-control'
      placeholder='Playlist Name'
      value={this.state.name || ''}
      onChange={this.handleChange} />
    var editButton = this.state.isEditing ? <i className="fas fa-check" onClick={this.handleSubmit}></i> : <i className="fas fa-pen" onClick={this.handleEdit}></i>
    return (
      <div className="playlist-item" data-playlist-item-id={this.props.id}>
        {this.state.isEditing ? inputField : <div className="name">{this.state.name}</div>}
        <div className="buttons">
          {this.state.isSubmitting ? <div className="loading loading-small"></div> : editButton}
          {this.state.isDeleting ? <div className="loading loading-small"></div> : <i className="fas fa-times" onClick={this.handleDelete}></i>}
        </div>
      </div>
    )
  }
}

export default MyPlaylistItem